import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {APIURL, getProtecteItems, setProtecteItems, updateProtecteItems,} from "../../utils";
import {GeneralStateInterface} from "../../interface/GeneralStateInterface";
import subcategoryService from "./subcategory.service";

interface SubCategoryState extends GeneralStateInterface{
    subcategories:  any[],
    categories:  any[],
    subcategory:  any,

    isCategoryLoading: boolean,
    isCategorySuccess: boolean,
    isCategoryError: boolean,
}

const initialState: SubCategoryState = {
    subcategories: [],
    categories: [],
    subcategory: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    isCategoryError: false,
    isCategoryLoading: false,
    isCategorySuccess: false,

    message: null,
}

export const setSubCategory = createAsyncThunk<any, Object>(
    'subcategory/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}subcategory/create`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getSubCategories = createAsyncThunk<any, Object>(
    'subcategory/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}subcategory/list`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getCategoriesOption = createAsyncThunk<any, Object>(
    'subcategory/option-category',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            return await subcategoryService.getCategoriesOption(tokens)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const updateSubCategory = createAsyncThunk<any, Object>(
    'subcategory/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}subcategory/update`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getSubCategory = createAsyncThunk<any, Object>(
    'subcategory/show',
    async (slug: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}subcategory/show/${slug}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const deleteSubCategory = createAsyncThunk<any, Object>(
    'subcategory/delete',
    async (content: any, thunkAPI) => {
        try {
             // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}subcategory/delete`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const subcategorySlice = createSlice({
    name: 'subcategory',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isDeleteLoading = false
            state.isDeleteError = false
            state.isDeleteSuccess = false

            state.isCategoryLoading = false
            state.isCategoryError = false
            state.isCategorySuccess = false


            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSubCategories.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSubCategories.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.subcategories = action.payload
            })
            .addCase(getSubCategories.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getSubCategory.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSubCategory.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.subcategory = action.payload
            })
            .addCase(getSubCategory.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getCategoriesOption.pending, (state) => {
                state.isCategoryLoading = true
            })
            .addCase(getCategoriesOption.fulfilled, (state, action) => {
                state.isCategoryLoading = false
                state.isCategorySuccess = true
                state.categories = action.payload
            })
            .addCase(getCategoriesOption.rejected, (state, action) => {
                state.isCategoryLoading = false
                state.isCategoryError = true
                state.message = action.payload
            })

            .addCase(setSubCategory.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setSubCategory.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.subcategories = [action.payload, ...state.subcategories]
            })
            .addCase(setSubCategory.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })


            .addCase(updateSubCategory.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateSubCategory.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.subcategories = [action.payload, ...state.subcategories.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.subcategory = action.payload?._id
            })
            .addCase(updateSubCategory.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })



            .addCase(deleteSubCategory.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deleteSubCategory.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.subcategories = state.subcategories.filter((admin: any) => admin?._id !== action.payload?._id)
            })
            .addCase(deleteSubCategory.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })
    }
})

export const { reset } = subcategorySlice.actions
export default subcategorySlice.reducer
