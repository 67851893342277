import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { Link, useNavigate } from 'react-router-dom';
import { reset } from '../../../../../redux/feature/auth/auth.slice';
import { getEvents } from '../../../../../redux/feature/event/event.slice';
import { MainLayout } from '../../../../layout';
import { BackButton, GridLayout } from '../../../../components';
import PlatformMenu from '../../platform/module/PlatformMenu';
import { links } from '../../../../routes';
import { capitalize } from '../../../../../utils/function';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export const EventsPage = () => {
    const [search, setSearch] = useState<string>("")
    const [numberItem, setNumberItem] = useState<number>(15)
    const [startList, setStartList] = useState<number>(1)
    const [chooseApercu, setChosseApercu] = useState<string>("grid")
    const dispatch = useAppDispatch();
    const {isLoading, isError, isSuccess, events, message}: any = useAppSelector((state) => state.event);
    let navigate = useNavigate()
    useEffect(() => {
        // @ts-ignore
        dispatch(getEvents())

        return () => {
            dispatch(reset())
        }
    }, [dispatch])

    return (
        <MainLayout title='Evenements' here='platform' here_menu='event'>
            <div className={"kubini-page2"}>
                <BackButton/>
                <div className={"kubini-page2__container"}>
                    <div className={"kubini-page2__side"}>
                        <PlatformMenu here={"event"}/>
                    </div>
                    <div className={"kubini-page2__content"}>
                        {
                            isLoading ? (
                                <div className={"kubini-loader"}>
                                    <i className="icon-spin"/>
                                </div>
                            ) : (
                                isError ? (
                                    <div className={"kubini-page-error"}>
                                        <div className={"kubini-page-error__container"}>
                                            <i className="icon-delete-square"/>
                                            <div className={"kubini-page2-error__text"}>
                                                <h2>Oupss!!</h2>
                                                <p>{message}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    isSuccess &&  events ? (
                                        <>
                                            <div className={"kubini-page2__header"}>
                                                <h2>Les évenements</h2>
                                            </div>
                                            <div className={"kubini-page2__searchActions kubini-page2-searchActions"}>
                                                <GridLayout direction={"fd--row"} gap={"g--6"} justify={"jc--start"} align={"al--start"} wrap={true}>
                                                    <div className={"kubini-page2-search"}>
                                                        <i className="icon-search"/>
                                                        <input
                                                            type="search"
                                                            value={search}
                                                            onChange={(e) => setSearch(e.target.value)}
                                                            placeholder={"search..."}
                                                        />
                                                    </div>
                                                    <div className={"kubini-page2-searchItem"}>
                                                        <span>Nombre à afficher</span>
                                                        <input
                                                            type="number"
                                                            value={numberItem}
                                                            min={5}
                                                            onChange={(e) => setNumberItem(parseInt(e.target.value))}
                                                        />
                                                    </div>
                                                    <div className={"kubini-page2-searchItem"}>
                                                        <span>Commencer par</span>
                                                        <input
                                                            type="number"
                                                            min={1}
                                                            value={startList}
                                                            onChange={(e) => setStartList(parseInt(e.target.value))}
                                                        />
                                                    </div>
                                                </GridLayout>
                                            </div>
                                            <div className={'kubini-page2__list kubini-page2-list'}>
                                                <div className={'kubini-page2-list-head'}>
                                                    <div className={'kubini-page2-list-head__text'}>
                                                        <span>{events.filter((el: any) => el.title?.toLowerCase().includes(search.toLowerCase())).length} élément(s) trouvé(s)</span>
                                                    </div>
                                                    <div className={'kubini-page2-list-head-actions'}>
                                                        <button
                                                            type={'button'}
                                                            className={`kubini-page2-list-head-action ${chooseApercu === 'grid' ? 'is--active' : ''}`}
                                                            onClick={() => setChosseApercu('grid')}
                                                        >
                                                            <i className={'icon-dashboard'} />
                                                        </button>
                                                        <button
                                                            type={'button'}
                                                            className={`kubini-page2-list-head-action ${chooseApercu === 'list' ? 'is--active' : ''}`}
                                                            onClick={() => setChosseApercu('list')}
                                                        >
                                                            <i className={'icon-list-bullets'} />
                                                        </button>
                                                    </div>
                                                </div>
                                                {
                                                    events
                                                        .filter((el: any) => el.title?.toLowerCase().includes(search.toLowerCase()))
                                                        .length > 0 ? (
                                                            <div className={'kubini-page2-list-container'}>
                                                                {
                                                                    chooseApercu === 'grid' ? (
                                                                        <div className={'kubini-page2-list-grid'}>
                                                                            <GridLayout
                                                                            direction={'fd--row'}
                                                                            wrap={true}
                                                                            justify={'jc--start'}
                                                                            align={'al--start'}
                                                                            gap={'g--4'}
                                                                            >
                                                                                {
                                                                                    events
                                                                                        .filter((el: any) => el.title?.toLowerCase().includes(search.toLowerCase()))
                                                                                        .slice((startList - 1), (numberItem + (startList - 1)))
                                                                                        .map((item: any, index: any) => (
                                                                                            <div className={'kubini-page2-list-grid-item'} key={index}>
                                                                                                <div className={'kubini-category-card'}>
                                                                                                    <div className={'kubini-category-card__container'}>
                                                                                                        <div className={'kubini-category-card__icon'}>
                                                                                                            <i className="icon-align-layers" />
                                                                                                        </div>
                                                                                                        <div className={'kubini-category-card__text'}>
                                                                                                        <h2>{item.title}</h2>
                                                                                                        <span>Date: {item.date_event ? capitalize(format(new Date(item.date_event), "EEEE d MMMM yyyy", {locale: fr})) : "ND"}</span>
                                                                                                        <span>Lieu: {item.lieu}</span>
                                                                                                        <Link className='kubini-link' to={links.user + item.user?.auth?.username}>Annoncé par {item.user?.auth?.fullname + " - @"+item.user?.auth?.username}</Link>
                                                                                                        <p>
                                                                                                            Ajouter le {item.createdAt ? capitalize(format(new Date(item.createdAt), "EEEE d MMMM yyyy à HH:mm:ss", {locale: fr})) : "ND"}
                                                                                                        </p>
                                                                                                        <div className={'kubini-category-card__actions'}>
                                                                                                            <button
                                                                                                            type={'button'}
                                                                                                            className={'kubini-category-card__action'}
                                                                                                            onClick={() => {
                                                                                                                navigate(links.event + item.slug)
                                                                                                            }}
                                                                                                            >
                                                                                                                <i className="icon-view" /> <span>Voir</span>
                                                                                                            </button>
                                                                                                            
                                                                                                        </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                }
                                                                            </GridLayout>
                                                                        </div>
                                                                    ) : (
                                                                        <div className={'kubini-page2-list-list'}>
                                                                          <div className={'kubini-page2-list-list__container'}>
                                                                            <div className={'kubini-page2-list-list-header'}>
                                                                              <GridLayout
                                                                                direction={'fd--row'}
                                                                                justify={'jc--start'}
                                                                                align={'al--start'}
                                                                                gap={'g--0'}
                                                                              >
                                                                                <div className={'kubini-page2-list-list-header-id'}>
                                                                                  <strong>#</strong>
                                                                                </div>
                                                                                <div className={'kubini-page2-list-list-header-large'}>
                                                                                  <strong>Evènement</strong>
                                                                                </div>
                                                                                <div className={'kubini-page2-list-list-header-large'}>
                                                                                  <strong>Date et lieu</strong>
                                                                                </div>
                                                                                <div className={'kubini-page2-list-list-header-mid'}>
                                                                                  <strong>Ajouté par</strong>
                                                                                </div>
                                                                                <div className={'kubini-page2-list-list-header-mid'}>
                                                                                  <strong>Ajouté le</strong>
                                                                                </div>
                                                                                <div className={'kubini-page2-list-list-header-actions'}>
                                                                                  <strong>Actions</strong>
                                                                                </div>
                                                                              </GridLayout>
                                                                            </div>
                                                                            <div className={'kubini-page2-list-list-items'}>
                                                                              <GridLayout
                                                                                direction={'fd--column'}
                                                                                justify={'jc--start'}
                                                                                align={'al--start'}
                                                                                gap={'g--0'}
                                                                                wrap={true}
                                                                              >
                                                                                {
                                                                                    events
                                                                                        .filter((el: any) => el.title?.toLowerCase().includes(search.toLowerCase()))
                                                                                        .slice((startList - 1), (numberItem + (startList - 1)))
                                                                                        .map((item: any, index: any) => (
                                                                                            <div className={'kubini-page2-list-list-item'} key={index}>
                                                                                              <GridLayout
                                                                                                direction={'fd--row'}
                                                                                                justify={'jc--start'}
                                                                                                align={'al--start'}
                                                                                                gap={'g--0'}
                                                                                              >
                                                                                                <div className={'kubini-page2-list-list-item-id'}>
                                                                                                  <strong>{index + 1}</strong>
                                                                                                </div>
                                                                                                <div className={'kubini-page2-list-list-item-large'}>
                                                                                                    <p>
                                                                                                        <span>{item.title}</span>
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className={'kubini-page2-list-list-item-large'}>
                                                                                                    <p>
                                                                                                        <span>Date: {item.date_event ? capitalize(format(new Date(item.date_event), "EEEE d MMMM yyyy", {locale: fr})) : "ND"}</span>
                                                                                                        <span>Lieu: {item.lieu}</span>
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className={'kubini-page2-list-list-item-large'}>
                                                                                                    <div className={"kubini-page2-list-item-user"}>
                                                                                                        <div className={"kubini-page2-list-item-user__avatar"}>
                                                                                                            {
                                                                                                                item.user?.auth?.avatar ? (
                                                                                                                    <img
                                                                                                                        src={item.user?.auth?.avatar?.url}
                                                                                                                        alt={item.user?.auth?.fullname}/>
                                                                                                                ) : (
                                                                                                                    <i className="icon-user-single"/>
                                                                                                                )
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div className={"kubini-page2-list-item-user__info"}>
                                                                                                            <strong>{item.user?.auth?.fullname}</strong>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className={'kubini-page2-list-list-item-mid'}>
                                                                                                  <strong>{item.createdAt ? capitalize(format(new Date(item.createdAt), "EEEE d MMMM yyyy à HH:mm:ss", {locale: fr})) : "ND"}</strong>
                                                                                                </div>
                                                                                                <div className={'kubini-page2-list-list-item-actions'}>
                                                                                                  <button
                                                                                                    type={'button'}
                                                                                                    className={'kubini-page2-list-list-item-action'}
                                                                                                    onClick={() => {
                                                                                                      navigate(links.event + item.slug)
                                                                                                    }}
                                                                                                  >
                                                                                                    <i className="icon-edit-write-2" /> <span>Voir</span>
                                                                                                  </button>
                                                                                                  
                                                                                                </div>
                                                                                              </GridLayout>
                                                                                            </div>
                                                                                        ))
                                                                                }
                                                                              </GridLayout>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        ) : (
                                                            <strong>Aucun élément trouvé...</strong>
                                                        )
                                                }
                                            </div>
                                        </>
                                    ) : null
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}