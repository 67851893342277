import React from 'react';
import ReactDOM from 'react-dom/client';
import "./app/assets/style/index.scss"
import {BrowserRouter} from "react-router-dom"
import {Provider} from "react-redux";
import {store} from "./redux/store";
import App from './App';
import { ThemeProvider } from './app/context/ThemeContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <ThemeProvider>
      <Provider store={store}>
          <App />
      </Provider>
    </ThemeProvider>
  </BrowserRouter>
);

