import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {APIURL, getProtecteItems, setProtecteItems, updateProtecteItems,} from "../../utils";
import {GeneralStateInterface} from "../../interface/GeneralStateInterface";

interface PartnerState extends GeneralStateInterface{
    partners:  any[],
    partner:  any,
}

const initialState: PartnerState = {
    partners: [],
    partner: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,
}


export const setPartner = createAsyncThunk<any, Object>(
    'partner/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}partner/create`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)
export const getPartners = createAsyncThunk<any, Object>(
    'partner/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}partner/list`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getPartner = createAsyncThunk<any, Object>(
    'partner/show',
    async (slug: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}partner/show/${slug}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)
export const updatePartner = createAsyncThunk<any, Object>(
    'partner/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}partner/update`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)
export const deletePartner = createAsyncThunk<any, Object>(
    'partner/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}partner/delete`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const partnerSlice: any = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isDeleteLoading = false
            state.isDeleteError = false
            state.isDeleteSuccess = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPartners.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getPartners.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.partners = action.payload
            })
            .addCase(getPartners.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getPartner.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getPartner.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.partner = action.payload
            })
            .addCase(getPartner.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(setPartner.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setPartner.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.partners = [action.payload, ...state.partners]
            })
            .addCase(setPartner.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })

            .addCase(updatePartner.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updatePartner.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.partners = [action.payload, ...state.partners.filter((partner: any) => partner?._id !== action.payload?._id)]
                state.partner = action.payload
            })
            .addCase(updatePartner.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(deletePartner.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deletePartner.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.partners = state.partners.filter((partner: any) => partner?._id !== action.payload?._id)
            })
            .addCase(deletePartner.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })
    }
})

export const { reset } = partnerSlice.actions
export default partnerSlice.reducer