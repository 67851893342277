import React from 'react';
import { MainLayout } from '../../../layout';

export const ErrorPage = () => {
    return (
        <MainLayout title='Page not found'>
            <div className={"kubini-page-error"}>
                <div className={"kubini-page-error__container"}>
                    <i className="icon-delete-square"/>
                    <div className={"kubini-page2-error__text"}>
                        <h2>Oupss!!</h2>
                        <p>{"Page introvable"}</p>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}