import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import authReducer from "../feature/auth/auth.slice"
import activityReducer from "../feature/activity/activity.slice"
import adminReducer from "../feature/admin/admin.slice"
import cityReducer from "../feature/city/city.slice"
import pubReducer from "../feature/pub/pub.slice"
import categoryReducer from "../feature/category/category.slice"
import subcategoryReducer from "../feature/subcategory/subcategory.slice"
import userReducer from "../feature/user/user.slice"
import articleReducer from "../feature/article/article.slice"
import eventReducer from "../feature/event/event.slice"
import postReducer from "../feature/post/post.slice"
import feedbackReducer from "../feature/feedback/feedback.slice"
import partnerReducer from "../feature/partner/partner.slice"


export const store = configureStore({
    reducer: {
        auth: authReducer,
        activity: activityReducer,
        admin: adminReducer,
        city: cityReducer,
        pub: pubReducer,
        category: categoryReducer,
        subcategory: subcategoryReducer,
        user: userReducer,
        article: articleReducer,
        event: eventReducer,
        post: postReducer,
        feedback: feedbackReducer,
        partner: partnerReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AddDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AddDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;