import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { banEvent, depingleEvent, epingleEvent, getEvent, reset, unbanEvent, uncommentEvent} from '../../../../../redux/feature/event/event.slice';
import { MainLayout } from '../../../../layout';
import { BackButton, Button } from '../../../../components';
import PlatformMenu from '../../platform/module/PlatformMenu';
import { Toast } from '../../../../components/src/Alert/module/Toast.module';
import { capitalize, formatMyDate, formatNumber, formatNumber2 } from '../../../../../utils/function';
import { links } from '../../../../routes';
import { ImagesBanner } from '../../article';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export const EventPage = () => {
    const {slug} = useParams()
    const dispatch = useAppDispatch();
    const { isLoading, isError, isSuccess, event, isEpingleError, isEpingleLoading, isEpingleSuccess, isEditError, isEditLoading, isEditSuccess, message} = useAppSelector((state) => state.event);
    const [banModal, setBanModal] = useState<boolean>(false)
    let navigate = useNavigate()
    const [readMore, setReadMore] = useState<number>(12)
    const [typeLike, setTypeLike] = useState<string>("")
    const [isNewNotif, setIsNewNotif] = useState<boolean>(false)
    const [isoNewNotif, setIsoNewNotif] = useState<boolean>(false)
    const [isbNewNotif, setIsbNewNotif] = useState<boolean>(false)

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const tabs = searchParams.get('tabs')
    
    useEffect(() => {
        if(isEditSuccess && typeLike){
            setIsNewNotif(true)
        }
    }, [isEditSuccess, typeLike])

    useEffect(() => {
        if(isEditError && typeLike){
          setIsNewNotif(true)
        }
    }, [isEditError, typeLike])
    
    useEffect(() => {
        if(isEditSuccess && banModal){
            setIsbNewNotif(true)
            setBanModal(false)
        }
    }, [isEditSuccess, banModal])

    useEffect(() => {
        if(isEditError && banModal){
            setIsbNewNotif(true)
            setBanModal(false)
        }
    }, [isEditError, banModal])
      
    useEffect(() => {
        if(isEpingleError){
            setIsoNewNotif(true)
            
        }
    }, [isEpingleError])

    useEffect(() => {
        if(isEpingleSuccess){
            setIsoNewNotif(true)
        }
    }, [isEpingleSuccess])
    
    useEffect(() => {
        if(slug){
            dispatch(getEvent(slug))
        }
        return () => {
            dispatch(reset())
        }
    }, [dispatch, slug])

    return (
        <MainLayout title='Evenement' here='plateform' here_menu='event'>
            <div className={"kubini-page2"}>
                <BackButton/>
                <div className={"kubini-page2__container"}>
                    <div className={"kubini-page2__side"}>
                        <PlatformMenu here={"event"}/>
                    </div>
                    <div className={"kubini-page2__content"}>
                        {
                            isLoading ? (
                                <div className={"kubini-loader"}>
                                    <i className="icon-spin"/>
                                </div>
                            ) : (
                                isError ? (
                                    <div className={"kubini-page-error"}>
                                        <div className={"kubini-page-error__container"}>
                                            <i className="icon-delete-square"/>
                                            <div className={"kubini-page2-error__text"}>
                                                <h2>Oupss!!</h2>
                                                <p>{message}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    isSuccess &&  event ? (
                                        <>
                                            <div className='kubini-product-banner'>
                                                <ImagesBanner images={event.images}/>
                                                <div className='kubini-product-banner__side'>
                                                <div className='kubini-product-banner__info kubini-product-banner-info'>
                                                    <div className='kubini-product-banner-info__details'>
                                                    <h2>{event.title}</h2>
                                                    <p>Lieu: {event?.lieu?.toUpperCase()}</p>
                                                    
                                                    <div className='kubini-product-banner-info-user'>
                                                        <div className='kubini-product-banner-info-user__avatar'>
                                                        {
                                                            event?.user?.auth?.avatar ? (
                                                                <img src={event?.user?.auth?.avatar?.url} alt="" />
                                                            ) : (
                                                                <i className="icon-user-single"></i>
                                                            )
                                                        }
                                                        </div>
                                                        <div className='kubini-product-banner-info-user__info'>
                                                            <strong>{event?.user?.auth?.fullname}</strong>
                                                            <span>{event?.user?.city?.name || ""}</span>
                                                        </div>
                                                    </div>
                                                    <div className='kubini-product-banner-info__avis kubini-product-banner-info-avis'>
                                                        <div className='kubini-product-banner-info-avis__item'>
                                                        <i className="icon-favorite-heart"></i> <span>{event?.likes?.length > 0 ? formatNumber(event?.likes?.length) : "Aucun"} utilisateur(s) aime(nt)</span>
                                                        </div>
                                                        <div className='kubini-product-banner-info-avis__item'>
                                                        <i className="icon-chat-two-bubbles-oval"></i> <span>{event?.comments?.length > 0 ? formatNumber(event?.comments?.length) : "Aucun"} commentaire(s)</span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div className='kubini-product-banner-info__orders'>
                                                        <div className='kubini-product-banner-info__price'>
                                                            <span>Date:</span>
                                                            <strong>{event?.date_event ? capitalize(format(new Date(event?.date_event), "EEEE d MMMM yyyy", {locale: fr})) : "ND"}</strong>
                                                        </div>
                                                        <div className='kubini-product-banner-info__actions'>
                                                        <Button btnType={'button'} label={isEpingleLoading ? "..." : event?.epingle ? "Ne plus Epingler" : "Epingler"} btnStyle={'is--primary'} active={!isEpingleLoading} withIconLeft={isEpingleLoading} iconLeftClass='icon-spin' onClick={() => {
                                                            if(event?.epingle){
                                                                dispatch(depingleEvent({
                                                                    articleId: event?._id
                                                                }))
                                                            }else{
                                                                dispatch(epingleEvent({
                                                                    articleId: event?._id
                                                                }))
                                                            }
                                                        }}/>

                                                        <Button btnType={'button'} label={banModal && isEditLoading ? "..." : event?.adminAction ? "Rendre visible" : "Rendre invisible"} btnStyle={'is--neutral'} active={!banModal} withIconLeft={banModal && isEditLoading} iconLeftClass='icon-spin' onClick={() => {
                                                            setBanModal(true)
                                                            if(event?.adminAction){
                                                                dispatch(unbanEvent({
                                                                    articleId: event?._id
                                                                }))
                                                            }else{
                                                                dispatch(banEvent({
                                                                    articleId: event?._id
                                                                }))
                                                            }
                                                        }}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                </div>
                                            </div>
                                            <div className='kubini-product-navigation'>
                                                <div className='kubini-product-navigation__nav kubini-product-navigation-nav'>
                                                <button type='button' className={`kubini-product-navigation-nav__item ${tabs ? (tabs === "presentation" ? "is--active" : "") : "is--active"}`} onClick={() => navigate(links.event + slug + `?tabs=presentation`)}>
                                                    <span>Description</span>
                                                </button>
                                                <button type='button' className={`kubini-product-navigation-nav__item ${tabs ? (tabs === "comments" ? "is--active" : "") : ""}`} onClick={() => navigate(links.event + slug + `?tabs=comments`)}>
                                                    <span>Commentaires ({event?.comments?.length > 0 ? formatNumber(event?.comments?.length) : "Aucun"} commentaire(s))</span>
                                                </button>
                                                </div>
                                                <div className={`kubini-product-navigation__container ${tabs ? (tabs === "presentation" ? "is--active" : "") : "is--active"}`}>
                                                <p>{event?.description?.text}</p>
                                                </div>
                                                <div className={`kubini-product-navigation__container ${tabs ? (tabs === "comments" ? "is--active" : "") : ""}`}>
                                                <div className='kubini-product-form'>
                                                    <div className='kubini-product-form__likes kubini-product-form-likes'>
                                                        <div className={`kubini-product-form-likes__btn`}>
                                                            <i className={isEditLoading && (typeLike === "like" || typeLike === "unlike") ? "icon-spin" : "icon-favorite-heart"}></i>
                                                            <span>{event?.likes ? formatNumber2(event?.likes.length) : "ND"}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='kubini-product-comments'>
                                                    <span>Les commentaires</span>
                                                    {
                                                        event?.comments?.filter((el: any) => el.supprime === false).sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).slice(0, readMore).map((item: any, index: number) => (
                                                        <div className='kubini-product-comment' key={index}>
                                                            <div className='kubini-product-comment__user'>
                                                            {
                                                                item?.user?.auth?.avatar ? (
                                                                    <img src={item?.user?.auth?.avatar?.url} alt="" />
                                                                ) : (
                                                                    <i className="icon-user-single"></i>
                                                                )
                                                            }
                                                            </div>
                                                            <div className='kubini-product-comment__container'>
                                                            <strong>{item?.user?.auth?.fullname}</strong>
                                                            <p>{item.content}</p>
                                                            <span>{capitalize(formatMyDate(item?.createdAt))}</span>
                                                            
                                                            <button type='button' onClick={() => {
                                                                setTypeLike("")
                                                                dispatch(uncommentEvent({articleId: event?._id, commentId: item?._id}))
                                                                setTypeLike("uncomment")
                                                            }}>
                                                                Supprimer
                                                            </button>
                                                                
                                                            </div>
                                                            
                                                        </div>
                                                        ))
                                                    }
                                                    {
                                                        event?.comments?.filter((el: any) => el.supprime === false)?.length > 12 ? (
                                                        <button className='kubini-home-products__more kubini-home-products-more' type='button' onClick={() => setReadMore(readMore + 12)}>
                                                            <div className='kubini-home-products-more-line'></div>
                                                            <span>Afficher plus</span>
                                                            <div className='kubini-home-products-more-line'></div>
                                                        </button>
                                                        ) : null
                                                    }
                                                </div>
                                                </div>
                                            </div>
                                            {
                                                event && isEpingleError && isoNewNotif ? (
                                                    <Toast id={"alert-notification"} status={"st-success"} fnc={() => setIsoNewNotif(false)}>
                                                        <p>{message}</p>
                                                    </Toast> 
                                                ) : null
                                            }
                                            {
                                                event && isEpingleSuccess && isoNewNotif ? (
                                                    <Toast id={"alert-notification"} status={"st-success"} fnc={() => setIsoNewNotif(false)}>
                                                        <p>{event?.epingle ? "Cet annonce a été epinglée" : "Cet annonce n'est plus epinglée"}</p>
                                                    </Toast> 
                                                ) : null
                                            }
                                            {
                                                event && isbNewNotif ? (
                                                    <Toast id={"alert-notification"} status={"st-success"} fnc={() => setIsbNewNotif(false)}>
                                                        <p>{message || event?.adminAction ? "Cet annonce a été invisibilisé" : "Cet annonce est  maintenant visible"}</p>
                                                    </Toast> 
                                                ) : null
                                            }
                                            {
                                                isNewNotif ? (
                                                <Toast id={"alert-notification"} status={"st-success"} fnc={() => setIsNewNotif(false)}>
                                                    <p>{message || (typeLike === "like" ? "Vous avez aimé cette annonce" : typeLike === "unlike" ? "Vous n'aimez plus cette annonce" : typeLike === "comment" ?  "Votre avis a été envoyé" : typeLike === "cart" ? "Vous avez enregistré dans le panier" : typeLike === "uncart" ? "Vous avez retiré du panier" : "Cet avis a bien été supprimé")}</p>
                                                </Toast>
                                                ) : null
                                            }
                                        </>
                                    ) : null
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}