import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from "./auth.service";

import {APIURL, getProtecteItems, updateProtecteItems} from "../../utils";
import { GeneralStateInterface } from '../../interface/GeneralStateInterface';

const token = window.localStorage.getItem('enka-admin-auth')

interface AuthState extends GeneralStateInterface{
    token?: string,
    me?:  any | null,

    isLoginError: boolean,
    isLoginLoading: boolean,
    isLoginSuccess: boolean,

    isAvatarError: boolean,
    isAvatarLoading: boolean,
    isAvatarSuccess: boolean,

    isPasswordError: boolean,
    isPasswordLoading: boolean,
    isPasswordSuccess: boolean,

    message: any
}

const initialState: AuthState = {
    token: token ? token : "",
    me: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isLoginError: false,
    isLoginLoading: false,
    isLoginSuccess: false,

    isAvatarError: false,
    isAvatarLoading: false,
    isAvatarSuccess: false,

    isPasswordError: false,
    isPasswordLoading: false,
    isPasswordSuccess: false,

    isEditError: false,
    isEditLoading: false,
    isEditSuccess: false,

    message: null,
}


export const login = createAsyncThunk<any, Object>(
    'auth/login',
    async (data: any, thunkAPI) => {
        try {
            return await authService.login(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getMe = createAsyncThunk<any, Object>(
    'auth/me',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}auth/me`,
                token: tokens,
                content
            }


            return await getProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const updateAvatar = createAsyncThunk<any, Object>(
    'auth/update-avatar',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}auth/update-avatar`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)



export const updateMyInformation = createAsyncThunk<any, Object>(
    'auth/update-information',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}auth/update-information`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const updatePassword = createAsyncThunk<any, Object>(
    'auth/update-password',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}auth/update-password`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const logout = createAsyncThunk<any, Object>(
    'auth/logout',
    async () => {
        authService.logout()
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state: any) => {
            state.isLoginError = false
            state.isLoginLoading = false
            state.isLoginSuccess = false

            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isAvatarError = false
            state.isAvatarError = false
            state.isAvatarSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isPasswordError = false
            state.isPasswordError = false
            state.isPasswordSuccess = false

            state.message = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoginLoading = true
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoginLoading = false
                state.isLoginSuccess = true
                // state.me = action.payload
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoginLoading = false
                state.isLoginError = true
                state.message = action.payload
                // state.me = null
            })


            .addCase(getMe.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMe.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.me = action.payload
            })
            .addCase(getMe.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.me = null
            })


            .addCase(updateAvatar.pending, (state) => {
                state.isAvatarLoading = true
            })
            .addCase(updateAvatar.fulfilled, (state, action) => {
                state.isAvatarLoading = false
                state.isAvatarSuccess = true
                state.me = action.payload
            })
            .addCase(updateAvatar.rejected, (state, action) => {
                state.isAvatarLoading = false
                state.isAvatarError = true
                state.message = action.payload
                // state.me = null
            })


            .addCase(updatePassword.pending, (state) => {
                state.isPasswordLoading = true
            })
            .addCase(updatePassword.fulfilled, (state, action) => {
                state.isPasswordLoading = false
                state.isPasswordSuccess = true
                // state.me = action.payload
            })
            .addCase(updatePassword.rejected, (state, action) => {
                state.isPasswordLoading = false
                state.isPasswordError = true
                state.message = action.payload
                // state.me = null
            })



            .addCase(updateMyInformation.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateMyInformation.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.me = action.payload
            })
            .addCase(updateMyInformation.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
                // state.me = null
            })

            .addCase(logout.fulfilled, (state) => {
                state.token = ""
            })
    },
})

export const { reset } = authSlice.actions
export default authSlice.reducer