import React, {FormEvent, useContext, useEffect, useState} from 'react';
import {AuthLayout} from "../../../../layout";
import {Button, GridLayout, Textfield} from "../../../../components";
import { useNavigate } from 'react-router-dom';
import {UserAuthContext} from "../../../../context/AuthContext";
import {links} from "../../../../routes";
import {login} from "../../../../../redux/feature/auth/auth.slice";
import {AlertModule} from "../../../../components/src/Alert";
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';

export const LoginPage = () => {
    const [data, setData] = useState<any>({
        login: "",
        password: "",
    })
    let navigate = useNavigate()
    const dispatch = useAppDispatch();
    const { isLoginLoading, isLoginSuccess, isLoginError, message } = useAppSelector((state) => state.auth);
    const { token } = useContext(UserAuthContext);

    useEffect(() => {
        if(token){
            navigate(links.home)
        }
    }, [token, navigate]);

    const [error, setError] = useState({
        field: "",
        message: ""
    })

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if(!data.login){
            setError({
                field: "login",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.password){
            setError({
                field: "password",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        setError({
            field: "",
            message: ""
        })
        dispatch(login(data))
    }

    useEffect(() => {
        if(isLoginSuccess){
            window.location.reload()
        }
    }, [navigate, isLoginSuccess]);

    return (
        <AuthLayout title={"Connexion"} subtitle={"Heureux de vous revoir"}>
            <form onSubmit={handleSubmit} className={"kubini-auth-form"}>
                <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--10"}>
                    {
                        isLoginError ? (
                            <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
                        ) : null
                    }
                    <div className={"kubini-auth-form__container"}>
                        <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--6"}>
                            <div className={"kubini-auth-form__field"}>
                                <Textfield
                                    id={"login"}
                                    value={data.login}
                                    require={true}
                                    changeValue={(val: string) => setData({...data, login: val})}
                                    type={"text"}
                                    label={"Nom d'utilisateur ou Email"}
                                    error={error}
                                    placeholder={"Veuillez insérer votre nom d'utilisateur ou Email"}
                                />
                            </div>
                            <div className={"kubini-auth-form__field"}>
                                <Textfield
                                    id={"password"}
                                    value={data.password}
                                    require={true}
                                    changeValue={(val: string) => setData({...data, password: val})}
                                    type={"password"}
                                    label={"Mot de passe"}
                                    placeholder={"Veuillez insérer votre mot de passe"}
                                    error={error}
                                />
                            </div>
                        </GridLayout>
                    </div>
                    <div className={"kubini-auth-form__action"}>
                        <Button btnType={"submit"} label={isLoginLoading ? "Connexion en cours..." : "Connexion"} btnStyle={"is--primary"} active={!!(data.login && data.password) || !isLoginLoading} withIconLeft={isLoginLoading} iconLeftClass={"icon-spin"}/>
                    </div>
                    <div className={"kubini-auth-form__info"}>
                        <p>En cas de perte de votre mot de passe. Veuillez contacter un des administrateurs</p>
                    </div>
                </GridLayout>
            </form>
        </AuthLayout>
    );
};