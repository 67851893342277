import {MainLayout} from "../../../../layout";
import {GridLayout} from "../../../../components";
import {useNavigate} from "react-router-dom";
import {links} from "../../../../routes";

export const SettingHomePage = () => {
  let navigate = useNavigate()
  const settingItems = [
    {
      link: links.setting_information,
      label: "Mes informations",
      description: "Modifier mes informations personnelles",
      icon: "icon-security-shield-person",
      isShow: true
    },
    {
      link: links.setting_password,
      label: "Mot de passe",
      description: "Modifier mon mot de passe",
      icon: "icon-setting-wrench",
      isShow: true
    },
    {
      link: links.setting_custom,
      label: "Customisation",
      description: "Customiser l'interface",
      icon: "icon-setting-slider",
      isShow: true
    },
  ]
  return (
    <MainLayout here={"setting"} title={"Setting"}>
      <div className={"kubini-page"}>
        <div className={"kubini-page__container"}>
          <div className={"kubini-page__header"}>
            <i className={"icon-setting-cog"}></i>
            <h1>Paramètres</h1>
            <p>Ici, vous pouvez modifier vos informations et votre mot de passe et personnaliser votre application.</p>
          </div>
          <div className={"kubini-page__content"}>
            <GridLayout direction={"fd--row"} justify={"jc--start"} wrap={true} align={"al--start"} gap={"g--10"}>
              {
                settingItems.map((item, index) => (
                  <div className={"kubini-page-item"} key={index}>
                    <button type={"button"} className={`kubini-page-item-btn ${item.isShow ? "" : "is--disable"}`} onClick={() => {
                      if(item.isShow) {
                        navigate(item.link)
                      }
                    }}>
                      <div className={"kubini-page-item-btn__icon"}>
                        <i className={item.icon}></i>
                      </div>
                      <div className={"kubini-page-item-btn__text"}>
                        <strong>{item.label}</strong>
                        <p>{item.description}</p>
                      </div>
                    </button>
                  </div>
                ))
              }
            </GridLayout>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
