import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {APIURL, getProtecteItems, updateProtecteItems,} from "../../utils";
import {GeneralStateInterface} from "../../interface/GeneralStateInterface";

interface EventState extends GeneralStateInterface{
    events:  any[],
    event:  any,
    isEpingleError: boolean,
    isEpingleSuccess: boolean,
    isEpingleLoading: boolean,
}
const initialState: EventState = {
    events: [],
    event: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isEpingleError: false,
    isEpingleSuccess: false,
    isEpingleLoading: false,

    message: null,
}

export const getEvents = createAsyncThunk<any, Object>(
    'event/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/list-for-admins`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const getEvent = createAsyncThunk<any, Object>(
    'event/show',
    async (slug: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/show/${slug}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)



export const banEvent = createAsyncThunk<any, Object>(
    'event/ban',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/ban`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const uncommentEvent = createAsyncThunk<any, Object>(
    'event/uncomment',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/delete-comment`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const unbanEvent = createAsyncThunk<any, Object>(
    'event/unban',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/unban`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const epingleEvent = createAsyncThunk<any, Object>(
    'article/epingler',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/epingle`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const depingleEvent = createAsyncThunk<any, Object>(
    'event/depingler',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}event/unepingle`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)



export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false


            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isEpingleLoading = false
            state.isEpingleError = false
            state.isEpingleSuccess = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEvents.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getEvents.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.events = action.payload
            })
            .addCase(getEvents.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getEvent.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getEvent.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.event = action.payload
            })
            .addCase(getEvent.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(uncommentEvent.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(uncommentEvent.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.event = action.payload
            })
            .addCase(uncommentEvent.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })


            .addCase(banEvent.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(banEvent.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.events = [action.payload, ...state.events.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.event = action.payload
            })
            .addCase(banEvent.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(unbanEvent.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(unbanEvent.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.events = [action.payload, ...state.events.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.event = action.payload
            })
            .addCase(unbanEvent.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(epingleEvent.pending, (state) => {
                state.isEpingleLoading = true
            })
            .addCase(epingleEvent.fulfilled, (state, action) => {
                state.isEpingleLoading = false
                state.isEpingleSuccess = true
                state.events = [action.payload, ...state.events.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.event = action.payload
            })
            .addCase(epingleEvent.rejected, (state, action) => {
                state.isEpingleLoading = false
                state.isEpingleError = true
                state.message = action.payload
            })

            .addCase(depingleEvent.pending, (state) => {
                state.isEpingleLoading = true
            })
            .addCase(depingleEvent.fulfilled, (state, action) => {
                state.isEpingleLoading = false
                state.isEpingleSuccess = true
                state.events = [action.payload, ...state.events.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.event = action.payload
            })
            .addCase(depingleEvent.rejected, (state, action) => {
                state.isEpingleLoading = false
                state.isEpingleError = true
                state.message = action.payload
            })

    }
})

export const { reset } = eventSlice.actions
export default eventSlice.reducer