import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {APIURL, getProtecteItems, setProtecteItems, updateProtecteItems,} from "../../utils";
import {GeneralStateInterface} from "../../interface/GeneralStateInterface";

interface PubState extends GeneralStateInterface{
    pubs:  any[],
    pub:  any,
}

const initialState: PubState = {

    pubs: [],
    pub: [],

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,
}

export const setPub = createAsyncThunk<any, Object>(
    'pub/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}pub/create`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getPubs = createAsyncThunk<any, Object>(
    'pub/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}pub/list-for-admins`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const getPub = createAsyncThunk<any, Object>(
    'pub/show',
    async (content, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}pub/show/${content}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const hidePub = createAsyncThunk<any, Object>(
    'pub/hide',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}pub/hide`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const showedPub = createAsyncThunk<any, Object>(
    'pub/showed',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}pub/showed`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const deletePub = createAsyncThunk<any, Object>(
    'pub/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}pub/delete`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)



export const pubSlice = createSlice({
    name: 'pub',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isDeleteLoading = false
            state.isDeleteError = false
            state.isDeleteSuccess = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPubs.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getPubs.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.pubs = action.payload
            })
            .addCase(getPubs.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getPub.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getPub.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.pub = action.payload
            })
            .addCase(getPub.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(setPub.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setPub.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.pubs = [action.payload, ...state.pubs]
            })
            .addCase(setPub.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })

            .addCase(hidePub.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(hidePub.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.pub = action.payload
                state.pubs = [action.payload, ...state.pubs.filter((pub: any) => pub?._id !== action.payload?._id)]
            })
            .addCase(hidePub.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(showedPub.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(showedPub.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.pub = action.payload
                state.pubs = [action.payload, ...state.pubs.filter((pub: any) => pub?._id !== action.payload?._id)]
            })
            .addCase(showedPub.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(deletePub.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deletePub.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.pubs = state.pubs.filter((pub: any) => pub?._id !== action.payload?._id)
            })
            .addCase(deletePub.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })
    }
})

export const { reset } = pubSlice.actions
export default pubSlice.reducer