import React, { useContext } from 'react';
import { ThemeContext } from './app/context/ThemeContext';
import { AuthContext } from './app/context/AuthContext';
import { WebRouter } from './app/routes';

function App() {
  const {
    theme,
    colorPrimary,
    textPosition,
    fontFamily,
    bordure,
    defaultDark,
    defaultTheme,
  } = useContext(ThemeContext);

  return (
    <AuthContext>
      <div  className={"kubini-app"}
            data-theme={defaultTheme === "ok" ? (defaultDark ? "dark" : "light") : theme}
            data-color={colorPrimary}
            data-align={textPosition}
            data-border={bordure}
            data-font={fontFamily}>
        <WebRouter/>
      </div>
    </AuthContext>
  );
}

export default App;
