import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {APIURL, getProtecteItems, updateProtecteItems,} from "../../utils";
import {GeneralStateInterface} from "../../interface/GeneralStateInterface";

interface ArticleState extends GeneralStateInterface{
    articles:  any[],
    article:  any,
    isEpingleError: boolean,
    isEpingleSuccess: boolean,
    isEpingleLoading: boolean,
}
const initialState: ArticleState = {
    articles: [],
    article: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isEpingleError: false,
    isEpingleSuccess: false,
    isEpingleLoading: false,

    message: null,
}

export const getArticles = createAsyncThunk<any, Object>(
    'article/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/list-for-admins`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const getArticle = createAsyncThunk<any, Object>(
    'article/show',
    async (slug: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/show/${slug}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)



export const banArticle = createAsyncThunk<any, Object>(
    'article/ban',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/ban`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const unbanArticle = createAsyncThunk<any, Object>(
    'article/unban',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/unban`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const epingleArticle = createAsyncThunk<any, Object>(
    'article/epingler',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/epingle`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const depingleArticle = createAsyncThunk<any, Object>(
    'article/depingler',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/unepingle`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const uncommentArticle = createAsyncThunk<any, Object>(
    'article/uncomment',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}article/delete-comment`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)




export const articleSlice = createSlice({
    name: 'article',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false


            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isEpingleLoading = false
            state.isEpingleError = false
            state.isEpingleSuccess = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getArticles.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getArticles.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.articles = action.payload
            })
            .addCase(getArticles.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getArticle.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getArticle.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.article = action.payload
            })
            .addCase(getArticle.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(uncommentArticle.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(uncommentArticle.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.articles = [action.payload, ...state.article.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.article = action.payload
            })

            .addCase(banArticle.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(banArticle.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.articles = [action.payload, ...state.articles.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.article = action.payload
            })
            .addCase(banArticle.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(unbanArticle.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(unbanArticle.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.articles = [action.payload, ...state.articles.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.article = action.payload
            })
            .addCase(unbanArticle.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(epingleArticle.pending, (state) => {
                state.isEpingleLoading = true
            })
            .addCase(epingleArticle.fulfilled, (state, action) => {
                state.isEpingleLoading = false
                state.isEpingleSuccess = true
                state.articles = [action.payload, ...state.articles.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.article = action.payload
            })
            .addCase(epingleArticle.rejected, (state, action) => {
                state.isEpingleLoading = false
                state.isEpingleError = true
                state.message = action.payload
            })

            .addCase(depingleArticle.pending, (state) => {
                state.isEpingleLoading = true
            })
            .addCase(depingleArticle.fulfilled, (state, action) => {
                state.isEpingleLoading = false
                state.isEpingleSuccess = true
                state.articles = [action.payload, ...state.articles.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.article = action.payload
            })
            .addCase(depingleArticle.rejected, (state, action) => {
                state.isEpingleLoading = false
                state.isEpingleError = true
                state.message = action.payload
            })

    }
})

export const { reset } = articleSlice.actions
export default articleSlice.reducer