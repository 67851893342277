import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { useParams } from 'react-router-dom';
import { banUser, getUser, reset, unbanUser } from '../../../../../redux/feature/user/user.slice';
import { BackButton, GridLayout } from '../../../../components';
import { MainLayout } from '../../../../layout';

export const UserPage = () => {
    const dispatch = useAppDispatch();
    const { isLoading, isSuccess, isError, user, isEditError, isEditLoading, isEditSuccess, message } = useAppSelector((state) => state.user);
    const [banModal, setBanModal] = useState<boolean>(false)
    const [isbNewNotif, setIsbNewNotif] = useState<boolean>(false)
    
    const {username} = useParams()
    // let navigate = useNavigate()
    
    useEffect(() => {
        if(username){
            dispatch(getUser(username))
        }
        return () => {
            dispatch(reset())
        }
    }, [dispatch, username]);
    
    useEffect(() => {
        if(isEditSuccess && banModal){
            setIsbNewNotif(true)
            setBanModal(false)
        }
    }, [isEditSuccess, banModal])

    useEffect(() => {
        if(isEditError && banModal){
            setIsbNewNotif(true)
            setBanModal(false)
        }
    }, [isEditError, banModal])
      
    return (
       
        <MainLayout title='Utilisateur' here='user' here_menu='user'>
            <div className={"kubini-account-page"}>
                <div><BackButton/></div>
                {
                    isLoading  ? (
                        <div className={"kubini-loader"}>
                            <i className="icon-spin"/>
                        </div>
                    ) : (
                        isError  ? (

                            <div className={"kubini-page-error"}>
                                <div className={"kubini-page-error__container"}>
                                    <i className="icon-delete-square"/>
                                    <div className={"kubini-page2-error__text"}>
                                        <h2>Oupss!!</h2>
                                        <p>{message }</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            isSuccess  && user  ? (
                                <GridLayout direction={'fd--column'} justify={'jc--start'} gap={"g--8"} align={'al--start'} >
                                    <div className={"kubini-account-banner"}>
                                        <GridLayout direction={"fd--row"} wrap={true} justify={"jc--start"} align={"al--start"} gap={"g--10"}>
                                            <div className={"kubini-account-avatar"}>
                                                <div className={"kubini-account-avatar-form-image"}>
                                                    {
                                                        user?.auth?.avatar ? (
                                                            <img src={user?.auth?.avatar?.url} alt="imageUrl"/>
                                                        ) : (<i className="icon-user-circle"></i>)
                                                    }
                                                </div>
                                            </div>

                                            <div className={`kubini-account-information`}>
                                                <div className={"kubini-account-information__icon"}>
                                                    <i className="icon-file-clipboard-text"></i>
                                                </div>
                                                <div className={"kubini-account-information__text kubini-account-information-text"}>
                                                    <GridLayout direction={"fd--column"}
                                                                justify={"jc--start"} align={"al--start"}
                                                                gap={"g--2"}>
                                                        <div className={"kubini-account-information-text__item"}>
                                                            <GridLayout direction={"fd--row"}
                                                                        justify={"jc--between"}
                                                                        align={"al--start"} gap={"g--2"}
                                                                        wrap={true}>
                                                                <span>Nom et prénom(s): </span>
                                                                <strong>{user?.auth?.fullname}</strong>
                                                            </GridLayout>
                                                        </div>
                                                        <div className={"kubini-account-information-text__item"}>
                                                            <GridLayout direction={"fd--row"}
                                                                        justify={"jc--between"}
                                                                        align={"al--start"} gap={"g--2"}
                                                                        wrap={true}>
                                                                <span>Identifiant: </span>
                                                                <strong>{user?.auth?.username || "ND"}</strong>
                                                            </GridLayout>
                                                        </div>
                                                        <div className={"kubini-account-information-text__item"}>
                                                            <GridLayout direction={"fd--row"}
                                                                        justify={"jc--between"}
                                                                        align={"al--start"} gap={"g--2"}
                                                                        wrap={true}>
                                                                <span>Email: </span>
                                                                <strong>{user?.auth?.email || "ND"}</strong>
                                                            </GridLayout>
                                                        </div>
                                                        <div className={"kubini-account-information-text__item"}>
                                                            <GridLayout direction={"fd--row"}
                                                                        justify={"jc--between"}
                                                                        align={"al--start"} gap={"g--2"}
                                                                        wrap={true}>
                                                                <span>Téléphone: </span>
                                                                <strong>{user?.auth?.telephone}</strong>
                                                            </GridLayout>
                                                        </div>
                                                        <div className={"kubini-account-information-text__item"}>
                                                            <GridLayout direction={"fd--row"}
                                                                        justify={"jc--between"}
                                                                        align={"al--start"} gap={"g--2"}
                                                                        wrap={true}>
                                                                <span>Ville de résidence: </span>
                                                                <strong>{user?.city?.name}</strong>
                                                            </GridLayout>
                                                        </div>
                                                    </GridLayout>
                                                </div>
                                            </div>

                                            <button type={"button"} className={"kubini-account-password"} onClick={() => {
                                                setBanModal(true)
                                                if(user?.adminAction){
                                                    dispatch(unbanUser({
                                                        id: user?._id
                                                    }))
                                                }else{
                                                    dispatch(banUser({
                                                        id: user?._id
                                                    }))
                                                }
                                            }}>
                                                <div className={"kubini-account-password__icon"}>
                                                    <i className={banModal && isEditLoading ? "icon-spin" : "icon-lock"}></i>
                                                </div>
                                                <div className={"kubini-account-password__text"}>
                                                    <strong>Action sur l'utilisateur</strong>
                                                    <p>{banModal && isEditLoading ? "..." : user?.adminAction ? "Debloquer le compte" : "Bannir l'utilisateur"}</p>
                                                </div>
                                            </button>
                                        </GridLayout>
                                    </div>
                                </GridLayout>
                            ) : null
                        )
                    )
                }
            </div>
        </MainLayout>
    )
}