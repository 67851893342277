import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { MainLayout } from '../../../../layout';
import { deletePub, getPubs, reset, setPub } from '../../../../../redux/feature/pub/pub.slice';
import { AlertModule, BackButton, Button, GridLayout, ModalLayout, Selectfield, Textfield } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { typePub } from '../../../../../utils/constant';
import { useNavigate } from 'react-router-dom';
import { links } from '../../../../routes';
import { format } from 'date-fns';
import { capitalize, slugifyFileName } from '../../../../../utils/function';
import { fr } from 'date-fns/locale';
import { APIURL } from '../../../../../redux/utils';


const AddModal = () => {
    let folder: string = "publicity"
    const [data, setData] = useState<any>({
        name: "",
        link: "",
        type_pub: "",
    })
    const dispatch = useAppDispatch();
    const { isCreateLoading, isCreateSuccess, isCreateError, message } = useAppSelector((state) => state.pub);
    
    const [error, setError] = useState<any>({
        field: "",
        message: ""
    })
    const [file, setFile] = useState<any>(null);
    const [size, setSize] = useState( 0);
    const [putUrl, setPutUrl] = useState("");
    const [infoImage, setInfoImage] = useState<any>(null);

    const fetchPresignedUrl = async () => {
        if(file){
            const response = await fetch(`${APIURL}presigned-url/put?folder=${folder}&id=pub-${slugifyFileName(file.name)}&expiry=86400`);
            const data = await response.json();
            setPutUrl(data.url)
            return data.url;
        }else{
        setError({
            field: "global",
            message: "Veuillez renseigner une image"
        })
        return
        }
    };
  
    const handleFileUpload = async () => {
        const fileUrl = file
        const response = await fetch(putUrl, {
          method: 'PUT',
          body: fileUrl,
        });
        if (response.ok) {
          const photoUrl = putUrl.split('?')[0];
          setInfoImage({
            url: photoUrl,
            size,
            type: "image"
          })
          
        } else {
          setError({
            field: "global",
            message: "Une erreur est survenue lors de l'enregistrement de l'images"
          })
          return
        }
      };
    
    const handleFileChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
        const file = e.target.files
        if(file && file.length > 0){
            let type: number = file[0].size
            let max: number = 1024 * 1024 * 8
            if(type < max){
                setSize(type)
                setFile(file[0])
            }else{
                setError({
                    field: "global",
                    message: "Votre fichier ne doit pas dépasser 8Mo"
                })
                return
            }
        }
    }
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if(!data.name){
            setError({
                field: "name",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.link){
            setError({
                field: "link",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.type_pub){
            setError({
                field: "type_pub",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        setError(null)
        fetchPresignedUrl()
    }
  
    useEffect(() => {
        if(putUrl){
          handleFileUpload()
        }
    }, [putUrl]);
    
    useEffect(() => {
        if(infoImage){
          let datas = {
            ...data,
            image: infoImage
          }
          dispatch(setPub(datas))
        }
    }, [infoImage, data]);

    return (
        <>
            {
                isCreateSuccess ? (
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon is--success"}>
                                <i className="icon-validation-check"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Création de la publicité</h2>
                                <p>La publicité a été créée avec succès</p>
                                <div className={"kubini-modal-form-status__action"}>
                                    <Button
                                        btnType={"button"}
                                        label={"Fermer..."}
                                        btnStyle={"is--neutral"}
                                        active={true}
                                        withIconLeft={false}
                                        onClick={() => {
                                            window.location.reload()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <form className={"kubini-modal-form__container"} onSubmit={handleSubmit}>
                        <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--10"}>
                            {
                                isCreateError ? (
                                    <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
                                ) : (
                                    error?.field === "file" ? (
                                        <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={error?.message}/>
                                    ) : null
                                )
                            }
                            <div className={"kubini-modal-form__fields"}>
                                <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--8"}>
                                    <div className={"kubini-modal-form__field"}>
                                        <Textfield
                                            id={"name"}
                                            value={data.name}
                                            require={true}
                                            changeValue={(value: any) => setData({...data, name: value})}
                                            type={"text"}
                                            label={"Nom"}
                                            placeholder={"ex: vêtements homme"}
                                            error={error}
                                        />
                                    </div>
                                    <div className={"kubini-modal-form__field"}>
                                        <Textfield
                                            id={"link"}
                                            value={data.link}
                                            require={true}
                                            changeValue={(value: any) => setData({...data, link: value})}
                                            type={"text"}
                                            label={"Lien vers"}
                                            placeholder={"ex: https://www.facebook.com/...."}
                                            error={error}
                                        />
                                    </div>
                                    <div className={"kubini-modal-form__field"}>
                                        <Selectfield
                                            id={"type_pub"}
                                            value={data.type_pub}
                                            require={true}
                                            changeValue={(value: any) => setData({...data, type_pub: value})}
                                            tabs={typePub}
                                            label={"Emplacement de la pub"}
                                            placeholder={"Choisir..."}
                                            error={error}
                                        />
                                    </div>
                                    <div className={"kubini-modal-form__field"}>
                                        <div className={"kubini-filefield"}>
                                            <input type="file" accept='.jpg,.png,jpeg,.webp' id='pubImg' onChange={handleFileChangeFile}/>
                                            <label htmlFor="pubImg">
                                                <div className={`kubini-filefield__label force--open`}>
                                                    {
                                                        file ? (
                                                            <>
                                                                <i className={"icon-file-check"}/>
                                                                <p>
                                                                    <strong>{slugifyFileName(file?.name)}</strong> <br/>
                                                                    <span>Cliquer pour changer l'image de la publicité (4Mo max)</span>
                                                                </p>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <i className={"icon-file-text"}/>
                                                                <span>Cliquer pour changer l'image de la publicité (4Mo max)</span>
                                                            </>
                                                        )
                                                    }

                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </GridLayout>
                            </div>
                            <div className={"kubini-modal-form__action"}>
                                <Button btnType={"submit"} label={isCreateLoading ? "Création en cours..." : "Ajouter la publicité"} btnStyle={"is--primary"} active={!isCreateLoading} withIconLeft={true} iconLeftClass={isCreateLoading ? "icon-spin" :"icon-storage-floppy-disk"}/>
                            </div>
                        </GridLayout>
                    </form>
                )
            }
        </>
    )
}

const DeleteModal = ({item}: { item: any }) => {
    const dispatch = useAppDispatch();
    const {isDeleteLoading, isDeleteError, isDeleteSuccess, message } = useAppSelector((state) => state.pub);
    return (
        <>
            {
                isDeleteSuccess ? (
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon is--success"}>
                                <i className="icon-validation-check"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Suppression d'une publ</h2>
                                <p>L'élément a bien été supprimé avec succès</p>
                                <div className={"kubini-modal-form-status__action"}>
                                    <Button
                                        btnType={"button"}
                                        label={ "Fermer..."}
                                        btnStyle={"is--neutral"}
                                        active={true}
                                        withIconLeft={false}
                                        onClick={() => {
                                            window.location.reload()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon is--danger"}>
                                <i className="icon-delete-bin"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Suppression de la pub</h2>
                                {
                                    isDeleteError ? (
                                        <p>
                                            <strong>Ouppss!!!</strong> <br/>
                                            <span>{message}</span>
                                        </p>
                                    ) : (
                                        <p>Souhaitez-vous vraiment supprimer cet élément</p>
                                    )
                                }

                                <div className={"kubini-modal-form-status__action"}>
                                    {
                                        isDeleteError ? (
                                            <Button
                                                btnType={"button"}
                                                label={isDeleteLoading ? "Suppression en cours" : "Réessayer..."}
                                                btnStyle={"is--danger"}
                                                active={!isDeleteLoading}
                                                withIconLeft={true}
                                                iconLeftClass={isDeleteLoading ? "icon-spin" : "icon-delete-bin"}
                                                onClick={() => {
                                                    dispatch(deletePub({id: item?._id}))
                                                }}
                                            />
                                        ) : (
                                            <Button
                                                btnType={"button"}
                                                label={isDeleteLoading ? "Suppression en cours" : "Confirmer la suppression"}
                                                btnStyle={"is--danger"}
                                                active={!isDeleteLoading}
                                                withIconLeft={true}
                                                iconLeftClass={isDeleteLoading ? "icon-spin" : "icon-delete-bin"}
                                                onClick={() => {
                                                    dispatch(deletePub({id: item?._id}))
                                                }}
                                            />
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export const PubsPage = () => {
    const [search, setSearch] = useState<string>("")
    const [numberItem, setNumberItem] = useState<number>(15)
    const [startList, setStartList] = useState<number>(1)
    const [addCategoryModal, setAddCategoryModal] = useState<boolean>(false)
    const [deleteCategoryModal, setDeleteCategoryModal] = useState<boolean>(false)
    const [itemCategoryModal, setItemCategoryModal] = useState<any>(null)
    const [chooseApercu, setChosseApercu] = useState<string>("grid")
    const dispatch = useAppDispatch();
    const { isLoading, isSuccess, isError, pubs, message } = useAppSelector((state) => state.pub);
    let navigate = useNavigate()
    useEffect(() => {
        // @ts-ignore
        dispatch(getPubs())

        return () => {
            dispatch(reset())
        }
    }, [dispatch])

    return (
        <MainLayout title='Publictiés' here='pub'>
            <div className={"kubini-page"}>
                <BackButton/>
                <div className={"kubini-page__container"}>
                    {
                        isLoading ? (
                            <div className={"kubini-loader"}>
                                <i className="icon-spin"/>
                            </div>
                        ) : (
                            isError ? (
                                <div className={"kubini-page-error"}>
                                    <div className={"kubini-page-error__container"}>
                                        <i className="icon-delete-square"/>
                                        <div className={"kubini-page2-error__text"}>
                                            <h2>Oupss!!</h2>
                                            <p>{message}</p>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                isSuccess && pubs ? (
                                    <>
                                        <div className={"kubini-page__header"}>
                                            <h2>Les publicités</h2>
                                        </div>
                                        <div className={"kubini-page__searchActions kubini-page-searchActions"}>
                                            <GridLayout direction={"fd--row"} gap={"g--6"} justify={"jc--start"} align={"al--start"} wrap={true}>
                                                <div className={"kubini-page-actions"}>
                                                    <GridLayout direction={"fd--row"} gap={"g--6"} justify={"jc--start"} align={"al--start"} wrap={true}>
                                                        <Button
                                                            btnType={"button"}
                                                            label={"Ajouter une pub"}
                                                            btnStyle={"is--primary"}
                                                            active={true}
                                                            withIconLeft={true}
                                                            iconLeftClass={"icon-add-square"}
                                                            onClick={() => setAddCategoryModal(true)}
                                                        />
                                                    </GridLayout>
                                                </div>
                                                <div className={"kubini-page-search"}>
                                                    <i className="icon-search"/>
                                                    <input
                                                        type="search"
                                                        value={search}
                                                        onChange={(e) => setSearch(e.target.value)}
                                                        placeholder={"search..."}
                                                    />
                                                </div>
                                                <div className={"kubini-page-searchItem"}>
                                                    <span>Nombre à afficher</span>
                                                    <input
                                                        type="number"
                                                        value={numberItem}
                                                        min={5}
                                                        onChange={(e) => setNumberItem(parseInt(e.target.value))}
                                                    />
                                                </div>
                                                <div className={"kubini-page-searchItem"}>
                                                    <span>Commencer par</span>
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        value={startList}
                                                        onChange={(e) => setStartList(parseInt(e.target.value))}
                                                    />
                                                </div>
                                            </GridLayout>
                                        </div>
                                        <div className={'kubini-page__list kubini-page-list'}>
                                            <div className={'kubini-page-list-head'}>
                                                <div className={'kubini-page-list-head__text'}>
                                                    <span>{pubs.filter((el: any) => el.name.toLowerCase().includes(search.toLowerCase())).length} élément(s) trouvé(s)</span>
                                                </div>
                                                <div className={'kubini-page-list-head-actions'}>
                                                    <button
                                                        type={'button'}
                                                        className={`kubini-page-list-head-action ${chooseApercu === 'grid' ? 'is--active' : ''}`}
                                                        onClick={() => setChosseApercu('grid')}
                                                    >
                                                        <i className={'icon-dashboard'} />
                                                    </button>
                                                    <button
                                                        type={'button'}
                                                        className={`kubini-page-list-head-action ${chooseApercu === 'list' ? 'is--active' : ''}`}
                                                        onClick={() => setChosseApercu('list')}
                                                    >
                                                        <i className={'icon-list-bullets'} />
                                                    </button>
                                                </div>
                                            </div>
                                            {
                                                pubs
                                                    .filter((el: any) => el.name.toLowerCase().includes(search.toLowerCase()))
                                                    .length > 0 ? (
                                                        <div className={'kubini-page-list-container'}>
                                                            {
                                                                chooseApercu === 'grid' ? (
                                                                    <div className={'kubini-page-list-grid'}>
                                                                        <GridLayout
                                                                            direction={'fd--row'}
                                                                            wrap={true}
                                                                            justify={'jc--start'}
                                                                            align={'al--start'}
                                                                            gap={'g--4'}
                                                                        >
                                                                            {
                                                                                pubs
                                                                                    .filter((el: any) => el.name.toLowerCase().includes(search.toLowerCase()))
                                                                                    .slice((startList - 1), (numberItem + (startList - 1)))
                                                                                    .map((item: any, index: any) => (
                                                                                        <div className={'kubini-page-list-grid-item'} key={index}>
                                                                                            <div className={'kubini-category-card'}>
                                                                                                <div className={'kubini-category-card__container'}>
                                                                                                    <div className={'kubini-category-card__icon'}>
                                                                                                        <i className="icon-mega-phone" />
                                                                                                    </div>
                                                                                                    <div className={'kubini-category-card__text'}>
                                                                                                        <h2>{item.name}</h2>
                                                                                                        <a href={item?.link} target='_blank' rel='noreferrer' className='kubini-link'>{item?.link}</a>
                                                                                                        <p>
                                                                                                            Ajouter le {item.createdAt ? capitalize(format(new Date(item.createdAt), "EEEE d MMMM yyyy à HH:mm:ss", {locale: fr})) : "ND"}
                                                                                                        </p>
                                                                                                        <div className={'kubini-category-card__actions'}>
                                                                                                            <button
                                                                                                                type={'button'}
                                                                                                                className={'kubini-category-card__action'}
                                                                                                                onClick={() => {
                                                                                                                    navigate(links.pub + item.slug)
                                                                                                                }}
                                                                                                            >
                                                                                                                <i className="icon-view" /> <span>Voir</span>
                                                                                                            </button>
                                                                                                            <button
                                                                                                                type={'button'}
                                                                                                                className={'kubini-category-card__action is--danger'}
                                                                                                                onClick={() => {
                                                                                                                    setItemCategoryModal(null)
                                                                                                                    setItemCategoryModal(item)
                                                                                                                    setDeleteCategoryModal(true)
                                                                                                                }}
                                                                                                            >
                                                                                                                <i className="icon-delete-bin" /> <span>Supprimer</span>
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    ))
                                                                            }
                                                                        </GridLayout>
                                                                    </div>
                                                                ) : (
                                                                    <div className={'kubini-page-list-list'}>
                                                                        <div className={'kubini-page-list-list__container'}>
                                                                        <div className={'kubini-page-list-list-header'}>
                                                                            <GridLayout
                                                                            direction={'fd--row'}
                                                                            justify={'jc--start'}
                                                                            align={'al--start'}
                                                                            gap={'g--0'}
                                                                            >
                                                                            <div className={'kubini-page-list-list-header-id'}>
                                                                                <strong>#</strong>
                                                                            </div>
                                                                            <div className={'kubini-page-list-list-header-large'}>
                                                                                <strong>Nom</strong>
                                                                            </div>
                                                                            <div className={'kubini-page-list-list-header-large'}>
                                                                                <strong>Lien vers</strong>
                                                                            </div>
                                                                            <div className={'kubini-page-list-list-header-mid'}>
                                                                                <strong>Ajouté le</strong>
                                                                            </div>
                                                                            <div className={'kubini-page-list-list-header-actions'}>
                                                                                <strong>Actions</strong>
                                                                            </div>
                                                                            </GridLayout>
                                                                        </div>
                                                                        <div className={'kubini-page-list-list-items'}>
                                                                            <GridLayout
                                                                                direction={'fd--column'}
                                                                                justify={'jc--start'}
                                                                                align={'al--start'}
                                                                                gap={'g--0'}
                                                                                wrap={true}
                                                                            >
                                                                            {
                                                                                pubs
                                                                                    .filter((el: any) => el.name.toLowerCase().includes(search.toLowerCase()))
                                                                                    .slice((startList - 1), (numberItem + (startList - 1)))
                                                                                    .map((item: any, index: any) => (
                                                                                        <div className={'kubini-page-list-list-item'} key={index}>
                                                                                            <GridLayout
                                                                                                direction={'fd--row'}
                                                                                                justify={'jc--start'}
                                                                                                align={'al--start'}
                                                                                                gap={'g--0'}
                                                                                            >
                                                                                            <div className={'kubini-page-list-list-item-id'}>
                                                                                                <strong>{index + 1}</strong>
                                                                                            </div>
                                                                                            <div className={'kubini-page-list-list-item-large'}>
                                                                                                <span>{item.name}</span>
                                                                                            </div>
                                                                                            <div className={'kubini-page-list-list-item-large'}>
                                                                                                <a href={item?.link} target='_blank' rel='noreferrer' className='kubini-link'>{item?.link}</a>
                                                                                            </div>
                                                                                            <div className={'kubini-page-list-list-item-mid'}>
                                                                                                <strong>{item.createdAt ? capitalize(format(new Date(item.createdAt), "EEEE d MMMM yyyy à HH:mm:ss", {locale: fr})) : "ND"}</strong>
                                                                                            </div>
                                                                                            <div className={'kubini-page-list-list-item-actions'}>
                                                                                                <button
                                                                                                    type={'button'}
                                                                                                    className={'kubini-page-list-list-item-action'}
                                                                                                    onClick={() => {
                                                                                                        navigate(links.pub + item.slug)
                                                                                                    }}
                                                                                                >
                                                                                                    <i className="icon-edit-write-2" /> <span>Voir</span>
                                                                                                </button>
                                                                                                <button
                                                                                                    type={'button'}
                                                                                                    className={'kubini-page-list-list-item-action is--danger'}
                                                                                                    onClick={() => {
                                                                                                        setItemCategoryModal(null)
                                                                                                        setItemCategoryModal(item)
                                                                                                        setDeleteCategoryModal(true)
                                                                                                    }}
                                                                                                >
                                                                                                    <i className="icon-edit-write-2" /> <span>Supprimer</span>
                                                                                                </button>
                                                                                            </div>
                                                                                            </GridLayout>
                                                                                        </div>

                                                                                    ))
                                                                            }
                                                                            </GridLayout>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    ) : (
                                                        <strong>Aucun élément trouvé...</strong>
                                                    )
                                            }
                                        </div>
                                    </>
                                ) : null
                            )
                        )
                    }
                    <ModalLayout
                        size={'is--md'}
                        id={'add-modal'}
                        isOpen={addCategoryModal}
                        fnc={(value: boolean) => setAddCategoryModal(value)}
                        withClose={true}
                    >
                        <div className={'kubini-modal-form'}>
                            <div className={'kubini-modal-form__header'}>
                                <h2>Ajout d'une publicité</h2>
                            </div>
                            <AddModal />
                        </div>
                    </ModalLayout>
                    {
                        itemCategoryModal ? (
                            <ModalLayout
                                size={'is--md'}
                                isOpen={deleteCategoryModal}
                                id={'delete-modal'}
                                fnc={(value: boolean) => setDeleteCategoryModal(value)}
                                withClose={true}
                            >
                                <div className={'kubini-modal-form'}>
                                    <DeleteModal item={itemCategoryModal} />
                                </div>
                            </ModalLayout>
                        ) : null
                    }

                </div>
            </div>
        </MainLayout>
    )
}