import {FC, ReactNode, useContext, useEffect, useState} from "react";
import {titlePage} from "../../../utils/function";
import {links} from "../../routes";
import {useNavigate} from "react-router-dom";
import {Button, LogoIcon} from "../../components";
import { UserAuthContext } from "../../context/AuthContext";

interface MainLayoutParams {
  title?: string,
  here?: string,
  here_menu?: string,
  children?: ReactNode
}
export const MainLayout:FC<MainLayoutParams> = ({title, here, here_menu, children}) => {
  titlePage(title)
  const { info, token, isLoading, isError, logOut } = useContext(UserAuthContext);

  const navItems = [
    {
      link: links.home,
      label: "Home",
      icon: "icon-home-1",
      isActive: here === "home" ? "is--active" : "",
      isShow: true
    },
    {
      link: links.users,
      label: "Utilisateurs",
      icon: "icon-user-multiple",
      isActive: here === "user" ? "is--active" : "",
      isShow: true
    },
    {
      link: links.platform,
      label: "Plateforme",
      icon: "icon-align-layers",
      isActive: here === "platform" ? "is--active" : "",
      isShow: true
    },
    {
      link: links.pubs,
      label: "Publicité",
      icon: "icon-mega-phone",
      isActive: here === "pub" ? "is--active" : "",
      isShow: true
    },
    {
      link: links.admins,
      label: "Administration",
      icon: "icon-security-shield-person",
      isActive: here === "admin" ? "is--active" : "",
      isShow: true
    },
    {
      link: links.setting,
      label: "Configuration",
      icon: "icon-setting-cog",
      isActive: here === "setting" ? "is--active" : "",
      isShow: true
    },
  ]

  const menuItems = [
    {
      title: "Utilisateurs",
      links: [
        {
          link: links.users,
          label: "Utilisateurs",
          icon: "icon-user-multiple",
          isActive: here_menu === "user" ? "is--active" : "",
          isShow: true
        },
        {
          link: links.feedbacks,
          label: "Retours clients",
          icon: "icon-chat-two-bubbles-oval",
          isActive: here_menu === "feedback" ? "is--active" : "",
          isShow: true
        },
        {
          link: links.cities,
          label: "Villes",
          icon: "icon-building",
          isActive: here_menu === "city" ? "is--active" : "",
          isShow: true
        },
      ],
    },
    {
      title: "Articles",
      links: [
        {
          link: links.categories,
          label: "Categories",
          icon: "icon-application-remove",
          isActive: here_menu === "category" ? "is--active" : "",
          isShow: true
        },
        {
          link: links.subcategories,
          label: "Sous-categories",
          icon: "icon-module-three",
          isActive: here_menu === "subcategory" ? "is--active" : "",
          isShow: true
        },
        {
          link: links.articles,
          label: "Articles",
          icon: "icon-shopping-cart",
          isActive: here_menu === "article" ? "is--active" : "",
          isShow: true
        },
      ]
    },
    {
      title: "Plateforme",
      links: [
        {
          link: links.events,
          label: "Evenements",
          icon: "icon-calendar-mark",
          isActive: here_menu === "event" ? "is--active" : "",
          isShow: true
        },
        {
          link: links.posts,
          label: "Publications",
          icon: "icon-copy-clipboard",
          isActive: here_menu === "post" ? "is--active" : "",
          isShow: true
        },
        {
            link: links.partners,
            label: "Nos partenaires",
            icon: "icon-content-book-phone",
            isActive: here_menu === "partner" ? "is--active" : "",
            isShow: true
        },
      ]
    },
    {
      title: "Paramètre",
      links: [
        {
          link: links.setting_information,
          label: "Mes informations",
          icon: "icon-edit-write-2",
          isActive: here_menu === "edit-info" ? "is--active" : "",
          isShow: true
        },
        {
          link: links.setting_password,
          label: "Mot de passe",
          icon: " icon-lock",
          isActive: here_menu === "edit-password" ? "is--active" : "",
          isShow: true
        },
        {
          link: links.setting_custom,
          label: "Customiser l'interface",
          icon: "icon-security-shield-profile",
          isActive: here_menu === "custom" ? "is--active" : "",
          isShow: true
        },
      ]
    },
  ]

  const [openMenu, setOpenMenu] = useState<boolean>(false)

  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login)
    }
  }, [token, navigate]);

  if(isLoading){
    return (
        <div className={"kubini-loader"}>
          <i className="icon-spin"/>
        </div>
    )
  }

  if(isError){
    return (
        <div className={"kubini-user-error"}>
          <div className={"kubini-user-error__container"}>
            <i className="icon-warning-triangle"/>
            <h2>Oupss!! Votre session a expiré</h2>
            <div className={"kubini-user-error__action"}>
              <Button btnType={"button"} label={"Se reconnecter"} btnStyle={"is--primary"} active={true} onClick={() => logOut()}/>
            </div>
          </div>
        </div>
    )
  }

  return (
    <>
      <main role={"main"} className={"kubini-main"}>
        {children}
      </main>
      <div className={"kubini-navigation"}>
        <div className={"kubini-navigation__container"}>
          <div className={`kubini-navigation__mainItem kubini-navigation-mainItem ${openMenu ? "is--open" : ""}`}>
            <button
              type={"button"}
              className={`kubini-navigation-mainItem-btn`}
              onClick={() => setOpenMenu(!openMenu)}
            >
              <i className="icon-dashboard-layout-circle"></i>
              <strong>Enka-market</strong>
            </button>
          </div>
          <div className={"kubini-navigation__items kubini-navigation-items"}>
            {
              navItems
                .filter((el) => el.isShow)
                .map((item, index) => (
                  <button type={"button"} className={`kubini-navigation-item ${item.isActive}`} key={index}
                          onClick={() => navigate(item.link)}>
                    <i className={item.icon}></i>
                    <span>{item.label}</span>
                  </button>
                ))
            }
          </div>
        </div>
      </div>
      <div className={`kubini-menu ${openMenu ? "is--open" : ""}`}>
        <div className={"kubini-menu__container"}>
          <div className={"kubini-menu__header"}>
              <div className={"kubini-menu__logo"}>
                <LogoIcon/>
              </div>
              <h2>Administration Enka-market</h2>
          </div>
          <div className={"kubini-menu__list"}>
            {
              menuItems.map((item, index) => (
                item.links.filter((el) => el.isShow).length > 0 && (
                  <div className={"kubini-menu__items kubini-menu-items"} key={index}>
                    <strong>{item.title}</strong>
                    <div className={"kubini-menu-items__list"}>
                      {
                        item.links && item.links.filter((el) => el.isShow).map((subitem, key) => (
                          <button type={"button"} className={`kubini-menu-item ${subitem.isActive}`} key={key}
                                  onClick={() => navigate(subitem.link)}>
                            <i className={subitem.icon}></i>
                            <span>{subitem.label}</span>
                          </button>
                        ))
                      }
                    </div>
                  </div>
                )
              ))
            }
          </div>
          <div className={"kubini-menu__account kubini-menu-account"}>
            <button type={"button"} className={"kubini-menu-account-user"} onClick={() => navigate(links.account)}>
              <div className={"kubini-menu-account-user__avatar"}>
                {
                  info?.avatar ? (
                      <img src={info?.avatar?.url} alt="avatar"/>
                  ) : (
                      <i className="icon-user-circle"></i>
                  )
                }
              </div>
              <div className={"kubini-menu-account-user__info"}>
                <strong>{info?.fullname}</strong>
                <span>{`#`+info?.username}</span>
              </div>
            </button>
            <button type={"button"} className={"kubini-menu-account-logout"} onClick={() => logOut()}>
              <i className="icon-logout"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
