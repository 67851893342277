import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { capitalize, slugifyFileName } from '../../../../utils/function';
import { AlertModule, BackButton, Button, GridLayout, ModalLayout, Textfield } from '../../../components';
import { deletePartner, getPartners, reset, setPartner, updatePartner } from '../../../../redux/feature/partner/partner.slice';
import { APIURL } from '../../../../redux/utils';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from '../../../layout';
import PlatformMenu from '../platform/module/PlatformMenu';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';


const AddModal = () => {
    let folder: string = "partner"
    const [data, setData] = useState<any>({
        name: "",
        link: "",
    })
    const dispatch = useAppDispatch();
    const { isCreateLoading, isCreateSuccess, isCreateError, message } = useAppSelector((state: any) => state.partner);
    
    const [error, setError] = useState<any>({
        field: "",
        message: ""
    })
    const [file, setFile] = useState<any>(null);
    const [size, setSize] = useState( 0);
    const [putUrl, setPutUrl] = useState("");
    const [infoImage, setInfoImage] = useState<any>(null);

    const fetchPresignedUrl = async () => {
        if(file){
            const response = await fetch(`${APIURL}presigned-url/put?folder=${folder}&id=partner-${slugifyFileName(file.name)}&expiry=86400`);
            const data = await response.json();
            setPutUrl(data.url)
            return data.url;
        }else{
        setError({
            field: "global",
            message: "Veuillez renseigner une image"
        })
        return
        }
    };
  
    const handleFileUpload = async () => {
        const fileUrl = file
        const response = await fetch(putUrl, {
          method: 'PUT',
          body: fileUrl,
        });
        if (response.ok) {
          const photoUrl = putUrl.split('?')[0];
          setInfoImage({
            url: photoUrl,
            size,
            type: "image"
          })
          
        } else {
          setError({
            field: "global",
            message: "Une erreur est survenue lors de l'enregistrement de l'images"
          })
          return
        }
      };
    
    const handleFileChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
        const file = e.target.files
        if(file && file.length > 0){
            let type: number = file[0].size
            let max: number = 1024 * 1024 * 8
            if(type < max){
                setSize(type)
                setFile(file[0])
            }else{
                setError({
                    field: "global",
                    message: "Votre fichier ne doit pas dépasser 8Mo"
                })
                return
            }
        }
    }
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if(!data.name){
            setError({
                field: "name",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.link){
            setError({
                field: "link",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        setError(null)
        fetchPresignedUrl()
    }
  
    useEffect(() => {
        if(putUrl){
          handleFileUpload()
        }
    }, [putUrl]);
    
    useEffect(() => {
        if(infoImage){
          let datas = {
            ...data,
            image: infoImage
          }
          dispatch(setPartner(datas))
        }
    }, [infoImage, data]);

    return (
        <>
            {
                isCreateSuccess ? (
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon is--success"}>
                                <i className="icon-validation-check"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Création du partenaire</h2>
                                <p>Le partenaire a été créée avec succès</p>
                                <div className={"kubini-modal-form-status__action"}>
                                    <Button
                                        btnType={"button"}
                                        label={"Fermer..."}
                                        btnStyle={"is--neutral"}
                                        active={true}
                                        withIconLeft={false}
                                        onClick={() => {
                                            window.location.reload()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <form className={"kubini-modal-form__container"} onSubmit={handleSubmit}>
                        <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--10"}>
                            {
                                isCreateError ? (
                                    <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
                                ) : (
                                    error?.field === "file" ? (
                                        <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={error?.message}/>
                                    ) : null
                                )
                            }
                            <div className={"kubini-modal-form__fields"}>
                                <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--8"}>
                                    <div className={"kubini-modal-form__field"}>
                                        <Textfield
                                            id={"name"}
                                            value={data.name}
                                            require={true}
                                            changeValue={(value: any) => setData({...data, name: value})}
                                            type={"text"}
                                            label={"Nom"}
                                            placeholder={"ex: vêtements homme"}
                                            error={error}
                                        />
                                    </div>
                                    <div className={"kubini-modal-form__field"}>
                                        <Textfield
                                            id={"link"}
                                            value={data.link}
                                            require={true}
                                            changeValue={(value: any) => setData({...data, link: value})}
                                            type={"text"}
                                            label={"Lien vers"}
                                            placeholder={"ex: https://www.facebook.com/...."}
                                            error={error}
                                        />
                                    </div>
                                    
                                    <div className={"kubini-modal-form__field"}>
                                        <div className={"kubini-filefield"}>
                                            <input type="file" accept='.jpg,.png,jpeg,.webp' id='pubImg' onChange={handleFileChangeFile}/>
                                            <label htmlFor="pubImg">
                                                <div className={`kubini-filefield__label force--open`}>
                                                    {
                                                        file ? (
                                                            <>
                                                                <i className={"icon-file-check"}/>
                                                                <p>
                                                                    <strong>{slugifyFileName(file?.name)}</strong> <br/>
                                                                    <span>Cliquer pour changer l'image du partenaire (4Mo max)</span>
                                                                </p>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <i className={"icon-file-text"}/>
                                                                <span>Cliquer pour changer l'image du partenaire (4Mo max)</span>
                                                            </>
                                                        )
                                                    }

                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </GridLayout>
                            </div>
                            <div className={"kubini-modal-form__action"}>
                                <Button btnType={"submit"} label={isCreateLoading ? "Création en cours..." : "Ajouter le partenaire"} btnStyle={"is--primary"} active={!isCreateLoading} withIconLeft={true} iconLeftClass={isCreateLoading ? "icon-spin" :"icon-storage-floppy-disk"}/>
                            </div>
                        </GridLayout>
                    </form>
                )
            }
        </>
    )
}

const EditModal = ({item}:{item: any}) => {
    let folder: string = "partner"
    const [data, setData] = useState<any>({
        id: item?._id,
        name: item?.name || "",
        link: item?.link || "",
        image: item?.image || ""
    })
    const dispatch = useAppDispatch();
    const { isEditLoading, isEditSuccess, isEditError, message } = useAppSelector((state: any) => state.partner);
    
    const [error, setError] = useState<any>({
        field: "",
        message: ""
    })
    const [file, setFile] = useState<any>(null);
    const [size, setSize] = useState( 0);
    const [putUrl, setPutUrl] = useState("");
    const [infoImage, setInfoImage] = useState<any>(null);

    const fetchPresignedUrl = async () => {
        if(file){
            const response = await fetch(`${APIURL}presigned-url/put?folder=${folder}&id=pub-${slugifyFileName(file.name)}&expiry=86400`);
            const data = await response.json();
            setPutUrl(data.url)
            return data.url;
        }else{
        setError({
            field: "global",
            message: "Veuillez renseigner une image"
        })
        return
        }
    };
  
    const handleFileUpload = async () => {
        const fileUrl = file
        const response = await fetch(putUrl, {
          method: 'PUT',
          body: fileUrl,
        });
        if (response.ok) {
          const photoUrl = putUrl.split('?')[0];
          setInfoImage({
            url: photoUrl,
            size,
            type: "image"
          })
          
        } else {
          setError({
            field: "global",
            message: "Une erreur est survenue lors de l'enregistrement de l'images"
          })
          return
        }
      };
    
    const handleFileChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
        const file = e.target.files
        if(file && file.length > 0){
            let type: number = file[0].size
            let max: number = 1024 * 1024 * 8
            if(type < max){
                setSize(type)
                setFile(file[0])
            }else{
                setError({
                    field: "global",
                    message: "Votre fichier ne doit pas dépasser 8Mo"
                })
                return
            }
        }
    }
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if(!data.name){
            setError({
                field: "name",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.link){
            setError({
                field: "link",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        setError(null)
        if(file){
            fetchPresignedUrl()
        }else{
            dispatch(updatePartner(data))
        }
        
    }
  
    useEffect(() => {
        if(putUrl){
          handleFileUpload()
        }
    }, [putUrl]);
    
    useEffect(() => {
        if(infoImage){
          let datas = {
            ...data,
            image: infoImage
          }
          dispatch(updatePartner(datas))
        }
    }, [infoImage, data]);

    return (
        <>
            {
                isEditSuccess ? (
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon is--success"}>
                                <i className="icon-validation-check"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Modification du partenaire</h2>
                                <p>Les informations ont été modifié avec succès</p>
                                <div className={"kubini-modal-form-status__action"}>
                                    <Button
                                        btnType={"button"}
                                        label={"Fermer..."}
                                        btnStyle={"is--neutral"}
                                        active={true}
                                        withIconLeft={false}
                                        onClick={() => {
                                            window.location.reload()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <form className={"kubini-modal-form__container"} onSubmit={handleSubmit}>
                        <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--10"}>
                            {
                                isEditError ? (
                                    <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
                                ) : (
                                    error?.field === "file" ? (
                                        <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={error?.message}/>
                                    ) : null
                                )
                            }
                            <div className={"kubini-modal-form__fields"}>
                                <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--8"}>
                                    <div className={"kubini-modal-form__field"}>
                                        <Textfield
                                            id={"name"}
                                            value={data.name}
                                            require={true}
                                            changeValue={(value: any) => setData({...data, name: value})}
                                            type={"text"}
                                            label={"Nom"}
                                            placeholder={"ex: vêtements homme"}
                                            error={error}
                                        />
                                    </div>
                                    <div className={"kubini-modal-form__field"}>
                                        <Textfield
                                            id={"link"}
                                            value={data.link}
                                            require={true}
                                            changeValue={(value: any) => setData({...data, link: value})}
                                            type={"text"}
                                            label={"Lien vers"}
                                            placeholder={"ex: https://www.facebook.com/...."}
                                            error={error}
                                        />
                                    </div>
                                    
                                    <div className={"kubini-modal-form__field"}>
                                        <div className={"kubini-filefield"}>
                                            <input type="file" accept='.jpg,.png,jpeg,.webp' id='pubImg' onChange={handleFileChangeFile}/>
                                            <label htmlFor="pubImg">
                                                <div className={`kubini-filefield__label force--open`}>
                                                    {
                                                        file ? (
                                                            <>
                                                                <i className={"icon-file-check"}/>
                                                                <p>
                                                                    <strong>{slugifyFileName(file?.name)}</strong> <br/>
                                                                    <span>Cliquer pour changer l'image du partenaire (4Mo max)</span>
                                                                </p>
                                                            </>
                                                        ) : (

                                                            <>
                                                                <i className={"icon-file-text"}/>
                                                                <p>
                                                                    {
                                                                        item?.cover ? (
                                                                            <strong>Possède une image</strong>
                                                                        ) : null
                                                                    }
                                                                    <span>Cliquer pour changer l'image du partenaire (4Mo max)</span>
                                                                </p>
                                                            </>
                                                        )
                                                    }

                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </GridLayout>
                            </div>
                            <div className={"kubini-modal-form__action"}>
                                <Button btnType={"submit"} label={isEditLoading ? "Création en cours..." : "Ajouter le partenaire"} btnStyle={"is--primary"} active={!isEditLoading} withIconLeft={true} iconLeftClass={isEditLoading ? "icon-spin" :"icon-storage-floppy-disk"}/>
                            </div>
                        </GridLayout>
                    </form>
                )
            }
        </>
    )
}

const DeleteModal = ({item}: { item: any }) => {
    const dispatch = useAppDispatch();
    const {isDeleteLoading, isDeleteError, isDeleteSuccess, message} = useAppSelector((state: any) => state.partner);
    return (
        <>
            {
                isDeleteSuccess ? (
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon is--success"}>
                                <i className="icon-validation-check"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Suppression d'un partenaire</h2>
                                <p>L'élément a bien été supprimé avec succès</p>
                                <div className={"kubini-modal-form-status__action"}>
                                    <Button
                                        btnType={"button"}
                                        label={ "Fermer..."}
                                        btnStyle={"is--neutral"}
                                        active={true}
                                        withIconLeft={false}
                                        onClick={() => {
                                            window.location.reload()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon is--danger"}>
                                <i className="icon-delete-bin"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Suppression d'un partenaire</h2>
                                {
                                    isDeleteError ? (
                                        <p>
                                            <strong>Ouppss!!!</strong> <br/>
                                            <span>{message}</span>
                                        </p>
                                    ) : (
                                        <p>Souhaitez-vous vraiment supprimer cet élément</p>
                                    )
                                }

                                <div className={"kubini-modal-form-status__action"}>
                                    {
                                        isDeleteError ? (
                                            <Button
                                                btnType={"button"}
                                                label={isDeleteLoading ? "Suppression en cours" : "Réessayer..."}
                                                btnStyle={"is--danger"}
                                                active={!isDeleteLoading}
                                                withIconLeft={true}
                                                iconLeftClass={isDeleteLoading ? "icon-spin" : "icon-delete-bin"}
                                                onClick={() => {
                                                    dispatch(deletePartner({id: item?._id}))
                                                }}
                                            />
                                        ) : (
                                            <Button
                                                btnType={"button"}
                                                label={isDeleteLoading ? "Suppression en cours" : "Confirmer la suppression"}
                                                btnStyle={"is--danger"}
                                                active={!isDeleteLoading}
                                                withIconLeft={true}
                                                iconLeftClass={isDeleteLoading ? "icon-spin" : "icon-delete-bin"}
                                                onClick={() => {
                                                    dispatch(deletePartner({id: item?._id}))
                                                }}
                                            />
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export const PartnerPage = () => {
    const [search, setSearch] = useState<string>("")
    const [numberItem, setNumberItem] = useState<number>(15)
    const [startList, setStartList] = useState<number>(1)
    const [addCategoryModal, setAddCategoryModal] = useState<boolean>(false)
    const [editCategoryModal, setEditCategoryModal] = useState<boolean>(false)
    const [deleteCategoryModal, setDeleteCategoryModal] = useState<boolean>(false)
    const [itemCategoryModal, setItemCategoryModal] = useState<any>(null)
    const [chooseApercu, setChosseApercu] = useState<string>("grid")
    const dispatch = useAppDispatch();
    const { isLoading, isSuccess, isError, partners, message } = useAppSelector((state: any) => state.partner);
    let navigate = useNavigate()
    useEffect(() => {
        
        dispatch(getPartners({}))

        return () => {
            dispatch(reset())
        }
    }, [dispatch])

    return (
    <MainLayout title='Partenaires' here='platform' here_menu='partner'>
      <div className={"kubini-page2"}>
        <BackButton/>
        <div className={"kubini-page2__container"}>
          <div className={"kubini-page2__side"}>
            <PlatformMenu here={"partner"}/>
          </div>
          <div className={"kubini-page2__content"}>
            {
              isLoading ? (
                <div className={"kubini-loader"}>
                    <i className="icon-spin"/>
                </div>
              ) : (
                isError ? (
                  <div className={"kubini-page-error"}>
                      <div className={"kubini-page-error__container"}>
                          <i className="icon-delete-square"/>
                          <div className={"kubini-page2-error__text"}>
                              <h2>Oupss!!</h2>
                              <p>{message}</p>
                          </div>
                      </div>
                  </div>
                ) : (
                  isSuccess && partners ? (
                    <>
                        <div className={"kubini-page__header"}>
                            <h2>Les partenaires</h2>
                        </div>
                        <div className={"kubini-page2__searchActions kubini-page2-searchActions"}>
                            <GridLayout direction={"fd--row"} gap={"g--6"} justify={"jc--start"} align={"al--start"} wrap={true}>
                                <div className={"kubini-page2-actions"}>
                                    <GridLayout direction={"fd--row"} gap={"g--6"} justify={"jc--start"} align={"al--start"} wrap={true}>
                                        <Button
                                            btnType={"button"}
                                            label={"Ajouter un partenaire"}
                                            btnStyle={"is--primary"}
                                            active={true}
                                            withIconLeft={true}
                                            iconLeftClass={"icon-add-square"}
                                            onClick={() => setAddCategoryModal(true)}
                                        />
                                    </GridLayout>
                                </div>
                                <div className={"kubini-page2-search"}>
                                    <i className="icon-search"/>
                                    <input
                                        type="search"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        placeholder={"search..."}
                                    />
                                </div>
                                <div className={"kubini-page2-searchItem"}>
                                    <span>Nombre à afficher</span>
                                    <input
                                        type="number"
                                        value={numberItem}
                                        min={5}
                                        onChange={(e) => setNumberItem(parseInt(e.target.value))}
                                    />
                                </div>
                                <div className={"kubini-page2-searchItem"}>
                                    <span>Commencer par</span>
                                    <input
                                        type="number"
                                        min={0}
                                        value={startList}
                                        onChange={(e) => setStartList(parseInt(e.target.value))}
                                    />
                                </div>
                            </GridLayout>
                        </div>
                        <div className={'kubini-page2__list kubini-page2-list'}>
                            <div className={'kubini-page2-list-head'}>
                                <div className={'kubini-page2-list-head__text'}>
                                    <span>{partners.filter((el: any) => el.name.toLowerCase().includes(search.toLowerCase())).length} élément(s) trouvé(s)</span>
                                </div>
                                <div className={'kubini-page2-list-head-actions'}>
                                    <button
                                        type={'button'}
                                        className={`kubini-page2-list-head-action ${chooseApercu === 'grid' ? 'is--active' : ''}`}
                                        onClick={() => setChosseApercu('grid')}
                                    >
                                        <i className={'icon-dashboard'} />
                                    </button>
                                    <button
                                        type={'button'}
                                        className={`kubini-page2-list-head-action ${chooseApercu === 'list' ? 'is--active' : ''}`}
                                        onClick={() => setChosseApercu('list')}
                                    >
                                        <i className={'icon-list-bullets'} />
                                    </button>
                                </div>
                            </div>
                            {
                                partners
                                    .filter((el: any) => el.name.toLowerCase().includes(search.toLowerCase()))
                                    .length > 0 ? (
                                        <div className={'kubini-page2-list-container'}>
                                            {
                                                chooseApercu === 'grid' ? (
                                                    <div className={'kubini-page-list-grid'}>
                                                        <GridLayout
                                                            direction={'fd--row'}
                                                            wrap={true}
                                                            justify={'jc--start'}
                                                            align={'al--start'}
                                                            gap={'g--4'}
                                                        >
                                                            {
                                                                partners
                                                                    .filter((el: any) => el.name.toLowerCase().includes(search.toLowerCase()))
                                                                    .slice((startList - 1), (numberItem + (startList - 1)))
                                                                    .map((item: any, index: any) => (
                                                                        <div className={'kubini-page-list-grid-item'} key={index}>
                                                                            <div className={'kubini-category-card'}>
                                                                                <div className={'kubini-category-card__container'}>
                                                                                    <div className={'kubini-category-card__image'}>
                                                                                        {
                                                                                            item?.cover ? (
                                                                                                <img
                                                                                                    src={item?.cover?.url}
                                                                                                    alt={item?.name}/>
                                                                                            ) : (
                                                                                                <i className="icon-content-book-phone"/>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                    <div className={'kubini-category-card__text'}>
                                                                                        <h2>{item.name}</h2>
                                                                                        <a href={item?.link} target='_blank' rel='noreferrer' className='kubini-link'>{item?.link}</a>
                                                                                        <p>
                                                                                            Ajouter le {item.createdAt ? capitalize(format(new Date(item.createdAt), "EEEE d MMMM yyyy à HH:mm:ss", {locale: fr})) : "ND"}
                                                                                        </p>
                                                                                        <div className={'kubini-category-card__actions'}>
                                                                                            <button
                                                                                                type={'button'}
                                                                                                className={'kubini-category-card__action'}
                                                                                                onClick={() => {
                                                                                                    setItemCategoryModal(null)
                                                                                                    setItemCategoryModal(item)
                                                                                                    setEditCategoryModal(true)
                                                                                                   
                                                                                                }}
                                                                                            >
                                                                                                <i className="icon-edit-write-2" /> <span>Modifier</span>
                                                                                            </button>
                                                                                            <button
                                                                                                type={'button'}
                                                                                                className={'kubini-category-card__action is--danger'}
                                                                                                onClick={() => {
                                                                                                    setItemCategoryModal(null)
                                                                                                    setItemCategoryModal(item)
                                                                                                    setDeleteCategoryModal(true)
                                                                                                }}
                                                                                            >
                                                                                                <i className="icon-delete-bin" /> <span>Supprimer</span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    ))
                                                            }
                                                        </GridLayout>
                                                    </div>
                                                ) : (
                                                    <div className={'kubini-page2-list-list'}>
                                                        <div className={'kubini-page2-list-list__container'}>
                                                        <div className={'kubini-page2-list-list-header'}>
                                                            <GridLayout
                                                            direction={'fd--row'}
                                                            justify={'jc--start'}
                                                            align={'al--start'}
                                                            gap={'g--0'}
                                                            >
                                                            <div className={'kubini-page2-list-list-header-id'}>
                                                                <strong>#</strong>
                                                            </div>
                                                            <div className={'kubini-page2-list-list-header-large'}>
                                                                <strong>Nom</strong>
                                                            </div>
                                                            <div className={'kubini-page2-list-list-header-large'}>
                                                                <strong>Lien vers</strong>
                                                            </div>
                                                            <div className={'kubini-page2-list-list-header-mid'}>
                                                                <strong>Ajouté le</strong>
                                                            </div>
                                                            <div className={'kubini-page2-list-list-header-actions'}>
                                                                <strong>Actions</strong>
                                                            </div>
                                                            </GridLayout>
                                                        </div>
                                                        <div className={'kubini-page2-list-list-items'}>
                                                            <GridLayout
                                                                direction={'fd--column'}
                                                                justify={'jc--start'}
                                                                align={'al--start'}
                                                                gap={'g--0'}
                                                                wrap={true}
                                                            >
                                                            {
                                                                partners
                                                                    .filter((el: any) => el.name.toLowerCase().includes(search.toLowerCase()))
                                                                    .slice((startList - 1), (numberItem + (startList - 1)))
                                                                    .map((item: any, index: any) => (
                                                                        <div className={'kubini-page2-list-list-item'} key={index}>
                                                                            <GridLayout
                                                                                direction={'fd--row'}
                                                                                justify={'jc--start'}
                                                                                align={'al--start'}
                                                                                gap={'g--0'}
                                                                            >
                                                                            <div className={'kubini-page2-list-list-item-id'}>
                                                                                <strong>{index + 1}</strong>
                                                                            </div>
                                                                            <div className={'kubini-page2-list-list-item-large'}>
                                                                                <div className={"kubini-page2-list-item-user"}>
                                                                                    <div className={"kubini-page2-list-item-user__avatar"}>
                                                                                        {
                                                                                            item?.cover ? (
                                                                                                <img
                                                                                                    src={item?.cover?.url}
                                                                                                    alt={item?.name}/>
                                                                                            ) : (
                                                                                                <i className="icon-content-book-phone"/>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                    <div className={"kubini-page2-list-item-user__info"}>
                                                                                        <strong>{item.name}</strong>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className={'kubini-page2-list-list-item-large'}>
                                                                                <a href={item?.link} target='_blank' rel='noreferrer' className='kubini-link'>{item?.link}</a>
                                                                            </div>
                                                                            <div className={'kubini-page2-list-list-item-mid'}>
                                                                                <strong>{item.createdAt ? capitalize(format(new Date(item.createdAt), "EEEE d MMMM yyyy à HH:mm:ss", {locale: fr})) : "ND"}</strong>
                                                                            </div>
                                                                            <div className={'kubini-page2-list-list-item-actions'}>
                                                                                <button
                                                                                    type={'button'}
                                                                                    className={'kubini-page2-list-list-item-action'}
                                                                                    onClick={() => {
                                                                                        setItemCategoryModal(null)
                                                                                        setItemCategoryModal(item)
                                                                                        setEditCategoryModal(true)
                                                                                    }}
                                                                                >
                                                                                    <i className="icon-edit-write-2" /> <span>Modifier</span>
                                                                                </button>
                                                                                <button
                                                                                    type={'button'}
                                                                                    className={'kubini-page2-list-list-item-action is--danger'}
                                                                                    onClick={() => {
                                                                                        setItemCategoryModal(null)
                                                                                        setItemCategoryModal(item)
                                                                                        setDeleteCategoryModal(true)
                                                                                    }}
                                                                                >
                                                                                    <i className="icon-delete-bin" /> <span>Supprimer</span>
                                                                                </button>
                                                                            </div>
                                                                            </GridLayout>
                                                                        </div>

                                                                    ))
                                                            }
                                                            </GridLayout>
                                                        </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    ) : (
                                        <strong>Aucun élément trouvé...</strong>
                                    )
                            }
                        </div>

                    </>
                  ) : null
                )
              )
            }
          </div>
        </div>
        
        <ModalLayout
            size={'is--md'}
            id={'add-modal'}
            isOpen={addCategoryModal}
            fnc={(value: boolean) => setAddCategoryModal(value)}
            withClose={true}
        >
            <div className={'kubini-modal-form'}>
                <div className={'kubini-modal-form__header'}>
                    <h2>Ajout d'un partenaire</h2>
                </div>
                <AddModal />
            </div>
        </ModalLayout>
        {
            itemCategoryModal ? (
                <ModalLayout
                    size={'is--md'}
                    isOpen={editCategoryModal}
                    id={'edit-modal'}
                    fnc={(value: boolean) => setEditCategoryModal(value)}
                    withClose={true}
                >
                    <div className={'kubini-modal-form'}>
                    <div className={'kubini-modal-form__header'}>
                        <h2>Modification d'un partenaire</h2>
                    </div>
                        <EditModal item={itemCategoryModal} />
                    </div>
                </ModalLayout>
            ) : null
        }
        {
            itemCategoryModal ? (
                <ModalLayout
                    size={'is--md'}
                    isOpen={deleteCategoryModal}
                    id={'delete-modal'}
                    fnc={(value: boolean) => setDeleteCategoryModal(value)}
                    withClose={true}
                >
                    <div className={'kubini-modal-form'}>
                        <DeleteModal item={itemCategoryModal} />
                    </div>
                </ModalLayout>
            ) : null
        }
      </div>
    </MainLayout>
    )
}
