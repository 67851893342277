import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../redux/store'
import { useNavigate } from 'react-router-dom'
import { getFeedbacks, reset } from '../../../../../redux/feature/feedback/feedback.slice'
import { MainLayout } from '../../../../layout'
import { BackButton, GridLayout } from '../../../../components'
import PlatformMenu from '../../platform/module/PlatformMenu'
import { capitalize } from '../../../../../utils/function'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { links } from '../../../../routes'

export function FeedBacksPage() {
  const [search, setSearch] = useState<string>("")
  const [numberItem, setNumberItem] = useState<number>(15)
  const [startList, setStartList] = useState<number>(1)
  const [chooseApercu, setChosseApercu] = useState<string>("grid")
  const dispatch = useAppDispatch();
  const {isLoading, isError, isSuccess, feedbacks, message}: any = useAppSelector((state) => state.feedback);
  let navigate = useNavigate()
  useEffect(() => {
      // @ts-ignore
      dispatch(getFeedbacks())

      return () => {
          dispatch(reset())
      }
  }, [dispatch])
  return (
    <MainLayout title='Feedbacks' here='platform' here_menu='feedback'>
      <div className={"kubini-page2"}>
        <BackButton/>
        <div className={"kubini-page2__container"}>
          <div className={"kubini-page2__side"}>
            <PlatformMenu here={"feedback"}/>
          </div>
          <div className={"kubini-page2__content"}>
            {
              isLoading ? (
                <div className={"kubini-loader"}>
                    <i className="icon-spin"/>
                </div>
              ) : (
                isError ? (
                  <div className={"kubini-page-error"}>
                      <div className={"kubini-page-error__container"}>
                          <i className="icon-delete-square"/>
                          <div className={"kubini-page2-error__text"}>
                              <h2>Oupss!!</h2>
                              <p>{message}</p>
                          </div>
                      </div>
                  </div>
                ) : (
                  isSuccess && feedbacks ? (
                    <>
                      <div className={"kubini-page2__header"}>
                        <h2>Les retours clients</h2>
                      </div>

                      <div className={"kubini-page2__searchActions kubini-page2-searchActions"}>
                        <GridLayout direction={"fd--row"} gap={"g--6"} justify={"jc--start"} align={"al--start"} wrap={true}>
                          <div className={"kubini-page2-search"}>
                              <i className="icon-search"/>
                              <input
                                  type="search"
                                  value={search}
                                  onChange={(e) => setSearch(e.target.value)}
                                  placeholder={"search..."}
                              />
                          </div>
                          <div className={"kubini-page2-searchItem"}>
                              <span>Nombre à afficher</span>
                              <input
                                  type="number"
                                  value={numberItem}
                                  min={5}
                                  onChange={(e) => setNumberItem(parseInt(e.target.value))}
                              />
                          </div>
                          <div className={"kubini-page2-searchItem"}>
                              <span>Commencer par</span>
                              <input
                                  type="number"
                                  min={1}
                                  value={startList}
                                  onChange={(e) => setStartList(parseInt(e.target.value))}
                              />
                          </div>
                        </GridLayout>
                      </div>

                      <div className={'kubini-page2__list kubini-page2-list'}>
                        <div className={'kubini-page2-list-head'}>
                            <div className={'kubini-page2-list-head__text'}>
                                <span>{feedbacks.filter((el: any) => el.name.toLowerCase().includes(search.toLowerCase()) || el.auth?.fullname?.toLowerCase().includes(search.toLowerCase()) || el.auth?.username?.toLowerCase().includes(search.toLowerCase())).length} élément(s) trouvé(s)</span>
                            </div>
                            <div className={'kubini-page2-list-head-actions'}>
                                <button
                                    type={'button'}
                                    className={`kubini-page2-list-head-action ${chooseApercu === 'grid' ? 'is--active' : ''}`}
                                    onClick={() => setChosseApercu('grid')}
                                >
                                    <i className={'icon-dashboard'} />
                                </button>
                                <button
                                    type={'button'}
                                    className={`kubini-page2-list-head-action ${chooseApercu === 'list' ? 'is--active' : ''}`}
                                    onClick={() => setChosseApercu('list')}
                                >
                                    <i className={'icon-list-bullets'} />
                                </button>
                            </div>
                        </div>
                        {
                          feedbacks
                            .filter((el: any) => el.name.toLowerCase().includes(search.toLowerCase()) || el.auth?.fullname?.toLowerCase().includes(search.toLowerCase()) || el.auth?.username?.toLowerCase().includes(search.toLowerCase()))
                            .length > 0 ? (
                              
                              <div className={'kubini-page2-list-container'}>
                                {
                                  chooseApercu === 'grid' ? (
                                    <div className={'kubini-page2-list-grid'}>
                                      <GridLayout
                                        direction={'fd--row'}
                                        wrap={true}
                                        justify={'jc--start'}
                                        align={'al--start'}
                                        gap={'g--4'}
                                      >
                                        {
                                          feedbacks
                                          .filter((el: any) => el.name.toLowerCase().includes(search.toLowerCase()) || el.auth?.fullname?.toLowerCase().includes(search.toLowerCase()) || el.auth?.username?.toLowerCase().includes(search.toLowerCase()))
                                          .slice((startList - 1), (numberItem + (startList - 1)))
                                          .map((item: any, index: any) => (
                                            <div className={'kubini-page2-list-grid-item'} key={index}>
                                              <div className={'kubini-category-card'}>
                                                  <div className={'kubini-category-card__container'}>
                                                      <div className={'kubini-category-card__icon'}>
                                                          <i className="icon-building" />
                                                      </div>
                                                      <div className={'kubini-category-card__text'}>
                                                        <h2>{item.name}</h2>
                                                        <p>Par: {item.auth?.fullname}</p>
                                                        <p>
                                                            Ajouté le {item.createdAt ? capitalize(format(new Date(item.createdAt), "EEEE d MMMM yyyy à HH:mm:ss", {locale: fr})) : "ND"}
                                                        </p>
                                                        <div className={'kubini-category-card__actions'}>
                                                            <button
                                                              type={'button'}
                                                              className={'kubini-category-card__action'}
                                                              onClick={() => {
                                                                navigate(links.feedback + item.slug)
                                                              }}
                                                            >
                                                                <i className="icon-view" /> <span>Voir</span>
                                                            </button>
                                                            
                                                        </div>
                                                      </div>
                                                  </div>
                                              </div>
                                            </div>
                                          ))              
                                        }
                                      </GridLayout>
                                    </div>
                                  ) : (
                                    <></>
                                  )
                                }
                              </div>
                            ) : (
                              <strong>Aucun retour client enregistré</strong>
                            )
                        }
                      </div>
                    </>
                  ) : null
                )
              )
            }
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
