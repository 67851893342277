export const paysOptions = [
  {
    name: "Afghanistan",
    value: "Afghanistan",
  },
  {
    name: "Afrique du Sud",
    value: "Afrique du Sud",
  },
  {
    name: "Albanie",
    value: "Albanie",
  },
  {
    name: "Algérie",
    value: "Algérie",
  },
  {
    name: "Allemagne",
    value: "Allemagne",
  },
  {
    name: "Andorre",
    value: "Andorre",
  },
  {
    name: "Angola",
    value: "Angola",
  },
  {
    name: "Anguilla",
    value: "Anguilla",
  },
  {
    name: "Antarctique",
    value: "Antarctique",
  },
  {
    name: "Antigua-et-Barbuda",
    value: "Antigua-et-Barbuda",
  },
  {
    name: "Antilles néerlandaises",
    value: "Antilles néerlandaises",
  },
  {
    name: "Arabie saoudite",
    value: "Arabie saoudite",
  },
  {
    name: "Argentine",
    value: "Argentine",
  },
  {
    name: "Arménie",
    value: "Arménie",
  },
  {
    name: "Aruba",
    value: "Aruba",
  },
  {
    name: "Australie",
    value: "Australie",
  },
  {
    name: "Autriche",
    value: "Autriche",
  },
  {
    name: "Azerbaïdjan",
    value: "Azerbaïdjan",
  },
  {
    name: "Bahamas",
    value: "Bahamas",
  },
  {
    name: "Bahreïn",
    value: "Bahreïn",
  },
  {
    name: "Bangladesh",
    value: "Bangladesh",
  },
  {
    name: "Barbade",
    value: "Barbade",
  },
  {
    name: "Bélarus",
    value: "Bélarus",
  },
  {
    name: "Belgique",
    value: "Belgique",
  },
  {
    name: "Belize",
    value: "Belize",
  },
  {
    name: "Bénin",
    value: "Bénin",
  },
  {
    name: "Bermudes",
    value: "Bermudes",
  },
  {
    name: "Bhoutan",
    value: "Bhoutan",
  },
  {
    name: "Bolivie",
    value: "Bolivie",
  },
  {
    name: "Bosnie-Herzégovine",
    value: "Bosnie-Herzégovine",
  },
  {
    name: "Botswana",
    value: "Botswana",
  },
  {
    name: "Brésil",
    value: "Brésil",
  },
  {
    name: "Brunéi Darussalam",
    value: "Brunéi Darussalam",
  },
  {
    name: "Bulgarie",
    value: "Bulgarie",
  },
  {
    name: "Burkina Faso",
    value: "Burkina Faso",
  },
  {
    name: "Burundi",
    value: "Burundi",
  },
  {
    name: "Cambodge",
    value: "Cambodge",
  },
  {
    name: "Cameroun",
    value: "Cameroun",
  },
  {
    name: "Canada",
    value: "Canada",
  },
  {
    name: "Cap-Vert",
    value: "Cap-Vert",
  },
  {
    name: "Ceuta et Melilla",
    value: "Ceuta et Melilla",
  },
  {
    name: "Chili",
    value: "Chili",
  },
  {
    name: "Chine",
    value: "Chine",
  },
  {
    name: "Chypre",
    value: "Chypre",
  },
  {
    name: "Colombie",
    value: "Colombie",
  },
  {
    name: "Comores",
    value: "Comores",
  },
  {
    name: "Congo-Brazzaville",
    value: "Congo-Brazzaville",
  },
  {
    name: "Corée du Nord",
    value: "Corée du Nord",
  },
  {
    name: "Corée du Sud",
    value: "Corée du Sud",
  },
  {
    name: "Costa Rica",
    value: "Costa Rica",
  },
  {
    name: "Côte d’Ivoire",
    value: "Côte d’Ivoire",
  },
  {
    name: "Croatie",
    value: "Croatie",
  },
  {
    name: "Cuba",
    value: "Cuba",
  },
  {
    name: "Danemark",
    value: "Danemark",
  },
  {
    name: "Diego Garcia",
    value: "Diego Garcia",
  },
  {
    name: "Djibouti",
    value: "Djibouti",
  },
  {
    name: "Dominique",
    value: "Dominique",
  },
  {
    name: "Égypte",
    value: "Égypte",
  },
  {
    name: "El Salvador",
    value: "El Salvador",
  },
  {
    name: "Émirats arabes unis",
    value: "Émirats arabes unis",
  },
  {
    name: "Équateur",
    value: "Équateur",
  },
  {
    name: "Érythrée",
    value: "Érythrée",
  },
  {
    name: "Espagne",
    value: "Espagne",
  },
  {
    name: "État de la Cité du Vatican",
    value: "État de la Cité du Vatican",
  },
  {
    name: "États fédérés de Micronésie",
    value: "États fédérés de Micronésie",
  },
  {
    name: "États-Unis",
    value: "États-Unis",
  },
  {
    name: "Éthiopie",
    value: "Éthiopie",
  },
  {
    name: "Fidji",
    value: "Fidji",
  },
  {
    name: "Finlande",
    value: "Finlande",
  },
  {
    name: "France",
    value: "France",
  },
  {
    name: "Gabon",
    value: "Gabon",
  },
  {
    name: "Gambie",
    value: "Gambie",
  },
  {
    name: "Géorgie",
    value: "Géorgie",
  },
  {
    name: "Géorgie du Sud et les îles Sandwich du Sud",
    value: "Géorgie du Sud et les îles Sandwich du Sud",
  },
  {
    name: "Ghana",
    value: "Ghana",
  },
  {
    name: "Gibraltar",
    value: "Gibraltar",
  },
  {
    name: "Grèce",
    value: "Grèce",
  },
  {
    name: "Grenade",
    value: "Grenade",
  },
  {
    name: "Groenland",
    value: "Groenland",
  },
  {
    name: "Guam",
    value: "Guam",
  },
  {
    name: "Guatemala",
    value: "Guatemala",
  },
  {
    name: "Guinée",
    value: "Guinée",
  },
  {
    name: "Guinée équatoriale",
    value: "Guinée équatoriale",
  },
  {
    name: "Guinée-Bissau",
    value: "Guinée-Bissau",
  },
  {
    name: "Guyana",
    value: "Guyana",
  },
  {
    name: "Guyane française",
    value: "Guyane française",
  },
  {
    name: "Haïti",
    value: "Haïti",
  },
  {
    name: "Honduras",
    value: "Honduras",
  },
  {
    name: "Hongrie",
    value: "Hongrie",
  },
  {
    name: "Île Bouvet",
    value: "Île Bouvet",
  },
  {
    name: "Île Christmas",
    value: "Île Christmas",
  },
  {
    name: "Île Clipperton",
    value: "Île Clipperton",
  },
  {
    name: "Île de l'Ascension",
    value: "Île de l'Ascension",
  },
  {
    name: "Île de Man",
    value: "Île de Man",
  },
  {
    name: "Île de Norfolk",
    value: "Île de Norfolk",
  },
  {
    name: "Île Åland",
    value: "Île Åland",
  },
  {
    name: "Île Caïmans",
    value: "Île Caïmans",
  },
  {
    name: "Île Canaries",
    value: "Île Canaries",
  },
  {
    name: "Île Cocos - Keeling",
    value: "Île Cocos - Keeling",
  },
  {
    name: "Île Cook",
    value: "Île Cook",
  },
  {
    name: "Île Féroé",
    value: "Île Féroé",
  },
  {
    name: "Île Heard et MacDonald",
    value: "Île Heard et MacDonald",
  },
  {
    name: "Île Mariannes",
    value: "Île Mariannes",
  },
  {
    name: "Île Mariannes du Nord",
    value: "Île Mariannes du Nord",
  },
  {
    name: "Île Marshall",
    value: "Île Marshall",
  },
  {
    name: "Île Mineures Éloignées des États-Unis",
    value: "Île Mineures Éloignées des États-Unis",
  },
  {
    name: "Île Salomon",
    value: "Île Salomon",
  },
  {
    name: "Île Turks et Caïques",
    value: "Île Turks et Caïques",
  },
  {
    name: "Île Vierges britanniques",
    value: "Île Vierges britanniques",
  },
  {
    name: "Île Vierges des États-Unis",
    value: "Île Vierges des États-Unis",
  },
  {
    name: "Inde",
    value: "Inde",
  },
  {
    name: "Indonésie",
    value: "Indonésie",
  },
  {
    name: "Irak",
    value: "Irak",
  },
  {
    name: "Iran",
    value: "Iran",
  },
  {
    name: "Irlande",
    value: "Irlande",
  },
  {
    name: "Islande",
    value: "Islande",
  },
  {
    name: "Israël",
    value: "Israël",
  },
  {
    name: "Italie",
    value: "Italie",
  },
  {
    name: "Jamaïque",
    value: "Jamaïque",
  },
  {
    name: "Japon",
    value: "Japon",
  },
  {
    name: "Jersey",
    value: "Jersey",
  },
  {
    name: "Jordanie",
    value: "Jordanie",
  },
  {
    name: "Kazakhstan",
    value: "Kazakhstan",
  },
  {
    name: "Kenya",
    value: "Kenya",
  },
  {
    name: "Kirghizistan",
    value: "Kirghizistan",
  },
  {
    name: "Kiribati",
    value: "Kiribati",
  },
  {
    name: "Koweït",
    value: "Koweït",
  },
  {
    name: "Laos",
    value: "Laos",
  },
  {
    name: "Lesotho",
    value: "Lesotho",
  },
  {
    name: "Lettonie",
    value: "Lettonie",
  },
  {
    name: "Liban",
    value: "Liban",
  },
  {
    name: "Libéria",
    value: "Libéria",
  },
  {
    name: "Libye",
    value: "Libye",
  },
  {
    name: "Liechtenstein",
    value: "Liechtenstein",
  },
  {
    name: "Lituanie",
    value: "Lituanie",
  },
  {
    name: "Luxembourg",
    value: "Luxembourg",
  },
  {
    name: "Macédoine",
    value: "Macédoine",
  },
  {
    name: "Madagascar",
    value: "Madagascar",
  },
  {
    name: "Malaisie",
    value: "Malaisie",
  },
  {
    name: "Malawi",
    value: "Malawi",
  },
  {
    name: "Maldives",
    value: "Maldives",
  },
  {
    name: "Mali",
    value: "Mali",
  },
  {
    name: "Malte",
    value: "Malte",
  },
  {
    name: "Maroc",
    value: "Maroc",
  },
  {
    name: "Martinique",
    value: "Martinique",
  },
  {
    name: "Maurice",
    value: "Maurice",
  },
  {
    name: "Mauritanie",
    value: "Mauritanie",
  },
  {
    name: "Mayotte",
    value: "Mayotte",
  },
  {
    name: "Mexique",
    value: "Mexique",
  },
  {
    name: "Moldavie",
    value: "Moldavie",
  },
  {
    name: "Monaco",
    value: "Monaco",
  },
  {
    name: "Mongolie",
    value: "Mongolie",
  },
  {
    name: "Monténégro",
    value: "Monténégro",
  },
  {
    name: "Montserrat",
    value: "Montserrat",
  },
  {
    name: "Mozambique",
    value: "Mozambique",
  },
  {
    name: "Myanmar",
    value: "Myanmar",
  },
  {
    name: "Namibie",
    value: "Namibie",
  },
  {
    name: "Nauru",
    value: "Nauru",
  },
  {
    name: "Népal",
    value: "Népal",
  },
  {
    name: "Nicaragua",
    value: "Nicaragua",
  },
  {
    name: "Niger",
    value: "Niger",
  },
  {
    name: "Nigéria",
    value: "Nigéria",
  },
  {
    name: "Niue",
    value: "Niue",
  },
  {
    name: "Norvège",
    value: "Norvège",
  },
  {
    name: "Nouvelle-Calédonie",
    value: "Nouvelle-Calédonie",
  },
  {
    name: "Nouvelle-Zélande",
    value: "Nouvelle-Zélande",
  },
  {
    name: "Oman",
    value: "Oman",
  },
  {
    name: "Ouganda",
    value: "Ouganda",
  },
  {
    name: "Ouzbékistan",
    value: "Ouzbékistan",
  },
  {
    name: "Pakistan",
    value: "Pakistan",
  },
  {
    name: "Palaos",
    value: "Palaos",
  },
  {
    name: "Panama",
    value: "Panama",
  },
  {
    name: "Papouasie-Nouvelle-Guinée",
    value: "Papouasie-Nouvelle-Guinée",
  },
  {
    name: "Paraguay",
    value: "Paraguay",
  },
  {
    name: "Pérou",
    value: "Pérou",
  },
  {
    name: "Philippines",
    value: "Philippines",
  },
  {
    name: "Pitcairn",
    value: "Pitcairn",
  },
  {
    name: "Pologne",
    value: "Pologne",
  },
  {
    name: "Polynésie française",
    value: "Polynésie française",
  },
  {
    name: "Porto Rico",
    value: "Porto Rico",
  },
  {
    name: "Portugal",
    value: "Portugal",
  },
  {
    name: "Qatar",
    value: "Qatar",
  },
  {
    name: "R.A.S. chinoise de Hong Kong",
    value: "R.A.S. chinoise de Hong Kong",
  },
  {
    name: "R.A.S. chinoise de Macao",
    value: "R.A.S. chinoise de Macao",
  },
  {
    name: "Régions éloignées de l’Océanie",
    value: "Régions éloignées de l’Océanie",
  },
  {
    name: "République centrafricaine",
    value: "République centrafricaine",
  },
  {
    name: "République démocratique du Congo",
    value: "République démocratique du Congo",
  },
  {
    name: "République dominicaine",
    value: "République dominicaine",
  },
  {
    name: "République tchèque",
    value: "République tchèque",
  },
  {
    name: "Réunion",
    value: "Réunion",
  },
  {
    name: "Roumanie",
    value: "Roumanie",
  },
  {
    name: "Royaume-Uni",
    value: "Royaume-Uni",
  },
  {
    name: "Russie",
    value: "Russie",
  },
  {   name: "Rwanda",
    value: "Rwanda",
  },
  {
    name: "Sahara occidental",
    value: "Sahara occidental",
  },
  {
    name: "Saint-Barthélémy",
    value: "Saint-Barthélémy",
  },
  {
    name: "Saint-Kitts-et-Nevis",
    value: "Saint-Kitts-et-Nevis",
  },
  {
    name: "Saint-Marin",
    value: "Saint-Marin",
  },
  {
    name: "Saint-Martin",
    value: "Saint-Martin",
  },
  {
    name: "Saint-Pierre-et-Miquelon",
    value: "Saint-Pierre-et-Miquelon",
  },
  {
    name: "Saint-Vincent-et-les Grenadines",
    value: "Saint-Vincent-et-les Grenadines",
  },
  {
    name: "Sainte-Hélène",
    value: "Sainte-Hélène",
  },
  {
    name: "Sainte-Lucie",
    value: "Sainte-Lucie",
  },
  {
    name: "Samoa",
    value: "Samoa",
  },
  {
    name: "Samoa américaines",
    value: "Samoa américaines",
  },
  {
    name: "Sao Tomé-et-Principe",
    value: "Sao Tomé-et-Principe",
  },
  {
    name: "Sénégal",
    value: "Sénégal",
  },
  {
    name: "Serbie",
    value: "Serbie",
  },
  {
    name: "Serbie-et-Monténégro",
    value: "Serbie-et-Monténégro",
  },
  {
    name: "Seychelles",
    value: "Seychelles",
  },
  {
    name: "Sierra Leone",
    value: "Sierra Leone",
  },
  {
    name: "Singapour",
    value: "Singapour",
  },
  {
    name: "Slovaquie",
    value: "Slovaquie",
  },
  {
    name: "Slovénie",
    value: "Slovénie",
  },
  {
    name: "Somalie",
    value: "Somalie",
  },
  {
    name: "Soudan",
    value: "Soudan",
  },
  {
    name: "Sri Lanka",
    value: "Sri Lanka",
  },
  {
    name: "Suède",
    value: "Suède",
  },
  {
    name: "Suisse",
    value: "Suisse",
  },
  {
    name: "Suriname",
    value: "Suriname",
  },
  {
    name: "Svalbard et Île Jan Mayen",
    value: "Svalbard et Île Jan Mayen",
  },
  {
    name: "Swaziland",
    value: "Swaziland",
  },
  {
    name: "Syrie",
    value: "Syrie",
  },
  {
    name: "Tadjikistan",
    value: "Tadjikistan",
  },
  {
    name: "Taïwan",
    value: "Taïwan",
  },
  {
    name: "Tanzanie",
    value: "Tanzanie",
  },
  {
    name: "Tchad",
    value: "Tchad",
  },
  {
    name: "Terres australes françaises",
    value: "Terres australes françaises",
  },
  {
    name: "Territoire britannique de l'océan Indien",
    value: "Territoire britannique de l'océan Indien",
  },
  {
    name: "Territoire palestinien",
    value: "Territoire palestinien",
  },
  {
    name: "Thaïlande",
    value: "Thaïlande",
  },
  {
    name: "Timor oriental",
    value: "Timor oriental",
  },
  {
    name: "Togo",
    value: "Togo",
  },
  {
    name: "Tokelau",
    value: "Tokelau",
  },
  {
    name: "Tonga",
    value: "Tonga",
  },
  {
    name: "Trinité-et-Tobago",
    value: "Trinité-et-Tobago",
  },
  {
    name: "Tristan da Cunha",
    value: "Tristan da Cunha",
  },
  {
    name: "Tunisie",
    value: "Tunisie",
  },
  {
    name: "Turkménistan",
    value: "Turkménistan",
  },
  {
    name: "Turquie",
    value: "Turquie",
  },
  {
    name: "Tuvalu",
    value: "Tuvalu",
  },
  {
    name: "Ukraine",
    value: "Ukraine",
  },
  {
    name: "Uruguay",
    value: "Uruguay",
  },
  {
    name: "Vanuatu",
    value: "Vanuatu",
  },
  {
    name: "Venezuela",
    value: "Venezuela",
  },
  {
    name: "Viêt Nam",
    value: "Viêt Nam",
  },
  {
    name: "Wallis-et-Futuna",
    value: "Wallis-et-Futuna",
  },
  {
    name: "Yémen",
    value: "Yémen",
  },
  {
    name: "Zambie",
    value: "Zambie",
  },
  {
    name: "Zimbabwe",
    value: "Zimbabwe",
  },
]

export const companyOptions = [
  {
    name: "Individual",
    value: "individual",
  },
  {
    name: "SA",
    value: "SA",
  },
  {
    name: "SAS",
    value: "SAS",
  },
  {
    name: "SARL",
    value: "SARL",
  },
  {
    name: "Other",
    value: "other",
  },
]

export const modelsOptions = [
  {
    name: "Project",
    value: "project",
  },
  {
    name: "Category project",
    value: "category_project",
  },
  {
    name: "Member",
    value: "member",
  },
  {
    name: "Role",
    value: "role",
  },
  {
    name: "Entreprise Setting",
    value: "entreprise_setting",
  },
  {
    name: "Agenda",
    value: "agenda",
  },
  {
    name: "Project Tasks",
    value: "project_task",
  },
  {
    name: "Department",
    value: "department",
  },
  {
    name: "Finance Entries",
    value: "finance_entries",
  },
  {
    name: "Finance Expenses",
    value: "finance_expenses",
  },
  {
    name: "Service",
    value: "service",
  },
  {
    name: "Client & Society",
    value: "client_society",
  },
]

export const tableOptions = [
  {
    name: "View",
    value: "view",
  },
  {
    name: "Add",
    value: "add",
  },
  {
    name: "Edit",
    value: "edit",
  },
  {
    name: "Delete",
    value: "delete",
  },
]

export const typePub = [
  {
    value: "home-banner",
    name: "Bannière de la page d'accueil"
  },
  {
    value: "home-top",
    name: "Haut de la page d'accueil"
  },
  {
    value: "search-top",
    name: "Haut de la page de recherche"
  },
  {
    value: "add-top",
    name: "Pages Ajout"
  },
  {
    value: "show-top.annonce",
    name: "Haut des page Apercu Annonce"
  },
  {
    value: "show-top-event",
    name: "Haut des page Apercu Annonce"
  },
  {
      value: "home-foot",
      name: "Bas de la page d'accueil"
  },
  {
      value: "home-popular",
      name: "Article Epingle de la page d'accueil"
  },
  {
      value: "home-popular-e",
      name: "Evenement Epingle de la page d'accueil"
  },
]