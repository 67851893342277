import React, { FormEvent, useContext, useEffect, useState } from 'react';
import { MainLayout } from '../../../../layout';
import { AlertModule, BackButton, Button, GridLayout, ModalLayout, Textfield } from '../../../../components';
import { deleteAdmin, getAdmins, reset, setAdmin } from '../../../../../redux/feature/admin/admin.slice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { links } from '../../../../routes';
import { UserAuthContext } from '../../../../context/AuthContext';

const AddModal = () => {
    const [data, setData] = useState<any>({
        fullname: "", 
        username: "", 
        telephone: "", 
        email: ""
    })
    const dispatch = useAppDispatch();
    const { isCreateLoading, isCreateSuccess, isCreateError, admin, message } = useAppSelector((state) => state.admin);

    const [error, setError] = useState<any>({
        field: "",
        message: ""
    })
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if(!data.username){
        setError({
            field: "username",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!data.fullname){
        setError({
            field: "fullname",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!data.telephone){
        setError({
            field: "telephone",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!data.email){
        setError({
            field: "email",
            message: "Veuillez renseigner ce champs"
        })
        return
    }

    setError(null)

    dispatch(setAdmin(data))
    }
  
    return (
        <>
            {
                isCreateSuccess ? (
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon is--success"}>
                                <i className="icon-validation-check"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Création du compte administrateur</h2>
                                <p>Le compte de l'administrateur a été créé. Ces identifiants sont:</p>
                                <ul>
                                    <li><span>Identifiant: </span> <strong>{admin?.auth?.username} ou {admin?.auth?.email}</strong></li>
                                    <li><span>Mot de passe par défaut: </span> <strong>123456789</strong></li>
                                </ul>
                                <div className={"kubini-modal-form-status__action"}>
                                    <Button
                                        btnType={"button"}
                                        label={"Fermer..."}
                                        btnStyle={"is--neutral"}
                                        active={true}
                                        withIconLeft={false}
                                        onClick={() => {
                                            window.location.reload()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <form className={"kubini-modal-form__container"} onSubmit={handleSubmit}>
                        <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--10"}>
                            {
                                isCreateError ? (
                                    <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
                                ) : null
                            }
                            <div className={"kubini-modal-form__fields"}>
                                <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--8"}>
                                    <div className={"kubini-modal-form__field"}>
                                        <Textfield
                                            id={"fullname"}
                                            value={data.fullname}
                                            require={true}
                                            changeValue={(value: any) => setData({...data, fullname: value})}
                                            type={"text"}
                                            label={"Nom et prénom(s)"}
                                            placeholder={"John DOE"}
                                            error={error}
                                        />
                                    </div>
                                    <div className={"kubini-modal-form__field"}>
                                        <Textfield
                                            id={"username"}
                                            value={data.username}
                                            require={true}
                                            changeValue={(value: any) => setData({...data, username: value})}
                                            type={"username"}
                                            label={"Nom d'utlisateur"}
                                            placeholder={"jong_doe"}
                                            error={error}
                                        />
                                    </div>
                                    <div className={"kubini-modal-form__field"}>
                                        <Textfield
                                            id={"email"}
                                            value={data.email}
                                            require={true}
                                            changeValue={(value: any) => setData({...data, email: value})}
                                            type={"email"}
                                            label={"Email"}
                                            placeholder={"jonh@email.com"}
                                            error={error}
                                        />
                                    </div>
                                    <div className={"kubini-modal-form__field"}>
                                        <Textfield
                                            id={"telephone"}
                                            value={data.telephone}
                                            require={true}
                                            changeValue={(value: any) => setData({...data, telephone: value})}
                                            type={"phone"}
                                            label={"Téléphone"}
                                            placeholder={"00226875678"}
                                            error={error}
                                        />
                                    </div>
                                </GridLayout>
                            </div>
                            <div className={"kubini-modal-form__action"}>
                                <Button btnType={"submit"} label={isCreateLoading ? "Création en cours..." : "Créer le compte"} btnStyle={"is--primary"} active={!isCreateLoading} withIconLeft={true} iconLeftClass={isCreateLoading ? "icon-spin" :"icon-storage-floppy-disk"}/>
                            </div>
                        </GridLayout>
                    </form>
                )
            }
        </>
    )
}

const DeleteModal = ({item}: { item: any }) => {
    const dispatch = useAppDispatch();
    const {isDeleteLoading, isDeleteError, isDeleteSuccess, message } = useAppSelector((state) => state.admin);

    return (
        <>
            {
                isDeleteSuccess ? (
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon is--success"}>
                                <i className="icon-validation-check"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Suppression d'un administrateur</h2>
                                <p>L'élément a bien été supprimé avec succès</p>
                                <div className={"kubini-modal-form-status__action"}>
                                    <Button
                                        btnType={"button"}
                                        label={ "Fermer..."}
                                        btnStyle={"is--neutral"}
                                        active={true}
                                        withIconLeft={false}
                                        onClick={() => {
                                            window.location.reload()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon is--danger"}>
                                <i className="icon-delete-bin"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Suppression d'un administrateur</h2>
                                {
                                    isDeleteError ? (
                                        <p>
                                            <strong>Ouppss!!!</strong> <br/>
                                            <span>{message}</span>
                                        </p>
                                    ) : (
                                        <p>Souhaitez-vous vraiment supprimer cet élément</p>
                                    )
                                }

                                <div className={"kubini-modal-form-status__action"}>
                                    {
                                        isDeleteError ? (
                                            <Button
                                                btnType={"button"}
                                                label={isDeleteLoading ? "Suppression en cours" : "Réessayer..."}
                                                btnStyle={"is--danger"}
                                                active={!isDeleteLoading}
                                                withIconLeft={true}
                                                iconLeftClass={isDeleteLoading ? "icon-spin" : "icon-delete-bin"}
                                                onClick={() => {
                                                    dispatch(deleteAdmin({adminId: item?._id}))
                                                }}
                                            />
                                        ) : (
                                            <Button
                                                btnType={"button"}
                                                label={isDeleteLoading ? "Suppression en cours" : "Confirmer la suppression"}
                                                btnStyle={"is--danger"}
                                                active={!isDeleteLoading}
                                                withIconLeft={true}
                                                iconLeftClass={isDeleteLoading ? "icon-spin" : "icon-delete-bin"}
                                                onClick={() => {
                                                    dispatch(deleteAdmin({adminId: item?._id}))
                                                }}
                                            />
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export const AdminsPage = () => {
    const { info } = useContext(UserAuthContext);
    const [search, setSearch] = useState<string>("")
    const [numberItem, setNumberItem] = useState<number>(15)
    const [startList, setStartList] = useState<number>(1)
    const [addCategoryModal, setAddCategoryModal] = useState<boolean>(false)
    const [deleteCategoryModal, setDeleteCategoryModal] = useState<boolean>(false)
    const [itemCategoryModal, setItemCategoryModal] = useState<any>(null)
    const [chooseApercu, setChosseApercu] = useState<string>("grid")
    const dispatch = useAppDispatch();
    const { isLoading, isSuccess, isError, admins, message } = useAppSelector((state) => state.admin);
    let navigate = useNavigate()
    useEffect(() => {
        // @ts-ignore
        dispatch(getAdmins())

        return () => {
            dispatch(reset())
        }
    }, [dispatch])

    return (
        <MainLayout title={isLoading ? "Chargement en cours..." :'Administrateurs'} here='admin' here_menu='admin'>
            <div className={"kubini-page"}>
                <div><BackButton/></div>
                {
                    isLoading ? (
                        <div className={"kubini-loader"}>
                            <i className="icon-spin"/>
                        </div>
                    ) : (
                        isError ? (
                            <div className={"kubini-page-error"}>
                                <div className={"kubini-page-error__container"}>
                                    <i className="icon-delete-square"/>
                                    <div className={"kubini-page2-error__text"}>
                                        <h2>Oupss!!</h2>
                                        <p>{message}</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            isSuccess && admins ? (
                                <div className={"kubini-page__container"}>
                                    <div className={"kubini-page__header"}>
                                        <h2>Les administrateurs</h2>
                                    </div>
                                    <div className={"kubini-page__searchActions kubini-page-searchActions"}>
                                        <GridLayout direction={"fd--row"} gap={"g--6"} justify={"jc--start"} align={"al--start"} wrap={true}>
                                           {
                                             info?.admin?.super ? (
                                                <div className={"kubini-page-actions"}>
                                                    <GridLayout direction={"fd--row"} gap={"g--6"} justify={"jc--start"} align={"al--start"} wrap={true}>
                                                        <Button
                                                            btnType={"button"}
                                                            label={"Ajouter un administrateur"}
                                                            btnStyle={"is--primary"}
                                                            active={true}
                                                            withIconLeft={true}
                                                            iconLeftClass={"icon-add-square"}
                                                            onClick={() => setAddCategoryModal(true)}
                                                        />
                                                    </GridLayout>
                                                </div>
                                             ):null
                                           }
                                            <div className={"kubini-page-search"}>
                                                <i className="icon-search"/>
                                                <input
                                                    type="search"
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    placeholder={"search..."}
                                                />
                                            </div>
                                            <div className={"kubini-page-searchItem"}>
                                                <span>Nombre à afficher</span>
                                                <input
                                                    type="number"
                                                    value={numberItem}
                                                    min={5}
                                                    onChange={(e) => setNumberItem(parseInt(e.target.value))}
                                                />
                                            </div>
                                            <div className={"kubini-page-searchItem"}>
                                                <span>Commencer par</span>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    value={startList}
                                                    onChange={(e) => setStartList(parseInt(e.target.value))}
                                                />
                                            </div>
                                        </GridLayout>
                                    </div>
                                    <div className={"kubini-page-list-head"}>
                                        <div className={"kubini-page-list-head__text"}>
                                        <span>{admins.filter((el: any) => el.auth?.fullname?.toLowerCase().includes(search.toLowerCase())).length} élément(s) trouvé(s)</span>
                                        </div>
                                        <div className={"kubini-page-list-head-actions"}>
                                            <button
                                                type={"button"}
                                                className={`kubini-page-list-head-action ${chooseApercu === "grid" ? "is--active" : ""}`}
                                                onClick={() => setChosseApercu("grid")}
                                            >
                                                <i className={"icon-dashboard"}/>
                                            </button>
                                            <button
                                                type={"button"}
                                                className={`kubini-page-list-head-action ${chooseApercu === "list" ? "is--active" : ""}`}
                                                onClick={() => setChosseApercu("list")}
                                            >
                                                <i className={"icon-list-bullets"}/>
                                            </button>
                                        </div>
                                    </div>
                                    {
                                        admins
                                            .filter((el: any) => el.auth?.fullname?.toLowerCase().includes(search.toLowerCase()))
                                            .length > 0 ? (
                                                <div className={"kubini-page-list-container"}>
                                                    {
                                                        chooseApercu === "grid" ? (
                                                            <div className={"kubini-page-list-grid"}>
                                                                {
                                                                    admins
                                                                        .filter((el: any) => el.auth?.fullname?.toLowerCase().includes(search.toLowerCase()))
                                                                        .slice((startList - 1), (numberItem + (startList - 1)))
                                                                        .map((admin: any, index: number) => (
                                                                            <div className='kubini-page-list-grid-item' key={index}>
                                                                                <div className={"kubini-category-card"}>
                                                                                    <div className={"kubini-category-card__container"}>
                                                                                        <div className={"kubini-category-card__icon"}>
                                                                                            <i className="icon-user-circle"/>
                                                                                        </div>
                                                                                        <div className={"kubini-category-card__text"}>
                                                                                            <h2>{admin?.auth?.fullname}</h2>
                                                                                            <p>Nom d'utilisateur: <strong>{admin?.auth?.username}</strong></p>
                                                                                            <div className={"kubini-category-card__actions"}>
                                                                                                <button
                                                                                                    type={"button"}
                                                                                                    className={"kubini-category-card__action"}
                                                                                                    onClick={() => navigate(links.admin + admin?.auth?.username)}
                                                                                                >
                                                                                                    <i className="icon-view"/>
                                                                                                    <span>Voir</span>
                                                                                                </button>
                                                                                                {
                                                                                                    info?.admin?.super ? (
                                                                                                        <button
                                                                                                            type={"button"}
                                                                                                            className={"kubini-category-card__action is--danger"}
                                                                                                            onClick={() => {
                                                                                                                setItemCategoryModal(null)
                                                                                                                setItemCategoryModal(admin)
                                                                                                                setDeleteCategoryModal(true)
                                                                                                            }}
                                                                                                        >
                                                                                                            <i className="icon-delete-bin"/>
                                                                                                            <span>Supprimer</span>
                                                                                                        </button>
                                                                                                    ) : null
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                }
                                                            </div>
                                                        ) : (
                                                            <div className={"kubini-page-list-list"}>
                                                                <div className={"kubini-page-list-list__container"}>
                                                                    <div className={"kubini-page-list-list-header"}>
                                                                        <GridLayout direction={"fd--row"} justify={"jc--start"} align={"al--start"} gap={"g--0"}>
                                                                            <div className={"kubini-page-list-list-header-id"}>
                                                                                <strong>#</strong>
                                                                            </div>
                                                                            <div className={"kubini-page-list-list-header-large"}>
                                                                                <strong>Nom et prénom(s)</strong>
                                                                            </div>
                                                                            <div className={"kubini-page-list-list-header-mid"}>
                                                                                <strong>Nom d'utilisateur</strong>
                                                                            </div>
                                                                            <div className={"kubini-page-list-list-header-large"}>
                                                                                <strong>Contacts</strong>
                                                                            </div>
                                                                            <div className={"kubini-page-list-list-header-actions"}>
                                                                                <strong>Actions</strong>
                                                                            </div>
                                                                        </GridLayout>
                                                                    </div>
                                                                    <div className={"kubini-page-list-list-items"}>
                                                                        <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--0"} wrap={true}>
                                                                            {
                                                                                admins
                                                                                    .filter((el: any) => el.auth?.fullname?.toLowerCase().includes(search.toLowerCase()))
                                                                                    .slice((startList - 1), (numberItem + (startList - 1)))
                                                                                    .map((admin: any, index: number) => (
                                                                                        <div className={"kubini-page-list-list-item"} key={index}>
                                                                                            <GridLayout direction={"fd--row"} justify={"jc--start"} align={"al--start"} gap={"g--0"}>
                                                                                                <div className={"kubini-page2-list-list-item-id"}>
                                                                                                    <strong>{index + 1}</strong>
                                                                                                </div>
                                                                                                <div className={"kubini-page2-list-list-item-large"}>
                                                                                                    <div className={"kubini-page2-list-item-user"}>
                                                                                                        <div className={"kubini-page2-list-item-user__avatar"}>
                                                                                                            {
                                                                                                                admin?.auth?.avatar ? (
                                                                                                                    <img
                                                                                                                        src={admin?.auth?.avatar?.url}
                                                                                                                        alt={admin?.auth?.fullname}/>
                                                                                                                ) : (
                                                                                                                    <i className="icon-user-single"/>
                                                                                                                )
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div className={"kubini-page2-list-item-user__info"}>
                                                                                                            <strong>{admin?.auth?.fullname}</strong>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className={"kubini-page2-list-list-item-mid"}>
                                                                                                    <strong>{admin?.auth?.username}</strong>
                                                                                                </div>
                                                                                                <div
                                                                                                    className={"kubini-page2-list-list-item-large"}>
                                                                                                    <p>
                                                                                                        <span>Tel: {admin?.auth?.telephone || "ND"}</span>
                                                                                                        <span>Email: {admin?.auth?.email || "ND"}</span>
                                                                                                    </p>
    
                                                                                                </div>
                                                                                                <div className={"kubini-page2-list-list-item-actions"}>
                                                                                                    <button
                                                                                                        type={"button"}
                                                                                                        className={"kubini-page2-list-list-item-action"}
                                                                                                        onClick={() => navigate(links.admin + admin?.auth?.username)}
                                                                                                    >
                                                                                                        <i className="icon-view"/>
                                                                                                        <span>Voir</span>
                                                                                                    </button>
                                                                                                    {
                                                                                                        info?.admin?.super ? (
                                                                                                            <button
                                                                                                                type={"button"}
                                                                                                                className={"kubini-page2-list-list-item-action is--danger"}
                                                                                                                onClick={() => {
                                                                                                                    setItemCategoryModal(null)
                                                                                                                    setItemCategoryModal(admin)
                                                                                                                    setDeleteCategoryModal(true)
                                                                                                                }}
                                                                                                            >
                                                                                                                <i className="icon-edit-write-2"/>
                                                                                                                <span>Supprimer</span>
                                                                                                            </button>
                                                                                                        ) : null
                                                                                                    }
                                                                                                </div>
                                                                                            </GridLayout>
                                                                                        </div>
                                                                                    ))
                                                                            }
                                                                        </GridLayout>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            ) : (
                                                <strong>Aucun administrateur...</strong>
                                            )
                                    }
    
                                    {
                                        info?.admin?.super ? (
                                            <ModalLayout size={"is--md"} id={"add-modal"} isOpen={addCategoryModal} fnc={(value: boolean) => {
                                                setAddCategoryModal(value)
                                                window.location.reload()
                                            }} withClose={true}>
                                                <div className={"kubini-modal-form"}>
                                                    <div className={"kubini-modal-form__header"}>
                                                        <h2>Ajout de compte administrateur</h2>
                                                    </div>
                                                    <AddModal/>
                                                </div>
                                            </ModalLayout>
                                        ):null
                                    }
                                    {
                                        info?.admin?.super && itemCategoryModal ? (
                                            <ModalLayout size={"is--md"} isOpen={deleteCategoryModal} id={"delete-modal"}
                                                fnc={(value: boolean) => {
                                                    setDeleteCategoryModal(value)
                                                    window.location.reload()
                                                }} withClose={true}>
                                                <div className={"kubini-modal-form"}>
                                                    <DeleteModal item={itemCategoryModal}/>
                                                </div>
                                            </ModalLayout>
                                        ) : null
                                    }
                                </div>
                            ) : null

                        )
                    )
                }
            </div>
        </MainLayout>
    )
}