import React, { useContext, useEffect } from 'react';
import { MainLayout } from '../../../../layout';
import { UserAuthContext } from '../../../../context/AuthContext';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { Button, GridLayout } from '../../../../components';
import {useNavigate} from "react-router-dom";
import { links } from '../../../../routes';
import { getActivities, reset } from '../../../../../redux/feature/activity/activity.slice';

export const AccountPage = () => {
    const { info, logOut } = useContext(UserAuthContext);
    const dispatch = useAppDispatch();
    const { isLoading, isSuccess, isError, activities, message } = useAppSelector((state) => state.activity);
    let navigate = useNavigate()

    useEffect(() => {
        dispatch(getActivities({}))
        return () => {
            dispatch(reset())
        }
    }, [dispatch]);

    return (
        <MainLayout title="Mon compte">
            <div className="kubini-page">
                <div className="kubini-page__container">
                    <div className={"kubini-page2__header is--center"}>
                        <h2>Mon Compte</h2>
                    </div>
                    <div className="kubini-page-user">
                        <div className="kubini-page-user-avatar">
                            <div className="kubini-page-user-avatar__container" >
                                {
                                    info?.avatar ? (
                                        <img src={info?.avatar?.url} alt="avatar" />
                                    ) : (
                                        <i className="icon-user-single"></i>
                                    )
                                }
                            </div>
                        </div>
                        <div className="kubini-page-user-information">
                            <h3>{info?.fullname}</h3>
                            <span>{`@${info?.username}`}</span>
                            <p>Email: <strong>{info?.email || "ND"}</strong> <br /> Téléphone: <strong>{info?.telephone || "ND"}</strong></p>
                            <div className="kubini-page-user-information__actions">
                                <Button 
                                    btnType={"button"} 
                                    label={"Modifier mes informations"} 
                                    btnStyle={"is--neutral"} 
                                    active={true} 
                                    withIconLeft={false}
                                    iconLeftClass="icon-atm-card"
                                    onClick={() => navigate(links.setting_information)}
                                />
                                
                                <Button 
                                    btnType={"button"} 
                                    label={"Se deconnecter"} 
                                    btnStyle={"is--danger"} 
                                    active={true} 
                                    withIconLeft={true}
                                    iconLeftClass="icon-logout"
                                    onClick={() => logOut()}
                                />
                            </div>
                        </div>
                        <div className={"kubini-account-content"}>
                            <div className={`kubini-account-activities is--full`}>
                                <h2>Activités</h2>
                                {
                                    isLoading ? (
                                        <div className={"kubini-loader"}>
                                            <i className="icon-spin"/>
                                        </div>
                                    ) : (
                                        isError ? (
                                            <div className={"kubini-page-error"}>
                                                <div className={"kubini-page-error__container"}>
                                                    <i className="icon-delete-square"/>
                                                    <div className={"kubini-page2-error__text"}>
                                                        <h2>Oupss!!</h2>
                                                        <p>{message}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            isSuccess && activities ? (
                                                <div className='kubini-account-activities__list'>
                
                                                    {
                                                        activities
                                                            .filter((el:any) => el?.admin?._id === info?.admin?._id)
                                                            .length > 0 ? (
                                                                <GridLayout direction={'fd--column'} justify={'jc--start'} align={'al--start'} gap='g--8'>
                                                                    {
                                                                        activities
                                                                        .filter((el:any) => el?.admin?._id === info?.admin?._id)
                                                                        .map((activity: any, index: number) => (
                                                                            <div className='kubini-activity' key={index}>
                                                                                <i className="icon-hierarchy"></i>
                                                                                <div className='kubini-activity__container'>
                                                                                    <span>Modèle: {activity?.activite?.model}</span>
                                                                                    <strong>{`Vous avez ${activity?.activite?.content}`}</strong>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </GridLayout>
                                                            ) : (
                                                                <strong>Aucune activité réalisée</strong>
                                                            )
                                                    }
                                                </div>

                                            ) : null
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}