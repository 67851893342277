import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { getUsers, reset } from '../../../../../redux/feature/user/user.slice';
import { MainLayout } from '../../../../layout';
import { links } from '../../../../routes';
import { BackButton, GridLayout } from '../../../../components';

export const UsersPage = () => {
    const [search, setSearch] = useState<string>("")
    const [numberItem, setNumberItem] = useState<number>(15)
    const [startList, setStartList] = useState<number>(1)
    const [chooseApercu, setChosseApercu] = useState<string>("grid")
    const dispatch = useAppDispatch();
    const { isLoading, isSuccess, isError, users, message } = useAppSelector((state) => state.user);
    
    let navigate = useNavigate()
    useEffect(() => {
        // @ts-ignore
        dispatch(getUsers())

        return () => {
            dispatch(reset())
        }
    }, [dispatch])

    return (
        <MainLayout title={isLoading ? "Chargement en cours..." :'Utilisateurs'} here='user' here_menu='user'>
            <div className={"kubini-page"}>
                <div><BackButton/></div>
                {
                    isLoading ? (
                        <div className={"kubini-loader"}>
                            <i className="icon-spin"/>
                        </div>
                    ) : (
                        isError ? (
                            <div className={"kubini-page-error"}>
                                <div className={"kubini-page-error__container"}>
                                    <i className="icon-delete-square"/>
                                    <div className={"kubini-page2-error__text"}>
                                        <h2>Oupss!!</h2>
                                        <p>{message}</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            isSuccess && users ? (
                                <div className={"kubini-page__container"}>
                                    <div className={"kubini-page__header"}>
                                        <h2>Les utilisateurs</h2>
                                    </div>
                                    <div className={"kubini-page__searchActions kubini-page-searchActions"}>
                                        <GridLayout direction={"fd--row"} gap={"g--6"} justify={"jc--start"} align={"al--start"} wrap={true}>
                                           
                                            <div className={"kubini-page-search"}>
                                                <i className="icon-search"/>
                                                <input
                                                    type="search"
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    placeholder={"search..."}
                                                />
                                            </div>
                                            <div className={"kubini-page-searchItem"}>
                                                <span>Nombre à afficher</span>
                                                <input
                                                    type="number"
                                                    value={numberItem}
                                                    min={5}
                                                    onChange={(e) => setNumberItem(parseInt(e.target.value))}
                                                />
                                            </div>
                                            <div className={"kubini-page-searchItem"}>
                                                <span>Commencer par</span>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    value={startList}
                                                    onChange={(e) => setStartList(parseInt(e.target.value))}
                                                />
                                            </div>
                                        </GridLayout>
                                    </div>
                                    <div className={"kubini-page-list-head"}>
                                        <div className={"kubini-page-list-head__text"}>
                                        <span>{users.filter((el:any) => !el.auth?.isAdmin).filter((el: any) => el.auth?.fullname?.toLowerCase().includes(search.toLowerCase())).length} élément(s) trouvé(s)</span>
                                        </div>
                                        <div className={"kubini-page-list-head-actions"}>
                                            <button
                                                type={"button"}
                                                className={`kubini-page-list-head-action ${chooseApercu === "grid" ? "is--active" : ""}`}
                                                onClick={() => setChosseApercu("grid")}
                                            >
                                                <i className={"icon-dashboard"}/>
                                            </button>
                                            <button
                                                type={"button"}
                                                className={`kubini-page-list-head-action ${chooseApercu === "list" ? "is--active" : ""}`}
                                                onClick={() => setChosseApercu("list")}
                                            >
                                                <i className={"icon-list-bullets"}/>
                                            </button>
                                        </div>
                                    </div>
                                    {
                                        users.filter((el:any) => !el.auth?.isAdmin).filter((el: any) => el.auth?.fullname?.toLowerCase().includes(search.toLowerCase()))
                                            .length > 0 ? (
                                                <div className={"kubini-page-list-container"}>
                                                    {
                                                        chooseApercu === "grid" ? (
                                                            <div className={"kubini-page-list-grid"}>
                                                                {
                                                                    users
                                                                    .filter((el:any) => !el.auth?.isAdmin)
                                                                    .filter((el: any) => el.auth?.fullname?.toLowerCase().includes(search.toLowerCase()))
                                                                        .slice((startList - 1), (numberItem + (startList - 1)))
                                                                        .map((user: any, index: number) => (
                                                                            <div className='kubini-page-list-grid-item' key={index}>
                                                                                <div className={"kubini-category-card"}>
                                                                                    <div className={"kubini-category-card__container"}>
                                                                                        <div className={"kubini-category-card__icon"}>
                                                                                            <i className="icon-user-circle"/>
                                                                                        </div>
                                                                                        <div className={"kubini-category-card__text"}>
                                                                                            <h2>{user?.auth?.fullname}</h2>
                                                                                            <p>Nom d'utilisateur: <strong>{user?.auth?.username}</strong></p>
                                                                                            <div className={"kubini-category-card__actions"}>
                                                                                                <button
                                                                                                    type={"button"}
                                                                                                    className={"kubini-category-card__action"}
                                                                                                    onClick={() => navigate(links.user + user?.auth?.username)}
                                                                                                >
                                                                                                    <i className="icon-view"/>
                                                                                                    <span>Voir</span>
                                                                                                </button>
                                                                                                
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                }
                                                            </div>
                                                        ) : (
                                                            <div className={"kubini-page-list-list"}>
                                                                <div className={"kubini-page-list-list__container"}>
                                                                    <div className={"kubini-page-list-list-header"}>
                                                                        <GridLayout direction={"fd--row"} justify={"jc--start"} align={"al--start"} gap={"g--0"}>
                                                                            <div className={"kubini-page-list-list-header-id"}>
                                                                                <strong>#</strong>
                                                                            </div>
                                                                            <div className={"kubini-page-list-list-header-large"}>
                                                                                <strong>Nom et prénom(s)</strong>
                                                                            </div>
                                                                            <div className={"kubini-page-list-list-header-mid"}>
                                                                                <strong>Nom d'utilisateur</strong>
                                                                            </div>
                                                                            <div className={"kubini-page-list-list-header-large"}>
                                                                                <strong>Contacts</strong>
                                                                            </div>
                                                                            <div className={"kubini-page-list-list-header-actions"}>
                                                                                <strong>Actions</strong>
                                                                            </div>
                                                                        </GridLayout>
                                                                    </div>
                                                                    <div className={"kubini-page-list-list-items"}>
                                                                        <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--0"} wrap={true}>
                                                                            {
                                                                                users
                                                                                    .filter((el:any) => !el.auth?.isAdmin)
                                                                                    .filter((el: any) => el.auth?.fullname?.toLowerCase().includes(search.toLowerCase()))
                                                                                    .slice((startList - 1), (numberItem + (startList - 1)))
                                                                                    .map((user: any, index: number) => (
                                                                                        <div className={"kubini-page-list-list-item"} key={index}>
                                                                                            <GridLayout direction={"fd--row"} justify={"jc--start"} align={"al--start"} gap={"g--0"}>
                                                                                                <div className={"kubini-page2-list-list-item-id"}>
                                                                                                    <strong>{index + 1}</strong>
                                                                                                </div>
                                                                                                <div className={"kubini-page2-list-list-item-large"}>
                                                                                                    <div className={"kubini-page2-list-item-user"}>
                                                                                                        <div className={"kubini-page2-list-item-user__avatar"}>
                                                                                                            {
                                                                                                                user?.auth?.avatar ? (
                                                                                                                    <img
                                                                                                                        src={user?.auth?.avatar?.url}
                                                                                                                        alt={user?.auth?.fullname}/>
                                                                                                                ) : (
                                                                                                                    <i className="icon-user-single"/>
                                                                                                                )
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div className={"kubini-page2-list-item-user__info"}>
                                                                                                            <strong>{user?.auth?.fullname}</strong>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className={"kubini-page2-list-list-item-mid"}>
                                                                                                    <strong>{user?.auth?.username}</strong>
                                                                                                </div>
                                                                                                <div
                                                                                                    className={"kubini-page2-list-list-item-large"}>
                                                                                                    <p>
                                                                                                        <span>Tel: {user?.auth?.telephone || "ND"}</span>
                                                                                                        <span>Email: {user?.auth?.email || "ND"}</span>
                                                                                                    </p>
    
                                                                                                </div>
                                                                                                <div className={"kubini-page2-list-list-item-actions"}>
                                                                                                    <button
                                                                                                        type={"button"}
                                                                                                        className={"kubini-page2-list-list-item-action"}
                                                                                                        onClick={() => navigate(links.user + user?.auth?.username)}
                                                                                                    >
                                                                                                        <i className="icon-view"/>
                                                                                                        <span>Voir</span>
                                                                                                    </button>
                                                                                                    
                                                                                                </div>
                                                                                            </GridLayout>
                                                                                        </div>
                                                                                    ))
                                                                            }
                                                                        </GridLayout>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            ) : (
                                                <strong>Aucun administrateur...</strong>
                                            )
                                    }
    
                                </div>
                            ) : null

                        )
                    )
                }
            </div>
        </MainLayout>
    )
}