import {AlertModule, BackButton, Button, GridLayout, ModalLayout, Textfield} from "../../../../components";
import {SettingMenu} from "../module/SettingModule";
import {MainLayout} from "../../../../layout";
import {ChangeEvent, FormEvent, useContext, useEffect, useState} from "react";
import { UserAuthContext } from "../../../../context/AuthContext";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { updateAvatar, updateMyInformation } from "../../../../../redux/feature/auth/auth.slice";
import { APIURL } from "../../../../../redux/utils";
import { slugifyFileName } from "../../../../../utils/function";

const AvatarEdit = ({info}:{info: any}) => {
    const dispatch = useAppDispatch();
    const { isAvatarLoading, isAvatarError ,message } = useAppSelector((state) => state.auth);
   
    let folder: string = "avatar-admin"
    const [avatars, setAvatars] = useState<any>(null);
    const [putUrl, setPutUrl] = useState("");
    const [infoImage, setInfoImage] = useState<any>(null);
    
    const fetchPresignedUrl = async () => {
      if(avatars){
        const response = await fetch(`${APIURL}presigned-url/put?folder=${folder}&id=${info?.username}-${avatars.name}&expiry=86400`);
        const data = await response.json();
        setPutUrl(data.url)
        return data.url;
      }else{
        setError({
          field: "global",
          message: "Veuillez renseigner une images"
        })
        return
      }
    };

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [error, setError] = useState<any>(null)
    
    const handleCoverChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
        const file = e.target.files
      if(file && file.length > 0){
          let type: number = file[0].size
          let max: number = 1024 * 1024 * 4
          if(type < max){
            let info = {
              name: slugifyFileName(file[0].name),
              size: file[0].size,
              type: file[0].type,
              file: file[0]
            }
            setAvatars(info)
          }else{
              setError({
                field: "global",
                message: "Votre fichier ne doit pas dépasser 4MB"
              })
              return
          }
      }
    }
  
    const handleFileUpload = async () => {
      const file = avatars?.file
      const response = await fetch(putUrl, {
        method: 'PUT',
        body: file,
      });
      if (response.ok) {
        const photoUrl = putUrl.split('?')[0];
        setInfoImage({
          url: photoUrl,
          size: avatars?.size,
          name: avatars?.name,
          type: "image"
        })
        
      } else {
        setError({
          field: "global",
          message: "Une erreur est survenue lors de l'enregistrement de l'images"
        })
        return
      }
    };
   
    useEffect(() => {
      if(avatars){
        fetchPresignedUrl()
      }
    }, [avatars]);
  
    useEffect(() => {
      if(putUrl){
        handleFileUpload()
      }
    }, [putUrl]);
  
    useEffect(() => {
      if(infoImage){
        let data = {
          image : infoImage
        }
        dispatch(updateAvatar(data))
        setError(null)
        setAvatars("")
      }
    }, [infoImage, dispatch]);
  
    
    useEffect(() => {
      if(error){
        setOpenModal(true)
      }
    }, [error]);

    useEffect(() => {
        if(isAvatarError){
          setOpenModal(true)
        }
      }, [isAvatarError]);
  
    return (
        <>
            <div className={"kubini-page2-form__avatar kubini-page2-form-avatar"}>
                <input type="file" accept='.jpg, .png,.jpeg' id='imageImg' onChange={handleCoverChangeFile}/>
                <label className={"kubini-page2-form-avatar-image"} htmlFor={"imageImg"}>
                    {
                      isAvatarLoading ? (
                          <i className="icon-spin"/>
                      ) : (
                        infoImage ? (
                          <img src={infoImage?.url} alt="imageUrl"/>
                        ) : (
                          info?.avatar ? (
                            <img src={info?.avatar?.url} alt="imageUrl"/>
                          ): ( 
                            <i className="icon-user-single"></i>
                          )
                        )
                      )
                    }
                </label>
                <label htmlFor="imageImg" className={"kubini-page2-form-avatar-label"}>
                Changer la photo
                </label>
            </div>

            <ModalLayout size={"is--md"} isOpen={openModal} id={"edit-modal"} fnc={(value: boolean) => setOpenModal(value)} withClose={true}>
                <div className={"kubini-modal-form"}>
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon is--danger"}>
                                <i className="icon-delete-square"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Oupss!!!</h2>
                                <p>{message}</p>
                                <div className={"kubini-modal-form-status__action"}>
                                    <Button
                                        btnType={"button"}
                                        label={"Fermer"}
                                        btnStyle={"is--neutral"}
                                        active={true}
                                        withIconLeft={false}
                                        onClick={() => {
                                            setOpenModal(false)
                                            setAvatars("")
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalLayout>
        </>
    )
}


const MyInformationEdit = ({info}:{info: any}) => {

    const [data, setData] = useState<any>({
        fullname: info?.fullname, 
        telephone: info?.telephone, 
        username: info?.username
    })
    const [error, setError] = useState<any>(null)
    const dispatch = useAppDispatch();
    const { isEditLoading, isEditError, isEditSuccess,message } = useAppSelector((state) => state.auth);

  
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if(!data.fullname){
            setError({
                field: "fullname",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.telephone){
            setError({
                field: "telephone",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.username){
            setError({
                field: "username",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        setError(null)
        dispatch(updateMyInformation(data))
    }
    
    return (
      <form onSubmit={handleSubmit} className={"kubini-page2-form__container"}>
          <GridLayout direction={"fd--column"} gap={"g--10"} justify={"jc--start"} align={"al--start"}>
            <h2>Mes Informations</h2>
            {
                isEditSuccess ? (
                    <AlertModule status={"st-success"} title={"Succès!!!"} message={"Vos informations ont été modifiées avec succès"}/>
                ) : (
                    isEditError ? (
                        <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
                    ) : null
                )
            }
            <div className={"kubini-page2-form-fields"}>
              <GridLayout direction={"fd--column"} gap={"g--8"} justify={"jc--start"} align={"al--start"}>
                <div className={"kubini-page2-form-field-items"}>
                  <GridLayout direction={"fd--row"} gap={"g--4"} justify={"jc--start"} align={"al--start"}>
                    <div className={"kubini-page2-form-field-item"}>
                      <Textfield
                        id={"fullname"}
                        value={data.fullname}
                        require={true}
                        changeValue={(value: any) => setData({...data, fullname: value})}
                        type={"text"}
                        label={"Nom et prénom"}
                        placeholder={"Eg: Jean Sawadogo"}
                        error={error}
                      />
                    </div>
                  </GridLayout>
                </div>
                <div className={"kubini-page2-form-field-items"}>
                  <GridLayout direction={"fd--row"} gap={"g--4"} justify={"jc--start"} align={"al--start"}>
                    <div className={"kubini-page2-form-field-item"}>
                      <Textfield
                        id={"username"}
                        value={data.username}
                        require={false}
                        changeValue={(value: any) => setData({...data, username: value})}
                        type={"username"}
                        label={"Nom d'utilisateur"}
                        placeholder={"Eg: jon_doe"}
                        error={error}
                      />
                    </div>
                  </GridLayout>
                </div>
                <div className={"kubini-page2-form-field-items"}>
                  <GridLayout direction={"fd--row"} gap={"g--4"} justify={"jc--start"} align={"al--start"}>
                    <div className={"kubini-page2-form-field-item"}>
                      <Textfield
                        id={"telephone"}
                        value={data.telephone}
                        require={true}
                        changeValue={(value: any) => setData({...data, telephone: value})}
                        type={"phone"}
                        label={"Téléphone"}
                        placeholder={"Eg: 0022674356723"}
                        error={error}
                      />
                    </div>
                  </GridLayout>
                </div>
              </GridLayout>
            </div>
            <div className={"kubini-page2-form-action"}>
              <Button btnType={"submit"} label={isEditLoading ? "Modification en cours" : "Enregistrer les informations"} btnStyle={"is--primary"} active={!isEditLoading} withIconLeft={true} iconLeftClass={isEditLoading ? "icon-spin" : "icon-storage-floppy-disk"}/>
            </div>
          </GridLayout>
      </form>
    )
}
  

export const SettingInformationPage = () => {
    const { info } = useContext(UserAuthContext);
    return (
      <MainLayout here={"setting"} here_menu={"edit-info"} title={"Mes informations"}>
        <div className={"kubini-page2"}>
          <BackButton/>
          <div className={"kubini-page2__container"}>
            <div className={"kubini-page2__side"}>
              <SettingMenu here={"edit-info"}/>
            </div>
            <div className={"kubini-page2__content"}>
              <div className={"kubini-page2__header"}>
                <h2>Mes informations</h2>
              </div>
              <div className={"kubini-page2__form kubini-page2-form"}>
                <AvatarEdit info={info}/>
                <MyInformationEdit info={info}/>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    )
  }
  