import {FC} from "react";
import {BackButtonInterface} from "../interface/BackButtonInterface";
import {useNavigate} from "react-router-dom";


export const BackButton: FC<BackButtonInterface> = ({link, label}) => {
  let navigate = useNavigate()
  return (
    <>
      {
        link ? (
          <button className={"kubini-back-button"} type={"button"} onClick={link}>
            <i className="icon-interface-arrows-left-circle-1--arrow-keyboard-circle-button-left"></i>
            <span>{label || "Back"}</span>

          </button>
        ) : (
          <button className={"kubini-back-button"} type={"button"} onClick={() => navigate(-1)}>
            <i className="icon-interface-arrows-left-circle-1--arrow-keyboard-circle-button-left"></i>
            <span>{label || "Back"}</span>
          </button>
        )
      }
    </>
  );
};
