import React, { useEffect } from 'react';
import { MainLayout } from '../../../../layout';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { useParams } from 'react-router-dom';
import { getPub, hidePub, reset, showedPub } from '../../../../../redux/feature/pub/pub.slice';
import { AlertModule, BackButton, GridLayout } from '../../../../components';

export const PubPage = () => {
    const dispatch = useAppDispatch();
    const { isLoading, isSuccess, isError, isEditError, isEditLoading, pub, message } = useAppSelector((state) => state.pub);
    const {slug} = useParams()

    useEffect(() => {
        if(slug){
            dispatch(getPub(slug))
        }
        return () => {
            dispatch(reset())
        }
    }, [dispatch, slug]);
    return (
        <MainLayout title='Publictiés' here='pub'>
            <div className={"kubini-account-page"}>
                <div><BackButton/></div>

                {
                    isLoading  ? (
                        <div className={"kubini-loader"}>
                            <i className="icon-spin"/>
                        </div>
                    ) : (
                        isError ? (
                            <div className={"kubini-page-error"}>
                                <div className={"kubini-page-error__container"}>
                                    <i className="icon-delete-square"/>
                                    <div className={"kubini-page2-error__text"}>
                                        <h2>Oupss!!</h2>
                                        <p>{message}</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            isSuccess && pub ? (
                                <GridLayout direction={'fd--column'} justify={'jc--start'} gap={"g--8"} align={'al--start'} >
                                    {
                                        isEditError ? (
                                            <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
                                        ) : null
                                    }
                                    <div className={"kubini-account-banner"}>
                                        <GridLayout direction={"fd--row"} wrap={true} justify={"jc--start"} align={"al--start"} gap={"g--10"}>
                                            <div className={"kubini-account-avatar"}>
                                                <div className={"kubini-account-avatar-form-image"}>
                                                    <i className="icon-mega-phone"></i>
                                                </div>
                                            </div>
                                            <div className={`kubini-account-information`}>
                                                <div className={"kubini-account-information__icon"}>
                                                    <i className="icon-file-clipboard-text"></i>
                                                </div>
                                                <div className={"kubini-account-information__text kubini-account-information-text"}>
                                                    <GridLayout direction={"fd--column"}
                                                                justify={"jc--start"} align={"al--start"}
                                                                gap={"g--2"}>
                                                        <div className={"kubini-account-information-text__item"}>
                                                            <GridLayout direction={"fd--row"}
                                                                        justify={"jc--between"}
                                                                        align={"al--start"} gap={"g--2"}
                                                                        wrap={true}>
                                                                <span>Nom: </span>
                                                                <strong>{pub?.name}</strong>
                                                            </GridLayout>
                                                        </div>
                                                        <div
                                                            className={"kubini-account-information-text__item"}>
                                                            <GridLayout direction={"fd--row"}
                                                                        justify={"jc--between"}
                                                                        align={"al--start"} gap={"g--2"}
                                                                        wrap={true}>
                                                                <span>Lien vers: </span>
                                                                <strong>{pub?.link || "ND"}</strong>
                                                            </GridLayout>
                                                        </div>
                                                        <div
                                                            className={"kubini-account-information-text__item"}>
                                                            <GridLayout direction={"fd--row"}
                                                                        justify={"jc--between"}
                                                                        align={"al--start"} gap={"g--2"}
                                                                        wrap={true}>
                                                                <span>Emplacement: </span>
                                                                <strong>{pub?.type_pub || "ND"}</strong>
                                                            </GridLayout>
                                                        </div>
                                                    </GridLayout>
                                                </div>
                                            </div>
                                            <button type={"button"} className={"kubini-account-password"} onClick={() => {
                                                if(pub.affiche){
                                                    dispatch(hidePub({
                                                        id: pub?._id
                                                    }))
                                                }else{
                                                    dispatch(showedPub({
                                                        id: pub?._id
                                                    }))
                                                }
                                            }}>
                                                <div className={"kubini-account-password__icon"}>
                                                    {
                                                        isEditLoading ? (
                                                            <i className="icon-spin"></i>
                                                        ) : (
                                                            <i className={pub.affiche ? "icon-view" : "icon-view-of"}></i>
                                                        )
                                                    }
                                                </div>
                                                <div className={"kubini-account-password__text"}>
                                                    <strong>{!pub.affiche ? "La pub est masquée" : "La pub est visible"}</strong>
                                                    <p>Cliquer ici pour {pub.affiche ? "masquer la publicité sur la plateforme" : "afficher la publicité sur la plateforme"}</p>
                                                </div>
                                            </button>
                                                
                                        </GridLayout>
                                    </div>
                                    <div className='kubini-account-bg'>
                                        <img src={pub.cover?.url} alt="pub" />
                                    </div>
                                </GridLayout>
                            ) : null
                        )
                    )
                }
            </div>
        </MainLayout>
    )
}