import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {APIURL, getProtecteItems, updateProtecteItems,} from "../../utils";
import {GeneralStateInterface} from "../../interface/GeneralStateInterface";

interface UserState extends GeneralStateInterface{
    users:  any[],
    user:  any,
}

const initialState: UserState = {

    users: [],
    user: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    message: null,
}

export const getUsers = createAsyncThunk<any, Object>(
    'user/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}user/list`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const getUser = createAsyncThunk<any, Object>(
    'user/show',
    async (username: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}user/show/${username}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const banUser = createAsyncThunk<any, Object>(
    'user/ban',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}user/ban`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const unbanUser = createAsyncThunk<any, Object>(
    'user/unban',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}user/unban`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)



export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false
            
            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.users = action.payload
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(getUser.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })


            .addCase(banUser.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(banUser.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.users = [action.payload, ...state.users.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.user = action.payload
            })
            .addCase(banUser.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(unbanUser.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(unbanUser.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.users = [action.payload, ...state.users.filter((admin: any) => admin?._id !== action.payload?._id)]
                state.user = action.payload
            })
            .addCase(unbanUser.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

    }
})

export const { reset } = userSlice.actions
export default userSlice.reducer