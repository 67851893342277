import React, { useContext, useEffect, useState } from 'react';
import { MainLayout } from '../../../../layout';
import { getAdmin, recoverPassword, reset } from '../../../../../redux/feature/admin/admin.slice';
import { BackButton, Button, GridLayout, ModalLayout } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { UserAuthContext } from '../../../../context/AuthContext';
import { useParams } from 'react-router-dom';
import { getActivities, reset as areset } from '../../../../../redux/feature/activity/activity.slice';


const RecoverPassword = ({user}:{user: any}) => {
    const { isEditLoading, isEditSuccess, isEditError, message } = useAppSelector((state) => state.admin);
    const dispatch = useAppDispatch();
    return (
        <>
            {
                isEditSuccess ? (
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon is--success"}>
                                <i className="icon-validation-check"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Réinitialisation du mot de passer</h2>
                                <p>Le mot de passe de l'administrateur a bien été réinitialisé avec succès</p>
                                <p>Le nouveau mot de passe est: <strong>123456789</strong></p>
                                <div className={"kubini-modal-form-status__action"}>
                                    <Button
                                        btnType={"button"}
                                        label={ "Fermer..."}
                                        btnStyle={"is--neutral"}
                                        active={true}
                                        withIconLeft={false}
                                        onClick={() => {
                                            window.location.reload()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon"}>
                                <i className="icon-lock-circle"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Réinitialisation du mot de passe</h2>
                                {
                                    isEditError ? (
                                        <p>
                                            <strong>Ouppss!!!</strong> <br/>
                                            <span>{message}</span>
                                        </p>
                                    ) : (
                                        <p>Souhaitez-vous vraiment réinitialiser le mot de passe de cet administrateur</p>
                                    )
                                }

                                <div className={"kubini-modal-form-status__action"}>
                                    {
                                        isEditError ? (
                                            <Button
                                                btnType={"button"}
                                                label={isEditLoading ? "Suppression en cours" : "Réessayer..."}
                                                btnStyle={"is--danger"}
                                                active={!isEditLoading}
                                                withIconLeft={true}
                                                iconLeftClass={isEditLoading ? "icon-spin" : "icon-lock-circle"}
                                                onClick={() => {
                                                    dispatch(recoverPassword({adminId: user?._id}))
                                                }}
                                            />
                                        ) : (
                                            <Button
                                                btnType={"button"}
                                                label={isEditLoading ? "Réinitialisation en cours..." : "Confirmer la réinitialisation"}
                                                btnStyle={"is--primary"}
                                                active={!isEditLoading}
                                                withIconLeft={true}
                                                iconLeftClass={isEditLoading ? "icon-spin" : "icon-lock-circle"}
                                                onClick={() => {
                                                    dispatch(recoverPassword({adminId: user?._id}))
                                                }}
                                            />
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export const AdminPage = () => {
    const { info } = useContext(UserAuthContext);
    const dispatch = useAppDispatch();
    const { isLoading, isSuccess, isError, admin, message } = useAppSelector((state) => state.admin);
    const { isLoading: isALoading, isSuccess: isASuccess, isError: isAError, activities, message: aMessage } = useAppSelector((state) => state.activity);
    const {username} = useParams()
    const [passwordModal, setPasswordModal] = useState<boolean>(false)
    
    useEffect(() => {
        if(username){
            dispatch(getAdmin(username))
            // @ts-ignore
            dispatch(getActivities())
        }
        return () => {
            dispatch(reset())
            dispatch(areset())
        }
    }, [dispatch, username]);
    
    return (
        <MainLayout title='Administrateur' here='admin' here_menu='admin'>
            <div className={"kubini-account-page"}>
                <div><BackButton/></div>
                {
                    isLoading || isALoading ? (
                        <div className={"kubini-loader"}>
                            <i className="icon-spin"/>
                        </div>
                    ) : (
                        isError || isAError ? (
                            <div className={"kubini-page-error"}>
                                <div className={"kubini-page-error__container"}>
                                    <i className="icon-delete-square"/>
                                    <div className={"kubini-page2-error__text"}>
                                        <h2>Oupss!!</h2>
                                        <p>{message || aMessage}</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            isSuccess && isASuccess && activities && admin ? (
                                <GridLayout direction={'fd--column'} justify={'jc--start'} gap={"g--8"} align={'al--start'} >
                                    <div className={"kubini-account-banner"}>
                                        <GridLayout direction={"fd--row"} wrap={true} justify={"jc--start"} align={"al--start"} gap={"g--10"}>
                                            <div className={"kubini-account-avatar"}>
                                                <div className={"kubini-account-avatar-form-image"}>
                                                    {
                                                        admin?.auth?.avatar ? (
                                                            <img src={admin?.auth?.avatar?.url}
                                                                    alt="imageUrl"/>
                                                        ) : (<i className="icon-user-circle"></i>)
                                                    }
                                                </div>
                                            </div>
                                            <div className={`kubini-account-information ${info?.admin?.super ? "" : "type-0"}`}>
                                                <div className={"kubini-account-information__icon"}>
                                                    <i className="icon-file-clipboard-text"></i>
                                                </div>
                                                <div className={"kubini-account-information__text kubini-account-information-text"}>
                                                    <GridLayout direction={"fd--column"}
                                                                justify={"jc--start"} align={"al--start"}
                                                                gap={"g--2"}>
                                                        <div className={"kubini-account-information-text__item"}>
                                                            <GridLayout direction={"fd--row"}
                                                                        justify={"jc--between"}
                                                                        align={"al--start"} gap={"g--2"}
                                                                        wrap={true}>
                                                                <span>Nom et prénom(s): </span>
                                                                <strong>{admin?.auth?.fullname}</strong>
                                                            </GridLayout>
                                                        </div>
                                                        <div
                                                            className={"kubini-account-information-text__item"}>
                                                            <GridLayout direction={"fd--row"}
                                                                        justify={"jc--between"}
                                                                        align={"al--start"} gap={"g--2"}
                                                                        wrap={true}>
                                                                <span>Identifiant: </span>
                                                                <strong>{admin?.auth?.username || "ND"}</strong>
                                                            </GridLayout>
                                                        </div>
                                                        <div
                                                            className={"kubini-account-information-text__item"}>
                                                            <GridLayout direction={"fd--row"}
                                                                        justify={"jc--between"}
                                                                        align={"al--start"} gap={"g--2"}
                                                                        wrap={true}>
                                                                <span>Email: </span>
                                                                <strong>{admin?.auth?.email || "ND"}</strong>
                                                            </GridLayout>
                                                        </div>
                                                        <div
                                                            className={"kubini-account-information-text__item"}>
                                                            <GridLayout direction={"fd--row"}
                                                                        justify={"jc--between"}
                                                                        align={"al--start"} gap={"g--2"}
                                                                        wrap={true}>
                                                                <span>Téléphone: </span>
                                                                <strong>{admin?.auth?.telephone}</strong>
                                                            </GridLayout>
                                                        </div>
                                                    </GridLayout>
                                                </div>
                                            </div>
                                            {
                                                info?.admin?.super ? (
                                                    <button type={"button"} className={"kubini-account-password"} onClick={() => setPasswordModal(true)}>
                                                        <div className={"kubini-account-password__icon"}>
                                                            <i className="icon-lock"></i>
                                                        </div>
                                                        <div className={"kubini-account-password__text"}>
                                                            <strong>Mot de passe</strong>
                                                            <p>Réinitialiser le mot de passe</p>
                                                        </div>
                                                    </button>
                                                ) : null
                                            }
                                        </GridLayout>
                                    </div>
                                    <div className={"kubini-account-content"}>
                                        <div className={`kubini-account-activities is--full`}>
                                            <h2>Activités</h2>
                                            <div className='kubini-account-activities__list'>
                                                {
                                                    activities
                                                    .filter((el:any) => el?.admin?._id === admin?._id)
                                                    .length > 0 ? (
                                                        <GridLayout direction={'fd--column'} justify={'jc--start'} align={'al--start'} gap='g--8'>
                                                            {
                                                                activities
                                                                .filter((el:any) => el?.admin?._id === admin?._id)
                                                                .map((activity: any, index: number) => (
                                                                    <div className='kubini-activity' key={index}>
                                                                        <i className="icon-hierarchy"></i>
                                                                        <div className='kubini-activity__container'>
                                                                            <span>Modèle: {activity?.activite?.model}</span>
                                                                            <strong>{`Cet administrateur a ${activity?.activite?.content}`}</strong>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </GridLayout>
                                                    ) : (
                                                        <strong>Aucune activité réalisée</strong>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </GridLayout>
                            ) : null
                        )
                    )
                }

                {
                    admin ? (
                        <ModalLayout size={"is--md"} isOpen={passwordModal} id={"edit-modal"} fnc={(value: boolean) => setPasswordModal(value)} withClose={true}>
                            <div className={"kubini-modal-form"}>
                            <div className={"kubini-modal-form__header"}>
                                    <h2>Réinitialisation du mot de passe</h2>
                                </div>
                                <RecoverPassword user={admin}/>
                            </div>
                        </ModalLayout>
                    ) : null
                }
            </div>
        </MainLayout>
    )
}