import React, {FC, ReactNode} from 'react';
import images from "../../assets/images";
import {GridLayout, LogoIcon} from "../../components";
import {SettingComponent} from "./SettingComponent";
import { titlePage } from '../../../utils/function';

interface AuthLayoutParams {
    title?: string,
    subtitle?: string,
    children?: ReactNode
}

export const AuthLayout:FC<AuthLayoutParams> = ({title, subtitle, children}) => {
    const {auth} = images
    titlePage(title)

    return (
        <>
            <div className={"kubini-auth-content"}>
                <div className={"kubini-auth-content__container"}>
                    <header className={"kubini-auth-content__header kubini-auth-content-header"}>
                        <div className={"kubini-auth-content-header__container"}>
                            <div className={"kubini-auth-content-header__logo"}>
                                <LogoIcon/>
                            </div>
                            <div className={"kubini-auth-content-header__text"}>
                                <h1>{title}</h1>
                                <span>{subtitle}</span>
                            </div>
                        </div>
                    </header>
                    <main className={"kubini-auth-content__main"} role={"main"}>
                        {children}
                    </main>
                    <footer className={"kubini-auth-content__footer kubini-auth-content-footer"}>
                        <GridLayout direction={"fd--column"} justify={"jc--center"} align={"al--center"} gap={"g--2"}>
                            <p>©Enka-market</p>
                            <div className={"kubini-auth-content-footer__container"}>
                                <p>Designed with <i className="icon-favorite-heart"></i> by <a href="https://www.hisiastudio.com" rel='noreferrer' target='_blank'>Hisia Studio</a></p>
                            </div>
                        </GridLayout>
                    </footer>
                </div>
            </div>
            <div className={"kubini-auth-image"}>
                <img src={auth} alt="kubini-auth"/>
            </div>
            <SettingComponent/>
        </>
    );
};
