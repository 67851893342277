import {FC} from "react";
import {ModalLayoutParams} from "../interface/ModalInterface";

export const ModalLayout: FC<ModalLayoutParams> = ({id, fnc, size, isOpen, children, withClose}) => {
    return (
        <div className={`kubini-modal ${isOpen ? "is--open" : ""}`} id={id}>
          <div className={`kubini-modal-container ${size}`}>
            {
              withClose ? (
                <button type={"button"} className={"kubini-modal__close-btn"} onClick={() => fnc(false)}>
                  <i className="icon-delete-square"/>
                </button>
              ) : null
            }
            <div className={"kubini-modal-contain"}>
              {children}
            </div>
          </div>
        </div>
    );
};
