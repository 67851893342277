import React from 'react';
import { MainLayout } from '../../../layout';
import { useNavigate } from 'react-router-dom';
import { links } from '../../../routes';
import {  GridLayout } from '../../../components';


export const PlatformPage = () => {
    let navigate = useNavigate()
    const settingItems = [
        {
            link: links.articles,
            label: "Les annonces",
            description: "Voir et modérer les annonces publiées sur le site",
            icon: "icon-shopping-cart ",
            isShow: true
        },
        {
            link: links.events,
            label: "Les évènements",
            description: "Voir et modérer les évènements publiés sur le site",
            icon: "icon-calendar-mark",
            isShow: true
        },
        {
            link: links.posts,
            label: "Les publications",
            description: "Voir et modérer les publications publiées sur le site",
            icon: "icon-copy-clipboard",
            isShow: true
        },
        {
            link: links.categories,
            label: "Les catégories",
            description: "Voir, ajouter, modifier et supprimer les catégories d'articles",
            icon: "icon-application-remove",
            isShow: true
        },
        {
            link: links.subcategories,
            label: "Les sous-catégories",
            description: "Voir, ajouter, modifier et supprimer les sous-catégories d'articles",
            icon: "icon-module-three",
            isShow: true
        },
        {
            link: links.cities,
            label: "Les villes",
            description: "Voir, ajouter, modifier et supprimer les villes",
            icon: "icon-building",
            isShow: true
        },
        {
            link: links.partners,
            label: "Nos partenaires",
            description: "Voir, ajouter, modifier et supprimer les partenaires",
            icon: "icon-content-book-phone",
            isShow: true
        },
        {
            link: links.feedbacks,
            label: "Retours clients",
            description: "Voir et repondre aux différents retours clients",
            icon: "icon-chat-two-bubbles-oval",
            isShow: true
        },
    ]
    return (
        <MainLayout title='Sur la plateforme' here='platform'>
            <div className={"kubini-page"}>
                <div className={"kubini-page__container"}>
                <div className={"kubini-page__header"}>
                    <i className={"icon-setting-cog"}></i>
                    <h1>Plateforme</h1>
                    <p>Ici, vous pouvez gérer les activités de la plateforme.</p>
                </div>
                <div className={"kubini-page__content"}>
                    <GridLayout direction={"fd--row"} justify={"jc--start"} wrap={true} align={"al--start"} gap={"g--10"}>
                    {
                        settingItems.map((item, index) => (
                        <div className={"kubini-page-item"} key={index}>
                            <button type={"button"} className={`kubini-page-item-btn ${item.isShow ? "" : "is--disable"}`} onClick={() => {
                            if(item.isShow) {
                                navigate(item.link)
                            }
                            }}>
                            <div className={"kubini-page-item-btn__icon"}>
                                <i className={item.icon}></i>
                            </div>
                            <div className={"kubini-page-item-btn__text"}>
                                <strong>{item.label}</strong>
                                <p>{item.description}</p>
                            </div>
                            </button>
                        </div>
                        ))
                    }
                    </GridLayout>
                </div>
                </div>
            </div>
        </MainLayout>
    )
}