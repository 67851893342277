import {FormEvent, useState} from "react";
import {AlertModule, BackButton, Button, GridLayout, Textfield} from "../../../../components";
import {SettingMenu} from "../module/SettingModule";
import {MainLayout} from "../../../../layout";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { updatePassword } from "../../../../../redux/feature/auth/auth.slice";

export const SettingPasswordPage = () => {
    const [data, setData] = useState<any>({
        password: "",
        newPassword: "",
        confirmPassword: "",
    })
    const [error, setError] = useState<any>(null)
    const dispatch = useAppDispatch();
    const { isPasswordLoading, isPasswordError, isPasswordSuccess,message } = useAppSelector((state) => state.auth);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if(!data.password){
            setError({
                field: "password",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.newPassword){
            setError({
                field: "newPassword",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.confirmPassword){
            setError({
                field: "confirmPassword",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        setError(null)
        dispatch(updatePassword(data))
    }
    
    return (
      <MainLayout here={"setting"} here_menu={"edit-password"} title={"Mes informations"}>
        <div className={"kubini-page2"}>
          <BackButton/>
          <div className={"kubini-page2__container"}>
            <div className={"kubini-page2__side"}>
              <SettingMenu here={"edit-password"}/>
            </div>
            <div className={"kubini-page2__content"}>
              <div className={"kubini-page2__header"}>
                <h2>Mes mots de passe</h2>
              </div>
              <div className={"kubini-page2__form kubini-page2-form"}>
                <form onSubmit={handleSubmit} className={"kubini-page2-form__container"}>
                    <GridLayout direction={"fd--column"} gap={"g--10"} justify={"jc--start"} align={"al--start"}>
                        <h2>Mot de passe</h2>
                        {
                            isPasswordSuccess ? (
                                <AlertModule status={"st-success"} title={"Succès!!!"} message={"Votre mot de passe a été modifiés avec succès"}/>
                            ) : (
                                isPasswordError ? (
                                    <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
                                ) : null
                            )
                        }

                        <div className={"kubini-page2-form-fields"}>
                        <GridLayout direction={"fd--column"} gap={"g--8"} justify={"jc--start"} align={"al--start"}>
                            <div className={"kubini-page2-form-field-items"}>
                            <GridLayout direction={"fd--row"} gap={"g--4"} justify={"jc--start"} align={"al--start"}>
                                <div className={"kubini-page2-form-field-item"}>
                                <Textfield
                                    id={"password"}
                                    value={data.password}
                                    require={true}
                                    changeValue={(value: any) => setData({...data, password: value})}
                                    type={"password"}
                                    label={"Ancien mot de passe"}
                                    placeholder={"Eg: *****"}
                                    error={error}
                                />
                                </div>
                            </GridLayout>
                            </div>
                            <div className={"kubini-page2-form-field-items"}>
                            <GridLayout direction={"fd--row"} gap={"g--4"} justify={"jc--start"} align={"al--start"}>
                                <div className={"kubini-page2-form-field-item"}>
                                <Textfield
                                    id={"newPassword"}
                                    value={data.newPassword}
                                    require={true}
                                    changeValue={(value: any) => setData({...data, newPassword: value})}
                                    type={"password"}
                                    label={"Nouveau mot de passe"}
                                    placeholder={"Eg: ****"}
                                    error={error}
                                />
                                </div>
                            </GridLayout>
                            </div>
                            <div className={"kubini-page2-form-field-items"}>
                            <GridLayout direction={"fd--row"} gap={"g--4"} justify={"jc--start"} align={"al--start"}>
                                <div className={"kubini-page2-form-field-item"}>
                                <Textfield
                                    id={"confirmPassword"}
                                    value={data.confirmPassword}
                                    require={true}
                                    changeValue={(value: any) => setData({...data, confirmPassword: value})}
                                    type={"password"}
                                    label={"Confirmation du mot de passe"}
                                    placeholder={"Eg: ****"}
                                    error={error}
                                />
                                </div>
                            </GridLayout>
                            </div>
                        </GridLayout>
                        </div>
                        <div className={"kubini-page2-form-action"}>
                        <Button btnType={"submit"} label={isPasswordLoading ? "Modification en cours" : "Enregistrer les informations"} btnStyle={"is--primary"} active={!isPasswordLoading} withIconLeft={true} iconLeftClass={isPasswordLoading ? "icon-spin" : "icon-storage-floppy-disk"}/>
                        </div>
                    </GridLayout>
                </form>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    )
  }
  