import React from 'react';
import { MainLayout } from '../../../layout';

export const HomePage = () => {
    return (
        <MainLayout here='home'>
            <div className={"kubini-page-error"}>
                <div className={"kubini-page-error__container"}>
                    {/* <i className="icon-delete-square"/> */}
                    <div className={"kubini-page2-error__text"}>
                        <h2>Page en developpement!!</h2>
                        <p>{"Dis-moi ce que tu veux j'affiche"}</p>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}