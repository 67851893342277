import axios from "axios"
import {APIURL} from "../../utils";

const getCategoriesOption =  async (token: any ) => {

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.get(APIURL + "category/list", config)
    let tabs:any[] = []
    if(response.data){
        let categories: any[] = response.data || []

        categories.forEach((el: any) => {
            let item = {
                value: el._id,
                name: el.name
            }

            tabs = [...tabs, item]
        })
    }

    return tabs
}

const subcategoryService = {
    getCategoriesOption
}

export default subcategoryService