import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {APIURL, getProtecteItems} from "../../utils";
import {GeneralStateInterface} from "../../interface/GeneralStateInterface";

interface ActivityState extends GeneralStateInterface{
    activities:  any[],
}

const initialState: ActivityState = {
    activities: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: null,
}

export const getActivities = createAsyncThunk<any, Object>(
    'activity/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${APIURL}activity/list`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const actitySlice = createSlice({
    name: 'activity',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getActivities.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getActivities.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.activities = action.payload
            })
            .addCase(getActivities.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
})

export const { reset } = actitySlice.actions
export default actitySlice.reducer