import { FC } from "react";
import {links} from "../../../../routes";
import {useNavigate} from "react-router-dom";

interface SettingMenuParams {
  here?: string
}

export const SettingMenu: FC<SettingMenuParams> = ({here}) => {

  const submenuItems = [
    {
      link: links.setting_information,
      label: "Mes informations",
      icon: "icon-security-shield-person",
      isActive: here === "edit-info" ? "is--active" : "",
      isShow: true
    },
    {
      link: links.setting_password,
      label: "Mot de passe",
      icon: "icon-setting-wrench",
      isActive: here === "edit-password" ? "is--active" : "",
      isShow: true
    },
    {
      link: links.setting_custom,
      label: "Configuration",
      icon: "icon-setting-slider",
      isActive: here === "custom" ? "is--active" : "",
      isShow: true
    },
  ]
  let navigate = useNavigate()
  return (
    <div className={"kubini-submenu"}>
      <div className={"kubini-submenu__container"}>
        <div className={"kubini-submenu__header"}>
          <h2>Paramètres</h2>
        </div>
        <div className={"kubini-submenu__list"}>
          {
            submenuItems.map((item, index) => (
              <button
                type={"button"}
                className={`kubini-submenu-item ${item.isActive}`}
                key={index}
                onClick={() => navigate(item.link)}
              >
                <i className={item.icon}></i>
                <span>{item.label}</span>
              </button>
            ))
          }
        </div>
      </div>
    </div>
  );
};
