import React, { FC } from 'react'
import { links } from '../../../../routes'
import { useNavigate } from 'react-router-dom'

interface PlatformMenuParams {
    here?: string
}
  
const PlatformMenu: FC<PlatformMenuParams> = ({here}) => {
    const submenuItems = [
        {
          link: links.articles,
          label: "Annonces",
          icon: "icon-shopping-cart ",
          isActive: here === "annonce" ? "is--active" : "",
          isShow: true
        },
        {
          link: links.events,
          label: "Evènements",
          icon: "icon-calendar-mark",
          isActive: here === "event" ? "is--active" : "",
          isShow: true
        },
        {
          link: links.posts,
          label: "Publication",
          icon: "icon-copy-clipboard",
          isActive: here === "post" ? "is--active" : "",
          isShow: true
        },
        {
          link: links.categories,
          label: "Catégories",
          icon: "icon-application-remove",
          isActive: here === "category" ? "is--active" : "",
          isShow: true
        },
        {
          link: links.subcategories,
          label: "Sous-catégories",
          icon: "icon-module-three",
          isActive: here === "subcategory" ? "is--active" : "",
          isShow: true
        },
        {
          link: links.cities,
          label: "Villes",
          icon: "icon-building",
          isActive: here === "city" ? "is--active" : "",
          isShow: true
        },
        {
            link: links.partners,
            label: "Nos partenaires",
            isActive: here === "partner" ? "is--active" : "",
            icon: "icon-content-book-phone",
            isShow: true
        },
        {
          link: links.feedbacks,
          label: "Feedbacks",
          icon: "icon-chat-two-bubbles-oval",
          isActive: here === "feedback" ? "is--active" : "",
          isShow: true
        },
    ]
    let navigate = useNavigate()
    return (
        <div className={"kubini-submenu"}>
          <div className={"kubini-submenu__container"}>
            <div className={"kubini-submenu__header"}>
              <h2>Plateforme</h2>
            </div>
            <div className={"kubini-submenu__list"}>
              {
                submenuItems.map((item, index) => (
                  <button
                    type={"button"}
                    className={`kubini-submenu-item ${item.isActive}`}
                    key={index}
                    onClick={() => navigate(item.link)}
                  >
                    <i className={item.icon}></i>
                    <span>{item.label}</span>
                  </button>
                ))
              }
            </div>
          </div>
        </div>
    )
}
export default PlatformMenu