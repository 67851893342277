import React, { FormEvent, useEffect, useState } from 'react';
import { MainLayout } from '../../../../layout';
import { Link, useParams } from 'react-router-dom';
import { AlertModule, BackButton, Button, GridLayout, ModalLayout, Textfield } from '../../../../components';
import { getCategory, updateCategory, reset } from '../../../../../redux/feature/category/category.slice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import PlatformMenu from '../../platform/module/PlatformMenu';
import { capitalize } from '../../../../../utils/function';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { links } from '../../../../routes';

const EditModal = ({item}:{item: any}) => {
    const [data, setData] = useState<any>({
        name: item.name,
        id: item?._id
    })
    const dispatch = useAppDispatch();
    const categoryState: any = useAppSelector((state) => state.category);

    const [error, setError] = useState<any>({
        field: "",
        message: ""
    })
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if(!data.name){
            setError({
                field: "name",
                message: "Veuillez renseigner ce champs"
            })
            return
        }

        setError(null)

        dispatch(updateCategory(data))
    }
  
    return (
        <>
            {
                categoryState.isEditSuccess ? (
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon is--success"}>
                                <i className="icon-validation-check"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Modification de la catégorie</h2>
                                <p>Les informations a été modifiées avec succès</p>
                                <div className={"kubini-modal-form-status__action"}>
                                    <Button
                                        btnType={"button"}
                                        label={"Fermer..."}
                                        btnStyle={"is--neutral"}
                                        active={true}
                                        withIconLeft={false}
                                        onClick={() => {
                                            window.location.reload()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <form className={"kubini-modal-form__container"} onSubmit={handleSubmit}>
                        <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--10"}>
                            {
                                categoryState.isEditError ? (
                                    <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={categoryState.message}/>
                                ) : null
                            }
                            <div className={"kubini-modal-form__fields"}>
                                <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--8"}>
                                    <div className={"kubini-modal-form__field"}>
                                        <Textfield
                                            id={"name"}
                                            value={data.name}
                                            require={true}
                                            changeValue={(value: any) => setData({...data, name: value})}
                                            type={"text"}
                                            label={"Nom"}
                                            placeholder={"ex: vêtements homme"}
                                            error={error}
                                        />
                                    </div>
                                </GridLayout>
                            </div>
                            <div className={"kubini-modal-form__action"}>
                                <Button btnType={"submit"} label={categoryState.isEditLoading ? "Modification en cours..." : "Enregistrer les modifications"} btnStyle={"is--primary"} active={!categoryState.isEditLoading} withIconLeft={true} iconLeftClass={categoryState.isEditLoading ? "icon-spin" :"icon-storage-floppy-disk"}/>
                            </div>
                        </GridLayout>
                    </form>
                )
            }
        </>
    )
}

export const CategoryPage = () => {
    const {slug} = useParams()
    const [editCategoryModal, setEditCategoryModal] = useState<boolean>(false)
    const dispatch = useAppDispatch();
    const categoryState: any = useAppSelector((state) => state.category);
 
    useEffect(() => {
        if(slug){
            dispatch(getCategory(slug))
        }
        return () => {
            dispatch(reset())
        }
    }, [dispatch, slug])

    return (
        <MainLayout title='Catégorie' here='plateform' here_menu='category'>
            <div className={"kubini-page2"}>
                <div><BackButton/></div>
                <div className={"kubini-page2__container"}>
                    <div className={"kubini-page2__side"}>
                        <PlatformMenu here={"category"}/>
                    </div>
                    <div className={"kubini-page2__content"}>
                        {
                            categoryState.isLoading ? (
                                <div className={"kubini-loader"}>
                                    <i className="icon-spin"/>
                                </div>
                            ) : (
                                categoryState.isError ? (
                                    <div className={"kubini-page-error"}>
                                        <div className={"kubini-page-error__container"}>
                                            <i className="icon-delete-square"/>
                                            <div className={"kubini-page2-error__text"}>
                                                <h2>Oupss!!</h2>
                                                <p>{categoryState.message}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    categoryState.isSuccess && categoryState.category ? ( 
                                        <GridLayout direction={'fd--column'} justify={'jc--start'} gap={"g--8"} align={'al--start'}  >
                                            <div className={"kubini-account-banner"}>
                                                <GridLayout direction={"fd--row"} wrap={true} justify={"jc--start"} align={"al--start"} gap={"g--10"}>
                                                    <div className={"kubini-account-avatar"}>
                                                        <div className={"kubini-account-avatar-form-image"}>
                                                            <i className="icon-align-layers"></i>
                                                        </div>
                                                    </div>

                                                    <button className={`kubini-account-information type-0`} type='button' onClick={() => setEditCategoryModal(true)}>
                                                        <div className={"kubini-account-information__icon"}>
                                                            <i className="icon-file-clipboard-text"></i>
                                                        </div>
                                                        <div className={"kubini-account-information__text kubini-account-information-text"}>
                                                            <GridLayout direction={"fd--column"}
                                                                        justify={"jc--start"} align={"al--start"}
                                                                        gap={"g--2"}>
                                                                <div className={"kubini-account-information-text__item"}>
                                                                    <GridLayout direction={"fd--row"}
                                                                                justify={"jc--between"}
                                                                                align={"al--start"} gap={"g--2"}
                                                                                wrap={true}>
                                                                        <span>Nom: </span>
                                                                        <strong>{categoryState.category?.name}</strong>
                                                                    </GridLayout>
                                                                </div>
                                                                <div className={"kubini-account-information-text__item"}>
                                                                    <GridLayout direction={"fd--row"}
                                                                                justify={"jc--between"}
                                                                                align={"al--start"} gap={"g--2"}
                                                                                wrap={true}>
                                                                        <span>Nombre de sous-categorie: </span>
                                                                        <strong>{categoryState.category?.subcategories.length || "ND"}</strong>
                                                                    </GridLayout>
                                                                </div>
                                                                <div className={"kubini-account-information-text__item"}>
                                                                    <GridLayout direction={"fd--row"}
                                                                                justify={"jc--between"}
                                                                                align={"al--start"} gap={"g--2"}
                                                                                wrap={true}>
                                                                        <span>Ajouté le: </span>
                                                                        <strong>{categoryState.category?.createdAt ? capitalize(format(new Date(categoryState.category.createdAt), "EEEE d MMMM yyyy à HH:mm:ss", {locale: fr})) : "ND"}</strong>
                                                                    </GridLayout>
                                                                </div>
                                                            </GridLayout>
                                                        </div>
                                                    </button>
                                                </GridLayout>
                                            </div>

                                            <div className={"kubini-account-content"}>
                                                <div className={`kubini-account-activities is--full`}>
                                                    <h2>Sous-catégories</h2>
                                                    <div className='kubini-account-activities__list'>
                                                    {
                                                        categoryState.category?.subcategories
                                                            .filter((el:any) => el?.supprime === false)
                                                            .length > 0 ? (
                                                                <GridLayout direction={'fd--column'} justify={'jc--start'} align={'al--start'} gap='g--8'>
                                                                    {
                                                                        categoryState.category?.subcategories
                                                                        .filter((el:any) => el?.supprime === false)
                                                                        .map((subcategory: any, index: number) => (
                                                                            <div className='kubini-activity' key={index}>
                                                                                <i className="icon-align-layers"></i>
                                                                                <div className='kubini-activity__container'>
                                                                                    <strong>{subcategory.name}</strong>
                                                                                    <span>{`Ajouté le ${subcategory?.createdAt ? capitalize(format(new Date(subcategory.createdAt), "EEEE d MMMM yyyy à HH:mm:ss", {locale: fr})) : "ND"}`}</span>
                                                                                    <Link to={links.subcategory + subcategory?.slug} className='kubini-link'>Voir l'aperçu</Link>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </GridLayout>
                                                            ) : (
                                                                <strong>Aucune sous-catégorie réalisée</strong>
                                                            )
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                categoryState.category ? (
                                                    <ModalLayout
                                                        size={'is--md'}
                                                        isOpen={editCategoryModal}
                                                        id={'edit-modal'}
                                                        fnc={(value: boolean) => setEditCategoryModal(value)}
                                                        withClose={true}
                                                    >
                                                        <div className={'kubini-modal-form'}>
                                                            <div className={'kubini-modal-form__header'}>
                                                                <h2>Modification d'une catégorie</h2>
                                                            </div>
                                                            <EditModal item={categoryState.category} />
                                                        </div>
                                                    </ModalLayout>
                                                ) : null
                                            }
                                        </GridLayout>
                                    ) : null
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}