import {MainLayout} from "../../../../layout";
import {BackButton} from "../../../../components";
import {SettingMenu} from "../module/SettingModule";
import {useContext, useEffect, useState} from "react";
import {fonts, ThemeContext} from "../../../../context/ThemeContext";

const ConfigurationFontFamily = () => {
  const {
    fontFamily,
    changeFontFamily
  } = useContext(ThemeContext);
  return (
    <div className={"kubini-configuration__container"}>
      <div className={"kubini-configuration__header"}>
        <i className="icon-style"></i>
        <strong>Police d'ecriture</strong>
      </div>
      <div className={"kubini-configuration__content"}>
        <select
          className={"kubini-configuration__select"}
          value={fontFamily}
          onChange={(e) => changeFontFamily(e.target.value)}
        >
          {
            fonts.map((item, index) => (
              <option value={item.value} key={index}>
                {item.label}
              </option>
            ))
          }
        </select>
      </div>
    </div>
  )
}
const ConfigurationFontSize = () => {
  const {
    fontSize,
    changeFontSize
  } = useContext(ThemeContext);
  return (
    <div className={"kubini-configuration__container"}>
      <div className={"kubini-configuration__header"}>
        <i className="icon-font-size"></i>
        <strong>Taille : {fontSize}px</strong>
      </div>
      <div className={"kubini-configuration__content"}>
        <div className={"kubini-configuration__slider kubini-configuration-slider"}>
          <button
            type={"button"}
            className={`kubini-configuration-slider__btn ${parseInt(fontSize) > 8}`}
            onClick={() => {
              if (parseInt(fontSize) > 8) {
                changeFontSize(parseInt(fontSize) - 1)
              }
            }}
          >
            <i className="icon-zoom-out"></i>
          </button>
          <div className={"kubini-configuration-slider__slide"}>
            <input
              type="range"
              value={parseInt(fontSize)}
              min={8}
              max={20}
              onChange={(e) => changeFontSize(e.target?.value)}
            />
          </div>
          <button
            type={"button"}
            className={`kubini-configuration-slider__btn ${parseInt(fontSize) > 8}`}
            onClick={() => {
              if (parseInt(fontSize) < 20) {
                changeFontSize(parseInt(fontSize) + 1)
              }
            }}
          >
            <i className="icon-zoom-in"></i>
          </button>
        </div>
      </div>
    </div>
  )
}
const ConfigurationColorScheme = () => {
  const {
    theme,
    defaultDark,
    defaultTheme,
    colorPrimary,
    switchPrimaryColor
  } = useContext(ThemeContext);

  const [schemes, setSchemes] = useState<any[]>([])

  useEffect(() => {
    setSchemes([
      {
        id: "default",
        color: "is--default"
      },
      {
        id: "blue",
        color: "is--blue"
      },
      {
        id: "gold",
        color: "is--gold"
      },
      {
        id: "black",
        color: "is--"+(defaultTheme === "ok" ? (defaultDark ? "white" : "black") : (theme ? "white" : "black")),
      },
      {
        id: "orange",
        color: "is--orange"
      },
      {
        id: "green",
        color: "is--green"
      },
      {
        id: "violet",
        color: "is--violet"
      }
    ])

  }, [defaultTheme, defaultDark, theme]);

 
  return (
    <div className={"kubini-configuration__container"}>
      <div className={"kubini-configuration__header"}>
        <i className="icon-edit-brush"></i>
        <strong>Schema de couleurs</strong>
      </div>
      <div className={"kubini-configuration__content"}>
        <div className={"kubini-configuration-colors"}>
          {
            schemes.map((item, index) => (
              <button
                type={"button"}
                className={`kubini-configuration-color ${colorPrimary === item.id ? "is--active" : ""}`}
                key={index}
                onClick={() => switchPrimaryColor(item.id)}
              >
                <div className={`kubini-configuration-color__container ${item.color}`}></div>
              </button>
            ))
          }

        </div>
      </div>
    </div>
  )
}
const ConfigurationBorder = () => {
  const {
    bordure,
    switchBordure,
  } = useContext(ThemeContext);
  return (
    <div className={"kubini-configuration__container"}>
      <div className={"kubini-configuration__header"}>
        <i className="icon-photo-focus-points"></i>
        <strong>Bordures</strong>
      </div>
      <div className={"kubini-configuration__content"}>
        <div className={"kubini-configuration-borders"}>
          <button
            type={"button"}
            className={`kubini-configuration-border ${bordure === "default" ? "is--active" : ""}`}
            onClick={() => switchBordure("default")}
          >
            <div className={`kubini-configuration-border-content is--arrondi`}></div>
          </button>
          <button
            type={"button"}
            className={`kubini-configuration-border ${bordure === "arrondi" ? "is--active" : ""}`}
            onClick={() => switchBordure("arrondi")}
          >
            <div className={`kubini-configuration-border-content is--default`}></div>
          </button>
        </div>
      </div>
    </div>
  )
}
const ConfigurationAligns = () => {
  const {
    textPosition,
    switchTextPosition,
  } = useContext(ThemeContext);
  return (
    <div className={"kubini-configuration__container"}>
      <div className={"kubini-configuration__header"}>
        <i className={textPosition === "default" ? "icon-align-horizontal-left" : textPosition === "center" ? "icon-align-horizontal-center" : "icon-align-horizontal-right"}></i>
        <strong>Alignements</strong>
      </div>
      <div className={"kubini-configuration__content"}>
        <div className={"kubini-configuration-aligns"}>
          <button
            type={"button"}
            className={`kubini-configuration-align ${textPosition === "default" ? "is--active" : ""}`}
            onClick={() => switchTextPosition("default")}
          >
            <i className="icon-align-horizontal-left"></i>
            <span>Gauche</span>
          </button>
          <button
            type={"button"}
            className={`kubini-configuration-align ${textPosition === "center" ? "is--active" : ""}`}
            onClick={() => switchTextPosition("center")}
          >
            <i className="icon-align-horizontal-center"></i>
            <span>Centre</span>
          </button>
          <button
            type={"button"}
            className={`kubini-configuration-align ${textPosition === "right" ? "is--active" : ""}`}
            onClick={() => switchTextPosition("right")}
          >
            <i className="icon-align-horizontal-right"></i>
            <span>Droit</span>
          </button>
        </div>
      </div>
    </div>
  )
}
const ConfigurationTheme = () => {
  const {
    theme,
    defaultDark,
    defaultTheme,
    swichtDefautTheme,
    switchTheme,
  } = useContext(ThemeContext);
  return (
    <div className={"kubini-configuration__container"}>
      <div className={"kubini-configuration__header"}>
        <i className={defaultTheme === "ok" ? (defaultDark ? "icon-moon" : "icon-weather-sun-1") : (theme === "dark" ? "icon-moon" : "icon-weather-sun-1")}></i>
        <strong>{defaultTheme === "ok" ? (defaultDark ? "Il fait nuit" : "Il fait jour") : (theme === "dark" ? "Thème Sombre" : "Thème Clair")}</strong>
      </div>
      <div className={"kubini-configuration__content"}>
        <div className={"kubini-configuration__theme kubini-configuration-theme"}>
          <button type={"button"} className={"kubini-configuration-theme-switch"} onClick={switchTheme}>
            <div
              className={`kubini-configuration-theme-switch__content ${defaultTheme === "ok" ? (defaultDark ? "is--active" : "") : (theme === "dark" ? "is--active" : "")}`}>
              <i
                className={defaultTheme === "ok" ? (defaultDark ? "icon-moon" : "icon-weather-sun-1") : (theme === "dark" ? "icon-moon" : "icon-weather-sun-1")}></i>
            </div>
          </button>
          <button type={"button"} className={"kubini-configuration-theme-default"} onClick={swichtDefautTheme}>
          <span>Par theme</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export const SettingConfigurationPage = () => {

  return (
    <MainLayout here={"setting"} here_menu={"custom"} title={"Configuration"}>
     <div className={"kubini-page2"}>
       <BackButton/>
       <div className={"kubini-page2__container"}>
         <div className={"kubini-page2__side"}>
           <SettingMenu here={"custom"}/>
         </div>
         <div className={"kubini-page2__content"}>
           <div className={"kubini-page2__header"}>
             <h2>Configuration</h2>
           </div>
           <div className={"kubini-page2__list"}>
             <div className={"kubini-configurations"}>
               <div className={"kubini-configuration"}>
                 <ConfigurationFontFamily/>
               </div>
               <div className={"kubini-configuration"}>
                 <ConfigurationFontSize/>
               </div>
               <div className={"kubini-configuration"}>
                 <ConfigurationAligns/>
               </div>
               <div className={"kubini-configuration"}>
                 <ConfigurationBorder/>
               </div>
               <div className={"kubini-configuration"}>
                 <ConfigurationColorScheme/>
               </div>
               <div className={"kubini-configuration"}>
                 <ConfigurationTheme/>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
    </MainLayout>
  )
}
