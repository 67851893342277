

export const LogoIcon = () => {
  return (
      <svg width="63" height="74" viewBox="0 0 63 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id={"fill_color"} d="M37.5695 25.4435C37.6284 25.5664 37.6774 25.6795 37.7216 25.7877L38.7025 28.246C38.9954 28.9756 39.1437 29.7555 39.139 30.542C39.1356 31.36 38.993 32.1715 38.7172 32.9413C38.2486 34.2366 37.4999 35.4119 36.5248 36.383C34.3758 38.5217 31.4751 39.7293 28.4469 39.7459C26.8071 39.7646 25.1914 39.349 23.763 38.5414C23.1318 38.1763 22.561 37.7152 22.0709 37.1745C21.61 36.6501 21.2432 36.0496 20.987 35.3996L20.006 32.9413C19.9668 32.8381 19.9227 32.7201 19.8785 32.5923L16.0431 33.9001C16.1019 34.0722 16.1706 34.259 16.2491 34.4557L17.23 36.914C17.6716 37.9948 18.2936 38.9924 19.0692 39.8639C20.2243 41.1573 21.6608 42.1666 23.2676 42.8139C24.9135 43.4783 26.6726 43.8156 28.4469 43.8071C30.4175 43.8025 32.3689 43.4186 34.1951 42.6762C36.7803 41.655 39.0342 39.9384 40.7085 37.7154C41.487 36.6748 42.1054 35.5229 42.5428 34.2983C42.9736 33.0946 43.1958 31.8258 43.2 30.5469C43.2062 29.2417 42.9597 27.9477 42.4742 26.7366L41.4932 24.2783C41.4148 24.0865 41.3363 23.9046 41.2578 23.7374L37.5695 25.4435Z" fill="black"/>
            <path id={"fill_color"} d="M13.1493 36.147H13.3799C13.6937 36.147 14.3068 36.147 15.0818 36.0733C15.6654 36.0241 16.3423 35.9454 17.0436 35.8127C18.2137 35.6109 19.3496 35.2452 20.418 34.7261C21.0173 34.4378 21.5737 34.0671 22.0709 33.6248C22.6014 33.1386 23.0285 32.5499 23.3264 31.8942L19.6185 30.2274C19.5533 30.364 19.4635 30.4873 19.3537 30.5912C19.0038 30.9094 18.5934 31.1536 18.1472 31.3091C17.2551 31.6437 16.3237 31.8618 15.376 31.9581C14.7136 32.0391 14.0471 32.0818 13.3799 32.0859H13.2425L13.1395 36.1421L13.1493 36.147Z" fill="black"/>
            <path id={"fill_color"} d="M35.0435 29.0081C35.0828 29.0081 35.534 28.9245 36.255 28.7475C36.7896 28.6148 37.4517 28.4329 38.1776 28.1772C39.3957 27.7737 40.5513 27.2011 41.6109 26.476C42.2002 26.0621 42.7272 25.5654 43.1754 25.0011C43.6722 24.3811 44.0271 23.6594 44.2152 22.8869L40.2915 21.9528C40.2471 22.1186 40.1704 22.274 40.0659 22.41C39.923 22.5933 39.7583 22.7584 39.5754 22.9017C39.1249 23.2614 38.6304 23.562 38.104 23.7965C37.2858 24.1735 36.4356 24.4763 35.5634 24.7011C35.2005 24.7946 34.8915 24.8634 34.6806 24.9076L34.4452 24.9568H34.3912L34.6021 26.2155L34.3912 24.9568L34.6021 26.2155L34.3912 24.9568L35.0435 29.0081Z" fill="black"/>
            <path id={"fill_color"} d="M16.102 16.1708L15.6115 13.9338C15.1567 11.8311 15.5496 9.63356 16.7045 7.82012C17.8594 6.00668 19.6826 4.72443 21.7766 4.25293C22.9531 3.99153 24.173 3.9976 25.3468 4.27071C26.5206 4.54382 27.6184 5.07702 28.5597 5.83117C30.0199 6.98656 31.037 8.61229 31.4387 10.4331L31.7281 11.7508L35.6861 10.8707L35.3968 9.55797C34.7013 6.40184 32.7837 3.65188 30.0657 1.91294C27.3478 0.174008 24.0521 -0.411492 20.9036 0.285224C18.1752 0.888026 15.7388 2.41841 14.0077 4.61676C12.8821 6.03229 12.0862 7.68198 11.678 9.44533C11.2699 11.2087 11.2598 13.0412 11.6485 14.8089L12.139 17.0509L16.097 16.1708H16.102Z" fill="black"/>
            <path id={"fill_color"} d="M57.497 55.4496C55.7764 58.3608 52.9851 60.4792 49.7232 61.3496L26.1515 67.7412C24.1724 68.2824 22.0933 68.344 20.0858 67.9209C18.0783 67.4977 16.2 66.6019 14.606 65.3074C12.2466 63.4082 10.6271 60.739 10.03 57.7653L7.1951 58.3455L10.0545 57.9178L5.88556 30.0307C5.82156 29.5894 5.78878 29.1442 5.78747 28.6983C5.79132 26.7384 6.43764 24.8342 7.62694 23.2786C8.81624 21.723 10.4826 20.6024 12.3695 20.0893L39.5215 12.739C41.5084 12.2025 43.6242 12.4397 45.4438 13.4028C47.2635 14.3659 48.6521 15.9836 49.3308 17.9309L57.5264 41.4373L62.9902 39.5247L54.8044 16.0183C53.6452 12.683 51.2678 9.91226 48.1514 8.2645C45.035 6.61674 41.4114 6.21453 38.0109 7.13893L10.8638 14.5139C7.75322 15.3592 5.00559 17.2049 3.04295 19.7675C1.08032 22.33 0.0112583 25.4676 0 28.6983C0.000768665 29.4322 0.0548627 30.1651 0.161853 30.8911L4.3406 58.8519V58.9257C4.84922 61.4499 5.8697 63.8424 7.33876 65.955C8.80783 68.0675 10.6942 69.855 12.8808 71.2066C15.0674 72.5582 17.5077 73.4451 20.0504 73.8123C22.593 74.1795 25.1838 74.0191 27.6621 73.3412L51.2289 66.9496C55.9452 65.6816 59.9797 62.6148 62.4703 58.4045L57.497 55.4545V55.4496Z" fill="black"/> 
      </svg>
  );
};

export const LogoWrapIcon = () => {
    return (
      <svg width="63" height="74" viewBox="0 0 63 74" fill="none" xmlns="http://www.w3.org/2000/svg">
     
            <path id={"fill_color"} d="M37.5695 25.4435C37.6284 25.5664 37.6774 25.6795 37.7216 25.7877L38.7025 28.246C38.9954 28.9756 39.1437 29.7555 39.139 30.542C39.1356 31.36 38.993 32.1715 38.7172 32.9413C38.2486 34.2366 37.4999 35.4119 36.5248 36.383C34.3758 38.5217 31.4751 39.7293 28.4469 39.7459C26.8071 39.7646 25.1914 39.349 23.763 38.5414C23.1318 38.1763 22.561 37.7152 22.0709 37.1745C21.61 36.6501 21.2432 36.0496 20.987 35.3996L20.006 32.9413C19.9668 32.8381 19.9227 32.7201 19.8785 32.5923L16.0431 33.9001C16.1019 34.0722 16.1706 34.259 16.2491 34.4557L17.23 36.914C17.6716 37.9948 18.2936 38.9924 19.0692 39.8639C20.2243 41.1573 21.6608 42.1666 23.2676 42.8139C24.9135 43.4783 26.6726 43.8156 28.4469 43.8071C30.4175 43.8025 32.3689 43.4186 34.1951 42.6762C36.7803 41.655 39.0342 39.9384 40.7085 37.7154C41.487 36.6748 42.1054 35.5229 42.5428 34.2983C42.9736 33.0946 43.1958 31.8258 43.2 30.5469C43.2062 29.2417 42.9597 27.9477 42.4742 26.7366L41.4932 24.2783C41.4148 24.0865 41.3363 23.9046 41.2578 23.7374L37.5695 25.4435Z" fill="black"/>
            <path id={"fill_color"} d="M13.1493 36.147H13.3799C13.6937 36.147 14.3068 36.147 15.0818 36.0733C15.6654 36.0241 16.3423 35.9454 17.0436 35.8127C18.2137 35.6109 19.3496 35.2452 20.418 34.7261C21.0173 34.4378 21.5737 34.0671 22.0709 33.6248C22.6014 33.1386 23.0285 32.5499 23.3264 31.8942L19.6185 30.2274C19.5533 30.364 19.4635 30.4873 19.3537 30.5912C19.0038 30.9094 18.5934 31.1536 18.1472 31.3091C17.2551 31.6437 16.3237 31.8618 15.376 31.9581C14.7136 32.0391 14.0471 32.0818 13.3799 32.0859H13.2425L13.1395 36.1421L13.1493 36.147Z" fill="black"/>
            <path id={"fill_color"} d="M35.0435 29.0081C35.0828 29.0081 35.534 28.9245 36.255 28.7475C36.7896 28.6148 37.4517 28.4329 38.1776 28.1772C39.3957 27.7737 40.5513 27.2011 41.6109 26.476C42.2002 26.0621 42.7272 25.5654 43.1754 25.0011C43.6722 24.3811 44.0271 23.6594 44.2152 22.8869L40.2915 21.9528C40.2471 22.1186 40.1704 22.274 40.0659 22.41C39.923 22.5933 39.7583 22.7584 39.5754 22.9017C39.1249 23.2614 38.6304 23.562 38.104 23.7965C37.2858 24.1735 36.4356 24.4763 35.5634 24.7011C35.2005 24.7946 34.8915 24.8634 34.6806 24.9076L34.4452 24.9568H34.3912L34.6021 26.2155L34.3912 24.9568L34.6021 26.2155L34.3912 24.9568L35.0435 29.0081Z" fill="black"/>
            <path id={"fill_color"} d="M16.102 16.1708L15.6115 13.9338C15.1567 11.8311 15.5496 9.63356 16.7045 7.82012C17.8594 6.00668 19.6826 4.72443 21.7766 4.25293C22.9531 3.99153 24.173 3.9976 25.3468 4.27071C26.5206 4.54382 27.6184 5.07702 28.5597 5.83117C30.0199 6.98656 31.037 8.61229 31.4387 10.4331L31.7281 11.7508L35.6861 10.8707L35.3968 9.55797C34.7013 6.40184 32.7837 3.65188 30.0657 1.91294C27.3478 0.174008 24.0521 -0.411492 20.9036 0.285224C18.1752 0.888026 15.7388 2.41841 14.0077 4.61676C12.8821 6.03229 12.0862 7.68198 11.678 9.44533C11.2699 11.2087 11.2598 13.0412 11.6485 14.8089L12.139 17.0509L16.097 16.1708H16.102Z" fill="black"/>
            <path d="M57.497 55.4496C55.7764 58.3608 52.9851 60.4792 49.7232 61.3496L26.1515 67.7412C24.1724 68.2824 22.0933 68.344 20.0858 67.9209C18.0783 67.4977 16.2 66.6019 14.606 65.3074C12.2466 63.4082 10.6271 60.739 10.03 57.7653L7.1951 58.3455L10.0545 57.9178L5.88556 30.0307C5.82156 29.5894 5.78878 29.1442 5.78747 28.6983C5.79132 26.7384 6.43764 24.8342 7.62694 23.2786C8.81624 21.723 10.4826 20.6024 12.3695 20.0893L39.5215 12.739C41.5084 12.2025 43.6242 12.4397 45.4438 13.4028C47.2635 14.3659 48.6521 15.9836 49.3308 17.9309L57.5264 41.4373L62.9902 39.5247L54.8044 16.0183C53.6452 12.683 51.2678 9.91226 48.1514 8.2645C45.035 6.61674 41.4114 6.21453 38.0109 7.13893L10.8638 14.5139C7.75322 15.3592 5.00559 17.2049 3.04295 19.7675C1.08032 22.33 0.0112583 25.4676 0 28.6983C0.000768665 29.4322 0.0548627 30.1651 0.161853 30.8911L4.3406 58.8519V58.9257C4.84922 61.4499 5.8697 63.8424 7.33876 65.955C8.80783 68.0675 10.6942 69.855 12.8808 71.2066C15.0674 72.5582 17.5077 73.4451 20.0504 73.8123C22.593 74.1795 25.1838 74.0191 27.6621 73.3412L51.2289 66.9496C55.9452 65.6816 59.9797 62.6148 62.4703 58.4045L57.497 55.4545V55.4496Z" fill="#FF971F"/>
      </svg>
    );
};
