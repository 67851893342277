import React, { FormEvent, useEffect, useState } from 'react';
import { AlertModule, BackButton, Button, GridLayout, ModalLayout, Textfield } from '../../../../components';
import { getSubCategory, reset, updateSubCategory } from '../../../../../redux/feature/subcategory/subcategory.slice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MainLayout } from '../../../../layout';
import PlatformMenu from '../../platform/module/PlatformMenu';
import { capitalize, formatNumber2 } from '../../../../../utils/function';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { links } from '../../../../routes';

const EditModal = ({item}:{item: any}) => {
    const [data, setData] = useState<any>({
        name: "",
        id: item?._id
    })
    const dispatch = useAppDispatch();
    const { isEditLoading, isEditSuccess, isEditError, message } = useAppSelector((state) => state.subcategory);

    const [error, setError] = useState<any>({
        field: "",
        message: ""
    })
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if(!data.name){
            setError({
                field: "name",
                message: "Veuillez renseigner ce champs"
            })
            return
        }

        setError(null)

        dispatch(updateSubCategory(data))
    }
  
    return (
        <>
            {
                isEditSuccess ? (
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon is--success"}>
                                <i className="icon-validation-check"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Modification de la sous-catégorie</h2>
                                <p>Les informations a été modifiées avec succès</p>
                                <div className={"kubini-modal-form-status__action"}>
                                    <Button
                                        btnType={"button"}
                                        label={"Fermer..."}
                                        btnStyle={"is--neutral"}
                                        active={true}
                                        withIconLeft={false}
                                        onClick={() => {
                                            window.location.reload()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <form className={"kubini-modal-form__container"} onSubmit={handleSubmit}>
                        <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--10"}>
                            {
                                isEditError ? (
                                    <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
                                ) : null
                            }
                            <div className={"kubini-modal-form__fields"}>
                                <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--8"}>
                                    <div className={"kubini-modal-form__field"}>
                                        <Textfield
                                            id={"name"}
                                            value={data.name}
                                            require={true}
                                            changeValue={(value: any) => setData({...data, name: value})}
                                            type={"text"}
                                            label={"Nom"}
                                            placeholder={"ex: Vêtement femme"}
                                            error={error}
                                        />
                                    </div>
                                </GridLayout>
                            </div>
                            <div className={"kubini-modal-form__action"}>
                                <Button btnType={"submit"} label={isEditLoading ? "Modification en cours..." : "Enregistrer les modifications"} btnStyle={"is--primary"} active={!isEditLoading} withIconLeft={true} iconLeftClass={isEditLoading ? "icon-spin" :"icon-storage-floppy-disk"}/>
                            </div>
                        </GridLayout>
                    </form>
                )
            }
        </>
    )
}

export const SubcategoryPage = () => {
    const {slug} = useParams()
    const [editCategoryModal, setEditCategoryModal] = useState<boolean>(false)
    const dispatch = useAppDispatch();
    const { isLoading, isSuccess, isError, subcategory, message } = useAppSelector((state) => state.subcategory);
    let navigate = useNavigate()

    useEffect(() => {
        if(slug){
            dispatch(getSubCategory(slug))
        }
        return () => {
            dispatch(reset())
        }
    }, [dispatch, slug])
    
    return (
        <MainLayout title='Sous-catégorie' here='plateform' here_menu='subcategory'>
            <div className={"kubini-page2"}>
                <div><BackButton/></div>
                <div className={"kubini-page2__container"}>
                    <div className={"kubini-page2__side"}>
                        <PlatformMenu here={"subcategory"}/>
                    </div>
                    <div className={"kubini-page2__content"}>
                        {
                            isLoading ? (
                                <div className={"kubini-loader"}>
                                    <i className="icon-spin"/>
                                </div>
                            ) : (
                                isError ? (
                                    <div className={"kubini-page-error"}>
                                        <div className={"kubini-page-error__container"}>
                                            <i className="icon-delete-square"/>
                                            <div className={"kubini-page2-error__text"}>
                                                <h2>Oupss!!</h2>
                                                <p>{message}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    isSuccess && subcategory ? (
                                        <GridLayout direction={'fd--column'} justify={'jc--start'} gap={"g--8"} align={'al--start'} >
                                            <div className={"kubini-account-banner"}>
                                                <GridLayout direction={"fd--row"} wrap={true} justify={"jc--start"} align={"al--start"} gap={"g--10"}>
                                                    <div className={"kubini-account-avatar"}>
                                                        <div className={"kubini-account-avatar-form-image"}>
                                                            <i className="icon-align-layers"></i>
                                                        </div>
                                                    </div>

                                                    <button className={`kubini-account-information type-0`} type='button' onClick={() => setEditCategoryModal(true)}>
                                                        <div className={"kubini-account-information__icon"}>
                                                            <i className="icon-file-clipboard-text"></i>
                                                        </div>
                                                        <div className={"kubini-account-information__text kubini-account-information-text"}>
                                                            <GridLayout direction={"fd--column"}
                                                                        justify={"jc--start"} align={"al--start"}
                                                                        gap={"g--2"}>
                                                                <div className={"kubini-account-information-text__item"}>
                                                                    <GridLayout direction={"fd--row"}
                                                                                justify={"jc--between"}
                                                                                align={"al--start"} gap={"g--2"}
                                                                                wrap={true}>
                                                                        <span>Nom: </span>
                                                                        <strong>{subcategory?.name}</strong>
                                                                    </GridLayout>
                                                                </div>
                                                                <div className={"kubini-account-information-text__item"}>
                                                                    <GridLayout direction={"fd--row"}
                                                                                justify={"jc--between"}
                                                                                align={"al--start"} gap={"g--2"}
                                                                                wrap={true}>
                                                                        <span>Catégorie: </span>
                                                                        <Link to={links.category + subcategory.category?.slug}>{subcategory.category?.name}</Link>
                                                                    </GridLayout>
                                                                </div>
                                                                <div className={"kubini-account-information-text__item"}>
                                                                    <GridLayout direction={"fd--row"}
                                                                                justify={"jc--between"}
                                                                                align={"al--start"} gap={"g--2"}
                                                                                wrap={true}>
                                                                        <span>Nombre d'articles: </span>
                                                                        <strong>{subcategory?.articles.length || "ND"}</strong>
                                                                    </GridLayout>
                                                                </div>
                                                                <div className={"kubini-account-information-text__item"}>
                                                                    <GridLayout direction={"fd--row"}
                                                                                justify={"jc--between"}
                                                                                align={"al--start"} gap={"g--2"}
                                                                                wrap={true}>
                                                                        <span>Ajouté le: </span>
                                                                        <strong>{subcategory?.createdAt ? capitalize(format(new Date(subcategory.createdAt), "EEEE d MMMM yyyy à HH:mm:ss", {locale: fr})) : "ND"}</strong>
                                                                    </GridLayout>
                                                                </div>
                                                            </GridLayout>
                                                        </div>
                                                    </button>
                                                </GridLayout>
                                            </div>

                                            <div className={"kubini-account-content"}>
                                                <div className={`kubini-account-activities is--full`}>
                                                    <h2>Articles</h2>
                                                    <div className='kubini-account-activities__list'>
                                                        {
                                                            subcategory?.articles
                                                            .filter((el:any) => el?.supprime === false)
                                                            .length > 0 ? (
                                                                <GridLayout direction={'fd--column'} justify={'jc--start'} align={'al--start'} gap='g--8'>
                                                                    {
                                                                        subcategory?.articles
                                                                            .filter((el:any) => el?.supprime === false)
                                                                            .map((article: any, index: number) => (
                                                                                <button type='button' className='kubini-article-item' key={index} onClick={() => navigate(links.article + article.slug)}>
                                                                                    <div className='kubini-article-item__avatar'>
                                                                                        <img src={article.image?.url} alt="article" />
                                                                                    </div>
                                                                                    <div className='kubini-article-item__info'>
                                                                                        <strong>{article.title}</strong>
                                                                                        <h2>{formatNumber2(article.price) + "XOF"}</h2>
                                                                                        <span>Proposé par {article.user?.auth?.fullname + " . @" + article.user?.auth?.username}</span>
                                                                                    </div>
                                                                                </button>
                                                                            ))
                                                                    }

                                                                    {
                                                                        subcategory ? (
                                                                            <ModalLayout
                                                                                size={'is--md'}
                                                                                isOpen={editCategoryModal}
                                                                                id={'edit-modal'}
                                                                                fnc={(value: boolean) => setEditCategoryModal(value)}
                                                                                withClose={true}
                                                                            >
                                                                                <div className={'kubini-modal-form'}>
                                                                                    <div className={'kubini-modal-form__header'}>
                                                                                        <h2>Modification d'une sous-catégorie</h2>
                                                                                    </div>
                                                                                    <EditModal item={subcategory} />
                                                                                </div>
                                                                            </ModalLayout>
                                                                        ) : null
                                                                    }
                                                                </GridLayout>
                                                            ): (
                                                                <strong>Aucun article</strong>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </GridLayout>
                                    ) : null
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}