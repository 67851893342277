import strSlug from "slug"

export const capitalize = (word: string) => {
  const lower = word.toLowerCase()
  return lower.charAt(0).toUpperCase() + lower.slice(1)
}

export const titlePage = (title?: string) => {
  if(title){
    document.title = `${capitalize(title)} | Adminisatration Enka-Market`;
  }else {
    document.title ="Administration Enka-Market";
  }
}

export function truncate(str: string | string[], maxlength: number) {
  return (str.length > maxlength) ?
    str.slice(0, maxlength - 1) + '…' : str;
}

export const formatNumber = (data: number) => {
  let number = data

  let result = number / 1000000000

  if(result >= 1){
    return Math.round(result*100)/100 + "Md"
  }
  result = number / 1000000
  if(result >= 1){
    return Math.round(result*100)/100 + "M"
  }
  result = number / 1000
  if(result >= 1){
    return result + "k"
  }
  return number
}

export const formatNumber2 = (data: number) => {
  let number = data

  let result = number / 1000000000
  if(result >= 1){
    return Math.round(result*100)/100 + "Md"
  }
  result = number / 1000000
  if(result >= 1){
    return Math.round(result*100)/100 + "M"
  }
  return number
}

export const formatMyDate = (mdate: any) => {
  let now = Date.now()
  let day = new Date(mdate)
  // @ts-ignore
  var dif = now - day
  let jour = dif / (1000 * 3600 * 24)

  if(jour > 1){
    return "il y a " + Math.round(jour) + "jrs"
  }else{
    if(jour === 1){
      return "hier"
    }else{
      let heure = dif / (1000 * 3600)
      if(heure > 1){
        return "il y a " + Math.round(heure) + "h"
      }else{
        let min = dif / (1000 * 60)
        if(min > 1){
          return "il ya " + Math.round(min)+ "min"
        }else{
          let second = dif / 1000
          if(second > 1){
            return "il y a " + Math.round(second)+"s"
          }else{
            return "maintenant"
          }
        }
      }
    }
  }
}

export const fileSizeCalculate = (size: number) => {
  let sizeInBytes = size / 1000000
  if(sizeInBytes > 1){
    return Math.round(sizeInBytes) + "Mo"
  }else{
    return Math.round(sizeInBytes * 10) / 10 + "ko"
  }
}


export  const slugifyFileName = (name: string) => {
  let tabs: string[] = name.split('.')
  let nom: string = tabs[0]
  let ext: string = name.split('.').pop() || ""

  if(nom && ext){
      return strSlug(nom) + "."+ext
  }else{
      return name
  }
}

