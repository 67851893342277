import { AccountPage, AdminPage, AdminsPage, ArticlePage, ArticlesPage, CategoriesPage, CategoryPage, CitiesPage, ErrorPage, EventPage, EventsPage, FeedBackPage, FeedBacksPage, HomePage, LoginPage, PartnerPage, PlatformPage, PostPage, PostsPage, PubPage, PubsPage, SettingConfigurationPage, SettingHomePage, SettingInformationPage, SettingPasswordPage, SubcategoriesPage, SubcategoryPage, UserPage, UsersPage } from "../pages"

export const links = {
    home: "/",
    account: "/mon-compte",
    setting: "/configuration",
    setting_information: "/configuration/edit-info",
    setting_password: "/configuration/mot-de-passe",
    setting_custom: "/configuration/customisation",
    login: "/connexion",
    admins: "/admins",
    platform: "/plateforme",
    admin: "/admin/",
    pubs: "/pubs",
    pub: "/pub/",
    cities: "/villes",
    city: "/ville/",
    categories: "/categories",
    category: "/categorie/",
    subcategories: "/sous-categories",
    subcategory: "/sous-categorie/",
    users: "/utilisateurs",
    user: "/utilisateur/",
    articles: "/articles",
    article: "/article/",
    feedbacks: "/retours-clients",
    feedback: "/feedback/",
    events: "/evenements",
    event: "/evenement/",
    posts: "/publications",
    post: "/publication/",
    partners: "/partenaires",
    errors: "/*",
}

export const routes = [
    {
        path: links.platform,
        Component: PlatformPage
    },
    {
        path: links.partners,
        Component: PartnerPage
    },
    {
        path: links.setting,
        Component: SettingHomePage
    },
    {
        path: links.setting_information,
        Component: SettingInformationPage
    },
    {
        path: links.setting_password,
        Component: SettingPasswordPage
    },
    {
        path: links.setting_custom,
        Component: SettingConfigurationPage
    },
    {
        path: links.account,
        Component: AccountPage
    },
    {
        path: links.login,
        Component: LoginPage
    },
    {
        path: links.home,
        Component: HomePage
    },
    {
        path: links.admins,
        Component: AdminsPage
    },
    {
        path: links.admin + ":username",
        Component: AdminPage
    },
    {
        path: links.feedbacks,
        Component: FeedBacksPage
    },
    {
        path: links.feedback + ":slug",
        Component: FeedBackPage
    },
    {
        path: links.cities,
        Component: CitiesPage
    },
    {
        path: links.users,
        Component: UsersPage
    },
    {
        path: links.user + ":username",
        Component: UserPage
    },
    {
        path: links.pubs,
        Component: PubsPage
    },
    {
        path: links.pub + ":slug",
        Component: PubPage
    },
    {
        path: links.categories,
        Component: CategoriesPage
    },
    {
        path: links.category + ":slug",
        Component: CategoryPage
    },
    {
        path: links.subcategories,
        Component: SubcategoriesPage
    },
    {
        path: links.subcategory + ":slug",
        Component: SubcategoryPage
    },
    {
        path: links.articles,
        Component: ArticlesPage
    },
    {
        path: links.article + ":slug",
        Component: ArticlePage
    },
    {
        path: links.events,
        Component: EventsPage
    },
    {
        path: links.event + ":slug",
        Component: EventPage
    },
    {
        path: links.posts,
        Component: PostsPage
    },
    {
        path: links.post + ":slug",
        Component: PostPage
    },
    {
        path: links.errors,
        Component: ErrorPage
    },
]