import React, { FormEvent, useEffect, useState } from 'react';
import { deleteSubCategory, getCategoriesOption, getSubCategories, reset, setSubCategory, updateSubCategory } from '../../../../../redux/feature/subcategory/subcategory.slice';
import { AlertModule, BackButton, Button, GridLayout, ModalLayout, Selectfield, Textfield } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { MainLayout } from '../../../../layout';
import { links } from '../../../../routes';
import { format } from 'date-fns';
import { capitalize } from '../../../../../utils/function';
import { fr } from 'date-fns/locale';
import PlatformMenu from '../../platform/module/PlatformMenu';
import { useNavigate } from 'react-router-dom';

const AddModal = ({categories}:{categories: any[]}) => {
    const [data, setData] = useState<any>({
        name: "",
        categoryId: ""
    })
    const dispatch = useAppDispatch();
    const { isCreateLoading, isCreateSuccess, isCreateError, message } = useAppSelector((state) => state.subcategory);

    const [error, setError] = useState<any>({
        field: "",
        message: ""
    })
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if(!data.name){
          setError({
              field: "name",
              message: "Veuillez renseigner ce champs"
          })
          return
      }
      if(!data.categoryId){
        setError({
            field: "categoryId",
            message: "Veuillez renseigner ce champs"
        })
        return
    }

    setError(null)

    dispatch(setSubCategory(data))
    }
  
    return (
        <>
            {
                isCreateSuccess ? (
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon is--success"}>
                                <i className="icon-validation-check"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Création de la sous-catégorie</h2>
                                <p>La sous-catégorie a été créée avec succès</p>
                                <div className={"kubini-modal-form-status__action"}>
                                    <Button
                                        btnType={"button"}
                                        label={"Fermer..."}
                                        btnStyle={"is--neutral"}
                                        active={true}
                                        withIconLeft={false}
                                        onClick={() => {
                                            window.location.reload()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <form className={"kubini-modal-form__container"} onSubmit={handleSubmit}>
                        <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--10"}>
                            {
                                isCreateError ? (
                                    <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
                                ) : null
                            }
                            <div className={"kubini-modal-form__fields"}>
                                <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--8"}>
                                    <div className={"kubini-modal-form__field"}>
                                        <Textfield
                                            id={"name"}
                                            value={data.name}
                                            require={true}
                                            changeValue={(value: any) => setData({...data, name: value})}
                                            type={"text"}
                                            label={"Nom"}
                                            placeholder={"ex: vêtements homme"}
                                            error={error}
                                        />
                                    </div>
                                    <div className={"kubini-modal-form__field"}>
                                        <Selectfield
                                            id={"categoryId"}
                                            value={data.categoryId}
                                            require={true}
                                            changeValue={(value: any) => setData({...data, categoryId: value})}
                                            tabs={categories}
                                            label={"Catégorie"}
                                            placeholder={"ex: choisir la catégorie"}
                                            error={error}
                                        />
                                    </div>
                                </GridLayout>
                            </div>
                            <div className={"kubini-modal-form__action"}>
                                <Button btnType={"submit"} label={isCreateLoading ? "Création en cours..." : "Ajouter la sous-catégorie"} btnStyle={"is--primary"} active={!isCreateLoading} withIconLeft={true} iconLeftClass={isCreateLoading ? "icon-spin" :"icon-storage-floppy-disk"}/>
                            </div>
                        </GridLayout>
                    </form>
                )
            }
        </>
    )
}

const EditModal = ({item}:{item: any}) => {
    const [data, setData] = useState<any>({
        name: "",
        id: item?._id
    })
    const dispatch = useAppDispatch();
    const { isEditLoading, isEditSuccess, isEditError, message } = useAppSelector((state) => state.subcategory);

    const [error, setError] = useState<any>({
        field: "",
        message: ""
    })
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if(!data.name){
            setError({
                field: "name",
                message: "Veuillez renseigner ce champs"
            })
            return
        }

        setError(null)

        dispatch(updateSubCategory(data))
    }
  
    return (
        <>
            {
                isEditSuccess ? (
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon is--success"}>
                                <i className="icon-validation-check"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Modification de la sous-catégorie</h2>
                                <p>Les informations a été modifiées avec succès</p>
                                <div className={"kubini-modal-form-status__action"}>
                                    <Button
                                        btnType={"button"}
                                        label={"Fermer..."}
                                        btnStyle={"is--neutral"}
                                        active={true}
                                        withIconLeft={false}
                                        onClick={() => {
                                            window.location.reload()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <form className={"kubini-modal-form__container"} onSubmit={handleSubmit}>
                        <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--10"}>
                            {
                                isEditError ? (
                                    <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
                                ) : null
                            }
                            <div className={"kubini-modal-form__fields"}>
                                <GridLayout direction={"fd--column"} justify={"jc--start"} align={"al--start"} gap={"g--8"}>
                                    <div className={"kubini-modal-form__field"}>
                                        <Textfield
                                            id={"name"}
                                            value={data.name}
                                            require={true}
                                            changeValue={(value: any) => setData({...data, name: value})}
                                            type={"text"}
                                            label={"Nom"}
                                            placeholder={"ex: Vêtement femme"}
                                            error={error}
                                        />
                                    </div>
                                </GridLayout>
                            </div>
                            <div className={"kubini-modal-form__action"}>
                                <Button btnType={"submit"} label={isEditLoading ? "Modification en cours..." : "Enregistrer les modifications"} btnStyle={"is--primary"} active={!isEditLoading} withIconLeft={true} iconLeftClass={isEditLoading ? "icon-spin" :"icon-storage-floppy-disk"}/>
                            </div>
                        </GridLayout>
                    </form>
                )
            }
        </>
    )
}

const DeleteModal = ({item}: { item: any }) => {
    const dispatch = useAppDispatch();
    const {isDeleteLoading, isDeleteError, isDeleteSuccess, message } = useAppSelector((state) => state.subcategory);
    return (
        <>
            {
                isDeleteSuccess ? (
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon is--success"}>
                                <i className="icon-validation-check"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Suppression d'une sous-catégorie</h2>
                                <p>L'élément a bien été supprimé avec succès</p>
                                <div className={"kubini-modal-form-status__action"}>
                                    <Button
                                        btnType={"button"}
                                        label={ "Fermer..."}
                                        btnStyle={"is--neutral"}
                                        active={true}
                                        withIconLeft={false}
                                        onClick={() => {
                                            window.location.reload()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={"kubini-modal-form-status"}>
                        <div className={"kubini-modal-form-status__container"}>
                            <div className={"kubini-modal-form-status__icon is--danger"}>
                                <i className="icon-delete-bin"/>
                            </div>
                            <div className={"kubini-modal-form-status__text"}>
                                <h2>Suppression d'une sous-catégorie</h2>
                                {
                                    isDeleteError ? (
                                        <p>
                                            <strong>Ouppss!!!</strong> <br/>
                                            <span>{message}</span>
                                        </p>
                                    ) : (
                                        <p>Souhaitez-vous vraiment supprimer cet élément</p>
                                    )
                                }

                                <div className={"kubini-modal-form-status__action"}>
                                    {
                                        isDeleteError ? (
                                            <Button
                                                btnType={"button"}
                                                label={isDeleteLoading ? "Suppression en cours" : "Réessayer..."}
                                                btnStyle={"is--danger"}
                                                active={!isDeleteLoading}
                                                withIconLeft={true}
                                                iconLeftClass={isDeleteLoading ? "icon-spin" : "icon-delete-bin"}
                                                onClick={() => {
                                                    dispatch(deleteSubCategory({id: item?._id}))
                                                }}
                                            />
                                        ) : (
                                            <Button
                                                btnType={"button"}
                                                label={isDeleteLoading ? "Suppression en cours" : "Confirmer la suppression"}
                                                btnStyle={"is--danger"}
                                                active={!isDeleteLoading}
                                                withIconLeft={true}
                                                iconLeftClass={isDeleteLoading ? "icon-spin" : "icon-delete-bin"}
                                                onClick={() => {
                                                    dispatch(deleteSubCategory({id: item?._id}))
                                                }}
                                            />
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export const SubcategoriesPage = () => {
    const [search, setSearch] = useState<string>("")
    const [numberItem, setNumberItem] = useState<number>(15)
    const [startList, setStartList] = useState<number>(1)
    const [addCategoryModal, setAddCategoryModal] = useState<boolean>(false)
    const [editCategoryModal, setEditCategoryModal] = useState<boolean>(false)
    const [deleteCategoryModal, setDeleteCategoryModal] = useState<boolean>(false)
    const [itemCategoryModal, setItemCategoryModal] = useState<any>(null)
    const [chooseApercu, setChosseApercu] = useState<string>("grid")
    const dispatch = useAppDispatch();
    const { isLoading, isSuccess, isError, subcategories, categories, isCategoryLoading, isCategoryError, isCategorySuccess, message } = useAppSelector((state) => state.subcategory);
    let navigate = useNavigate()
    useEffect(() => {
        // @ts-ignore
        dispatch(getSubCategories())
        // @ts-ignore
        dispatch(getCategoriesOption())

        return () => {
            dispatch(reset())
        }
    }, [dispatch])

    return (
        <MainLayout title='Sous-catégorie' here='plateform' here_menu='subcategory'>
            <div className={"kubini-page2"}>
                <BackButton/>
                <div className={"kubini-page2__container"}>
                    <div className={"kubini-page2__side"}>
                        <PlatformMenu here={"subcategory"}/>
                    </div>
                    <div className={"kubini-page2__content"}>
                        {
                            isLoading || isCategoryLoading ? (
                                <div className={"kubini-loader"}>
                                    <i className="icon-spin"/>
                                </div>
                            ) : (
                                isError || isCategoryError ? (
                                    <div className={"kubini-page-error"}>
                                        <div className={"kubini-page-error__container"}>
                                            <i className="icon-delete-square"/>
                                            <div className={"kubini-page2-error__text"}>
                                                <h2>Oupss!!</h2>
                                                <p>{message}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    isSuccess && isCategorySuccess && subcategories ? (
                                        <>
                                            <div className={"kubini-page2__header"}>
                                                <h2>Les sous-catégories</h2>
                                            </div>
                                            <div className={"kubini-page2__searchActions kubini-page2-searchActions"}>
                                                <GridLayout direction={"fd--row"} gap={"g--6"} justify={"jc--start"} align={"al--start"} wrap={true}>
                                                    <div className={"kubini-page2-actions"}>
                                                        <GridLayout direction={"fd--row"} gap={"g--6"} justify={"jc--start"} align={"al--start"} wrap={true}>
                                                            <Button
                                                                btnType={"button"}
                                                                label={"Ajouter une sous-catégorie"}
                                                                btnStyle={"is--primary"}
                                                                active={true}
                                                                withIconLeft={true}
                                                                iconLeftClass={"icon-add-square"}
                                                                onClick={() => setAddCategoryModal(true)}
                                                            />
                                                        </GridLayout>
                                                    </div>
                                                    <div className={"kubini-page2-search"}>
                                                        <i className="icon-search"/>
                                                        <input
                                                            type="search"
                                                            value={search}
                                                            onChange={(e) => setSearch(e.target.value)}
                                                            placeholder={"search..."}
                                                        />
                                                    </div>
                                                    <div className={"kubini-page2-searchItem"}>
                                                        <span>Nombre à afficher</span>
                                                        <input
                                                            type="number"
                                                            value={numberItem}
                                                            min={5}
                                                            onChange={(e) => setNumberItem(parseInt(e.target.value))}
                                                        />
                                                    </div>
                                                    <div className={"kubini-page2-searchItem"}>
                                                        <span>Commencer par</span>
                                                        <input
                                                            type="number"
                                                            min={0}
                                                            value={startList}
                                                            onChange={(e) => setStartList(parseInt(e.target.value))}
                                                        />
                                                    </div>
                                                </GridLayout>
                                            </div>
                                            <div className={'kubini-page2__list kubini-page2-list'}>
                                                <div className={'kubini-page2-list-head'}>
                                                    <div className={'kubini-page2-list-head__text'}>
                                                        <span>{subcategories.filter((el: any) => el.name?.toLowerCase().includes(search.toLowerCase())).length > 0} élément(s) trouvé(s)</span>
                                                    </div>
                                                    <div className={'kubini-page2-list-head-actions'}>
                                                        <button
                                                            type={'button'}
                                                            className={`kubini-page2-list-head-action ${chooseApercu === 'grid' ? 'is--active' : ''}`}
                                                            onClick={() => setChosseApercu('grid')}
                                                        >
                                                            <i className={'icon-dashboard'} />
                                                        </button>
                                                        <button
                                                            type={'button'}
                                                            className={`kubini-page2-list-head-action ${chooseApercu === 'list' ? 'is--active' : ''}`}
                                                            onClick={() => setChosseApercu('list')}
                                                        >
                                                            <i className={'icon-list-bullets'} />
                                                        </button>
                                                    </div>
                                                </div>
                                                {
                                                    subcategories
                                                        .filter((el: any) => el.name?.toLowerCase().includes(search.toLowerCase()))
                                                        .length > 0 ? (
                                                            <div className={'kubini-page2-list-container'}>
                                                                {
                                                                    chooseApercu === 'grid' ? (
                                                                        <div className={'kubini-page2-list-grid'}>
                                                                            <GridLayout
                                                                            direction={'fd--row'}
                                                                            wrap={true}
                                                                            justify={'jc--start'}
                                                                            align={'al--start'}
                                                                            gap={'g--4'}
                                                                            >
                                                                                {
                                                                                    subcategories
                                                                                        .filter((el: any) => el.name?.toLowerCase().includes(search.toLowerCase()))
                                                                                        .slice((startList - 1), (numberItem + (startList - 1)))
                                                                                        .map((item: any, index: any) => (
                                                                                            <div className={'kubini-page2-list-grid-item'} key={index}>
                                                                                                <div className={'kubini-category-card'}>
                                                                                                    <div className={'kubini-category-card__container'}>
                                                                                                        <div className={'kubini-category-card__icon'}>
                                                                                                            <i className="icon-align-layers" />
                                                                                                        </div>
                                                                                                        <div className={'kubini-category-card__text'}>
                                                                                                        <h2>{item.name}</h2>
                                                                                                        <span>{item.category?.name}</span>
                                                                                                        <p>
                                                                                                            Ajouter le {item.createdAt ? capitalize(format(new Date(item.createdAt), "EEEE d MMMM yyyy à HH:mm:ss", {locale: fr})) : "ND"}
                                                                                                        </p>
                                                                                                        <div className={'kubini-category-card__actions'}>
                                                                                                            <button
                                                                                                            type={'button'}
                                                                                                            className={'kubini-category-card__action'}
                                                                                                            onClick={() => {
                                                                                                                navigate(links.subcategory + item.slug)
                                                                                                            }}
                                                                                                            >
                                                                                                                <i className="icon-view" /> <span>Voir</span>
                                                                                                            </button>
                                                                                                            <button
                                                                                                            type={'button'}
                                                                                                            className={'kubini-category-card__action'}
                                                                                                            onClick={() => {
                                                                                                                setItemCategoryModal(null)
                                                                                                                setItemCategoryModal(item)
                                                                                                                setEditCategoryModal(true)
                                                                                                            }}
                                                                                                            >
                                                                                                                <i className="icon-edit-write-2" /> <span>Modifier</span>
                                                                                                            </button>
                                                                                                            <button
                                                                                                                type={'button'}
                                                                                                                className={'kubini-category-card__action is--danger'}
                                                                                                                onClick={() => {
                                                                                                                    setItemCategoryModal(null)
                                                                                                                    setItemCategoryModal(item)
                                                                                                                    setDeleteCategoryModal(true)
                                                                                                                }}
                                                                                                            >
                                                                                                                <i className="icon-delete-bin" /> <span>Supprimer</span>
                                                                                                            </button>
                                                                                                        </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                        ))
                                                                                }
                                                                            </GridLayout>
                                                                        </div>
                                                                    ) : (
                                                                        <div className={'kubini-page2-list-list'}>
                                                                          <div className={'kubini-page2-list-list__container'}>
                                                                            <div className={'kubini-page2-list-list-header'}>
                                                                              <GridLayout
                                                                                direction={'fd--row'}
                                                                                justify={'jc--start'}
                                                                                align={'al--start'}
                                                                                gap={'g--0'}
                                                                              >
                                                                                <div className={'kubini-page2-list-list-header-id'}>
                                                                                  <strong>#</strong>
                                                                                </div>
                                                                                <div className={'kubini-page2-list-list-header-large'}>
                                                                                  <strong>Nom</strong>
                                                                                </div>
                                                                                <div className={'kubini-page2-list-list-header-large'}>
                                                                                  <strong>Catégorie</strong>
                                                                                </div>
                                                                                <div className={'kubini-page2-list-list-header-mid'}>
                                                                                  <strong>Ajouté le</strong>
                                                                                </div>
                                                                                <div className={'kubini-page2-list-list-header-actions'}>
                                                                                  <strong>Actions</strong>
                                                                                </div>
                                                                              </GridLayout>
                                                                            </div>
                                                                            <div className={'kubini-page2-list-list-items'}>
                                                                              <GridLayout
                                                                                direction={'fd--column'}
                                                                                justify={'jc--start'}
                                                                                align={'al--start'}
                                                                                gap={'g--0'}
                                                                                wrap={true}
                                                                              >
                                                                                {
                                                                                    subcategories
                                                                                        .filter((el: any) => el.name?.toLowerCase().includes(search.toLowerCase()))
                                                                                        .slice((startList - 1), (numberItem + (startList - 1)))
                                                                                        .map((item: any, index: any) => (
                                                                                            <div className={'kubini-page2-list-list-item'} key={index}>
                                                                                              <GridLayout
                                                                                                direction={'fd--row'}
                                                                                                justify={'jc--start'}
                                                                                                align={'al--start'}
                                                                                                gap={'g--0'}
                                                                                              >
                                                                                                <div className={'kubini-page2-list-list-item-id'}>
                                                                                                  <strong>{index + 1}</strong>
                                                                                                </div>
                                                                                                <div className={'kubini-page2-list-list-item-large'}>
                                                                                                  <span>{item.name}</span>
                                                                                                </div>
                                                                                                <div className={'kubini-page2-list-list-item-large'}>
                                                                                                  <span>{item.category?.name}</span>
                                                                                                </div>
                                                                                                <div className={'kubini-page2-list-list-item-mid'}>
                                                                                                  <strong>{item.createdAt ? capitalize(format(new Date(item.createdAt), "EEEE d MMMM yyyy à HH:mm:ss", {locale: fr})) : "ND"}</strong>
                                                                                                </div>
                                                                                                <div className={'kubini-page2-list-list-item-actions'}>
                                                                                                  <button
                                                                                                    type={'button'}
                                                                                                    className={'kubini-page2-list-list-item-action'}
                                                                                                    onClick={() => {
                                                                                                      navigate(links.subcategory + item.slug)
                                                                                                    }}
                                                                                                  >
                                                                                                    <i className="icon-edit-write-2" /> <span>Voir</span>
                                                                                                  </button>
                                                                                                  <button
                                                                                                    type={'button'}
                                                                                                    className={'kubini-page2-list-list-item-action'}
                                                                                                    onClick={() => {
                                                                                                      setItemCategoryModal(null)
                                                                                                      setItemCategoryModal(item)
                                                                                                      setEditCategoryModal(true)
                                                                                                    }}
                                                                                                  >
                                                                                                    <i className="icon-edit-write-2" /> <span>Modifier</span>
                                                                                                  </button>
                                                                                                  <button
                                                                                                    type={'button'}
                                                                                                    className={'kubini-page2-list-list-item-action is--danger'}
                                                                                                    onClick={() => {
                                                                                                        setItemCategoryModal(null)
                                                                                                        setItemCategoryModal(item)
                                                                                                        setDeleteCategoryModal(true)
                                                                                                    }}
                                                                                                  >
                                                                                                    <i className="icon-edit-write-2" /> <span>Supprimer</span>
                                                                                                  </button>
                                                                                                </div>
                                                                                              </GridLayout>
                                                                                            </div>

                                                                                        ))
                                                                                }
                                                                              </GridLayout>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        ) : (
                                                            <strong>Aucun élément trouvé...</strong>
                                                        )
                                                }
                                            </div>
                                        </>
                                    ) : null
                                )
                            )
                        }
                    </div>


                    <ModalLayout
                        size={'is--md'}
                        id={'add-modal'}
                        isOpen={addCategoryModal}
                        fnc={(value: boolean) => setAddCategoryModal(value)}
                        withClose={true}
                    >
                        <div className={'kubini-modal-form'}>
                            <div className={'kubini-modal-form__header'}>
                                <h2>Ajout d'une sous-catégorie</h2>
                            </div>
                            <AddModal categories={categories} />
                        </div>
                    </ModalLayout>
                    {
                        itemCategoryModal ? (
                            <ModalLayout
                                size={'is--md'}
                                isOpen={editCategoryModal}
                                id={'edit-modal'}
                                fnc={(value: boolean) => setEditCategoryModal(value)}
                                withClose={true}
                            >
                                <div className={'kubini-modal-form'}>
                                <div className={'kubini-modal-form__header'}>
                                    <h2>Modification d'une sous-catégorie</h2>
                                </div>
                                    <EditModal item={itemCategoryModal} />
                                </div>
                            </ModalLayout>
                        ) : null
                    }
                    {
                        itemCategoryModal ? (
                            <ModalLayout
                                size={'is--md'}
                                isOpen={deleteCategoryModal}
                                id={'delete-modal'}
                                fnc={(value: boolean) => setDeleteCategoryModal(value)}
                                withClose={true}
                            >
                                <div className={'kubini-modal-form'}>
                                    <DeleteModal item={itemCategoryModal} />
                                </div>
                            </ModalLayout>
                        ) : null
                    }

                </div>
            </div>
        </MainLayout>
    )
}