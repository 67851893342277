import axios from "axios"
import {APIURL} from "../../utils";

const API_URL = APIURL + "auth/";

const login = async (data: any) => {
    const response = await axios.post(API_URL + 'admin-login', data)
    
    if (response.data) {
        console.log(response.data)
        let token = response?.data?.accessToken
        window.localStorage.setItem('enka-admin-auth', token)
        return response.data
    }
}

const logout = () => {
    window.localStorage.removeItem('enka-admin-auth')
}

const authService = {
    login, logout
}

export default authService